import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import { GOOGLE_MAPS_KEY } from "./GOOGLE_MAPS_KEY";
import { HttpUtil } from "./http.util";

interface AddressParams {
  language: string;
  address: string;
}

interface TimezoneParams {
  lat: string;
  lng: string;
}

@Injectable({ providedIn: 'root' })
export class LocationService {
  constructor(
    @Inject(GOOGLE_MAPS_KEY) private key: string,
    private HttpUtil: HttpUtil,
    private http: HttpClient
  ) { }


  getStates() {
    return this.HttpUtil.get({ url: '/api/states' });
  }

  getAddress(obj: AddressParams) {
    const params: any = {
      ...obj
    }
    params.key = this.key;
    params.components = 'country:BR';
    params.sensor = false;
    return this.http.get<any>('https://maps.googleapis.com/maps/api/geocode/json', { withCredentials: false, params: params }).toPromise();
  }

  getTimezone(obj: TimezoneParams) {
    const params: any = {}
    params.location = obj.lat + ',' + obj.lng;
    params.lat = null;
    params.lng = null;
    params.key = this.key;
    let dt = new Date();
    params.timestamp = (dt.getTime() / 1000);

    return this.http.get('https://maps.googleapis.com/maps/api/timezone/json', { withCredentials: false, params: params }).toPromise()
  }
}

export const ng2LocationService = {
  name: LocationService.name,
  def: downgradeInjectable(LocationService)
}

import { AfterViewInit, Component, forwardRef, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import dt from 'datatables.net'

declare var $: JQueryStatic

@Component({
    selector: 'kpi-top-bar',
    templateUrl: './kpi-topbar.component.html'
})
export class KpiTopbarComponent implements OnInit, AfterViewInit {
    metrics: any[] = [];
    selectedMetrics: any[] = [];
    filteredMetrics: any[] = [];

    constructor(private i18n: TranslocoService){}

    ngOnInit() {
        const vm = this;
        console.log('init topbar')
        this.metrics = [vm.buildMetric(vm.getConstants().dividend, null)].concat(vm.getConstants().divisor.map(d => vm.buildMetric(vm.getConstants().dividend, d)));
    }

    ngAfterViewInit(){
        this.registerEvents();
    }

    registerEvents() {
        $('#drop-filters').on('click', function (event) {
           $(this).parent().toggleClass('open');
        });
  
        $('button#filter').on('click', function (e) {
            $('.filter-selector').removeClass('open');
        })
  
        $('body').on('click', function (e) {
            if (!(<any>$('.filter-selector')).is(e.target)
                && (<any>$('.filter-selector')).has(e.target).length === 0
                && (<any>$('.open')).has(e.target).length === 0
            ) {
                $('.filter-selector').removeClass('open');
            }
        });
  
        //@ts-ignore
        $('#metric-filter').multiselect('setOptions', { nonSelectedText: this.i18n.translate('kpi.show-opts') })
        //@ts-ignore
        $('#metric-filter').multiselect('dataprovider', this.metrics)
        //@ts-ignore
        $('#metric-filter').multiselect('rebuild');
  
        $('#search-input').on('keyup', function () {
            (<any>$('input[type="search"]')).val((<any>this).value).trigger("keyup");
        });
    }
  
    buildMetric(dividend, divisor) {
        let messages = {
            dividend: this.i18n.translate('kpi.metrics.' + dividend.label),
            by: divisor ? this.i18n.translate('kpi.by') : '',
            divisor: divisor ? this.i18n.translate('kpi.metrics.' + divisor.label) : ''
        }
        return {
            label: `${messages.dividend} ${messages.by} ${messages.divisor}`,
            title: `${messages.dividend} ${messages.by} ${messages.divisor}`,
            value: divisor ? divisor.id : 1
        }
    }
  
    filter() {
        var table = $('#tblKpis').DataTable();
        table.columns(1).search(this.selectedMetrics.join('|'), true, false)
        this.filteredMetrics = this.selectedMetrics.slice();
        table.draw();
    }
  
    findMetric(value) {
        return this.metrics.find(m => m.value == value) ? this.metrics.find(m => m.value == value).label : '';
    }
  
    unSelect(value) {
        const vm = this;
        if (this.selectedMetrics.indexOf(value) > -1) {
            $('.filter-selector .multiselect-container').children().eq(parseInt(value) - 1).removeClass('active');
            $('.filter-selector .multiselect-container').children().eq(parseInt(value) - 1).find('input').attr('checked', 0);
        let title = $('.filter-selector button.multiselect').eq(0).attr('title');
        let arr = title?.split(', ')
        arr?.splice(this.selectedMetrics.indexOf(value), 1);
        let newText = arr?.join(', ') === '' ? vm.i18n.translate('kpi.show-opts') : arr?.join(', ')
        $('.filter-selector .multiselect-selected-text').eq(0).text(newText);
        $('.filter-selector button.multiselect').eq(0).attr('title', newText)
        this.selectedMetrics.splice(this.selectedMetrics.indexOf(value), 1);
        this.filter();
        }
    }

    getConstants() {
        return {
            dividend: {
                label: "consumption",
                type: "Consumo",
                unit: "kWh",
                id: 1
            },
            divisor: [{
                label: 'size',
                type: 'Espaço',
                unit: 'm²',
                id: 2
            }, {
                label: "people_count",
                type: "Num Pessoas",
                unit: null,
                id: 3
            },
            {
                label: 'celsius',
                type: 'Temp Celsius',
                unit: '°C',
                id: 4
            }]
        }
    }
}
import * as angular from 'angular';

angular
  .module('dashboard-directives')
  .directive('formBuilder', formBuilderDirective);

/* @ngInject */
function formBuilderDirective() {
  let directive = {
    restrict: 'E',
    templateUrl: '/app/directives/form-builder/form-builder.html',
    controller: FormBuilderController,
    scope: {
      schema: '=',
      model: '=',
      formGroupClass: '@',
      formLabelClass: '@',
      formInputClass: '@',
      formInputGroupClass: '@',
      labelStyle: '@',
      inputStyle: '@'
    },
    controllerAs: 'vm',
    bindToController: true
  };

  return directive;
}

FormBuilderController.$inject = ['$attrs', '$scope'];

/* @ngInject */
function FormBuilderController($attrs, $scope) {
  var vm = this;

  vm.$onInit = onInit;
  vm.addNewObject = addNewObject;
  vm.removeObject = removeObject;
  vm.checkDisabled = checkDisabled;
  vm.checkDisabledICMS = checkDisabledICMS;
  vm.inputClass = inputClass;
  vm.bulkPaste = bulkPaste;
  vm.groupClass = groupClass;
  vm.$scope = $scope;


  if (angular.isUndefined($attrs.formGroupClass)) {
    $attrs.formGroupClass = "col-md-3 col-sm-12";
  }

  function onInit() {

  }

  function bulkPaste($event, fieldType, elementName, bulkPaste) {
    let vm = this;
    if (bulkPaste) {
      let nameParts = elementName.split("-");

      if (nameParts.length > 1 && parseInt(nameParts[1])) {
        let prefix = nameParts[0];
        let currentIdx = parseInt(nameParts[1]);
        $.each($event.originalEvent.clipboardData.items, function (i, v) {
          if (v.type === 'text/plain') {
            v.getAsString(function (text) {
              text = text.trim('\r\n');
              $.each(text.split('\r\n'), function (idx, value) {
                let field = `${prefix}-${currentIdx}`;
                let currentElement = $(`input[name='${field}']`);
                let val = value.replace(',', '.');
                if (fieldType == "number" && parseFloat(val) >= 0) {
                  currentElement.val(parseFloat(val));
                  vm.model[field] = parseFloat(val);
                }
                currentIdx++;
              });

            });
          }
        })

      }
    }
    return "true"
  }

  function inputClass(cssClass) {
    var vm = this;
    return cssClass != undefined ? cssClass : vm.formInputClass;
  }

  function groupClass(cssClass) {
    return cssClass != undefined ? cssClass : $attrs.formGroupClass;
  }

  function addNewObject(model, key, properties) {
    var newObject = {};

    var ranges = model[key];

    Object.keys(properties).forEach(function (k, index) {
      newObject[k] = properties[k].default != undefined ? properties[k].default : 0; //set default value
    });

    //init the first element
    if (ranges.length == 1 && ranges[0].from == undefined) {
      ranges[0] = {
        from: 0,
        to: null,
        percent: ranges[0].percent
      }
    }
    else if (ranges.length > 0) {
      if (ranges[ranges.length - 1].to) {
        //new range receive "from" value from last range "to"
        newObject.from = ranges[ranges.length - 1].to;
        ranges.push(newObject);
        model.msg = "";
      } else {
        model.msg = "contract.instance.last-value-blank";
      }
    } else {
      ranges.push(newObject);
    }
  }

  function checkDisabled(enableSectionFieldName, key) {
    if (enableSectionFieldName == undefined) {
      if (Object.keys(vm.model).length === 0) {
        return true;
      }
      return false;
    } else if (vm.model && vm.model['peakHourSection'] === false) {
      return enableSectionFieldName === false ||  //seaction disabled
        (key.toLowerCase().indexOf('peak') > -1 && key.toLowerCase().indexOf('offpeak') === -1); //peak field
    } else {
      return enableSectionFieldName === false;
    }
  }

  function checkDisabledICMS(enableSectionFieldName) {
    if (enableSectionFieldName === undefined) {
      return false;
    }
    return !enableSectionFieldName;
  }

  function removeObject(model, index) {
    model.splice(index, 1);
  }


}

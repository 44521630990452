<div class="row" style="margin-top: 8px; width:200%">
  <div class="col-sm-3">
    <span class="search-box">
      <i class="fa fa-search" style="position: absolute;"></i>
      <input type="text" class="form-control search-input" id="search-input" placeholder="{{'main.search' | transloco}}" style="width: 200px;"/>
    </span>
  </div>
  <div class="filter-selector col-sm-2">
    <button class="btn dropdown-toggle" type="button" id="drop-filters" aria-expanded="false">
      {{ 'kpi.table.filter' | transloco }}
      <i class="fas fa-chevron-down"></i>
    </button>
    <ul class="dropdown-menu list-unstyled" role="menu">
      <li>
        <label for="metric-filter">{{ 'kpi.table.metric' | transloco }}:</label>
        <select id="metric-filter" class="filter form-control" multiple="multiple" ng-model="selectedMetrics">
        </select>
      </li>

      <li>
        <button id="filter" ngClick="filter()" class="btn btn-primary btn-color-ga float-right">{{ 'kpi.table.to-filter' | transloco }}</button>
      </li>
    </ul>
  </div>
  <div class="selected-filters">
    <div class="tag" *ngFor="let f of filteredMetrics">
      {{findMetric(f)}}
      <i class="fa fa-times close" ng-click="unSelect(f)"></i>
    </div>
  </div>
</div>
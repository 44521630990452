import { Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import { Auxiliar } from './auxiliar';
import { HttpUtil } from "./http.util";

@Injectable({ providedIn: 'root' })
export class EnergyBillService {
  constructor(
    private HttpUtil: HttpUtil,
    private Auxiliar: Auxiliar
  ) { }

  createBillMetadata(data) {
    return this.HttpUtil.post({ url: '/api/energyBillMetadatas', data: data })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  updateBillMetadata(id, metadata) {
    return this.HttpUtil.put({
      url: `/api/energyBillMetadatas/${id}`,
      data: metadata,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw Error(error);
      });
  }

  uploadEnergyBill(params, data) {
    return this.HttpUtil.postData('/api/energyBills', 'invoice', data, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  getPresentationBills(params: any = {}) {
    params.presentationView =
      params.presentationView != null ? params.presentationView : true;
    return this.getEnergyBills(params);
  }

  getEnergyBills(params: any = {}) {
    return this.HttpUtil.get({
      url: `/api/energyBillMetadatas`,
      params: params,
    })
      .then((res) => res.data.energyBillMetadatas)
      .catch((error) => {
        throw Error(error);
      });
  }

  getPresentationMetadata(metadataId, params: any = {}) {
    params.presentationView = true;
    return this.getEnergyBillMetadata(metadataId, params);
  }

  getEnergyBillMetadata(metadataId, params = {}) {
    return this.HttpUtil.get({
      url: `/api/energyBillMetadatas/${metadataId}`,
      params: params,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw Error(error);
      });
  }

  getEnergyBillMetadatas(params: any = {}) {
    params.populateBills = 'false';
    return this.HttpUtil.get({
      url: `/api/energyBillMetadatas`,
      params: params,
    })
      .then((res) => res.data.energyBillMetadatas)
      .catch((error) => {
        throw Error(error);
      });
  }

  deleteMetadata(metadataId) {
    return this.HttpUtil.del({
      url: `/api/energyBillMetadatas/${metadataId}`,
    })
      .then((res) => res.data)
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });
  }

  deleteEnergyBill(energyBillId) {
    return this.HttpUtil.del({
      url: `/api/energyBills/${energyBillId}`,
    })
      .then((res) => res.data) // Deleted EnergyBill ID
      .catch((error) => {
        throw Error(error);
      });
  }

  downloadBillPDF(energyBillId) {
    return this.HttpUtil.get({
      url: `/api/energyBills/${energyBillId}/pdf`,
      responseType: 'blob',
    })
      .then((res) => {
        this.Auxiliar.download(res, null);
        return true;
      })
      .catch((error) => console.error(error));
  }
}

export const ng2EnergyBillService = {
  name: EnergyBillService.name,
  def: downgradeInjectable(EnergyBillService)
}

//@ts-nocheck
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NG_ROUTER, NG_STATE_PARAMS } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

declare var $: JQueryStatic;

@Component({
  selector: 'app-top-bar-search',
  templateUrl: './top-bar-search.component.html',
  styleUrls: ['./top-bar-search.component.scss']
})
export class TopBarSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  query = new FormControl()
  onDestroy$ = new Subject<void>()

  constructor(
    @Inject(NG_ROUTER) private $state,
    @Inject(NG_STATE_PARAMS) private $stateParams,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.query.setValue(this.$stateParams.query)
    this.query.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: this.doPageFiltering.bind(this)
    })
  }

  doPageFiltering(value: string){
    /*
      Possivelmente não é necessário fazer a transição para atualizar
    */
    const urlParams = {
      query: value
    };
    const transition = this.$state.current.name
    this.$state.transitionTo(transition, urlParams, {
      inherit: true, reload: false, notify: false
    })

    const hiddenInputFilter = $(this.document).find('input[type="search"]')
    if(hiddenInputFilter){
      hiddenInputFilter.val(value).trigger('keyup')
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next()
  }
}

import { Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import { HttpUtil } from "./http.util";

@Injectable({ providedIn: 'root' })
export class MeterService {

  constructor(
    private HttpUtil: HttpUtil
  ) { }

  /**
  ** meterType - "consumption", "solar_generation"
  **/
  getMeters(params) {
    return this.HttpUtil.get({ url: '/api/meters', params: params })
      .then(res => { return res.data.meters });
  }

  /**
  ** meterType - "consumption", "solar_generation"
  **/
  getDataSources(params) {
    params.type = "meter"
    return this.HttpUtil.get({ url: '/api/datasources', params: params })
      .then(res => { return res.data.dataSources });
  }

  getMetersByUser(params) {
    return this.HttpUtil.get({ url: `/api/users/${params.userId}/meters`, params: params })
      .then(res => { return res.data.meters });
  }

  getMeter(params) {
    return this.HttpUtil.get({ url: '/api/meters/' + params.meterId, params: params })
      .then(res => { return res.data.meter });
  }


  /**
  ** Get Meters Types
  **/
  getMetersTypes(params) {
    return this.HttpUtil.get({ url: '/api/meterstypes', method: 'get', params: params }).then(res => { return res.data.metersTypes });
  }

  /**
  ** Save meter
  **/
  saveMeter(params) {
    if (params.id) {
      return this.HttpUtil.put({ url: '/api/meters/' + params.id, method: 'put', data: params });
    } else {
      return this.HttpUtil.post({ url: '/api/meters', method: 'post', data: params });
    }
  }

  getUsers(params) {
    return this.HttpUtil.get({ url: '/api/meters/' + params.meterId + '/users', params: params })
      .then(res => { return res.data.users });
  }

  /**
  ** Save DeviceInstallation
  **/
  saveDeviceInstallation(params) {
    if (params.id && params.meterId) {
      return this.HttpUtil.put({ url: `/api/meters/${params.meterId}/device-installation/${params.id}`, method: 'put', data: params });
    } else if (params.meterId) {
      return this.HttpUtil.post({ url: `/api/meters/${params.meterId}/device-installation/`, method: 'post', data: params });
    }
  }

  getContracts(dataSourceId) {
    return this.HttpUtil.get({ url: `/api/datasources/${dataSourceId}/contracts` })
      .then(res => { return res.data.contracts });
  }

  status(params) {
    return this.HttpUtil.get({ url: `/api/meters/${params.meterId}/status` })
      .then(res => { return res.data.meter });
  }
}

export const ng2MeterService = {
  name: MeterService.name,
  def: downgradeInjectable(MeterService)
}

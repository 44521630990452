//@ts-nocheck
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import DataFrame from 'dataframe-js';
import * as moment from 'moment';
import { ContractService } from './contract.service';
import { GroupService } from './group.service';
import { HttpUtil } from './http.util';
import { MeasurementService } from './measurement.service';

@Injectable({providedIn: 'root'})
export class DataSourceService {
  constructor(
    private HttpUtil: HttpUtil,
    private groupService: GroupService,
    private measurementService: MeasurementService,
    private contractService: ContractService
  ) { }

  /**
   ** meterType - "consumption", "solar_generation"
   **/
  getMeters(params) {
    return this.HttpUtil.get({
      url: '/api/meters',
      params: params
    })
      .then(res => {
        return res.data.meters
      });
  };
  getDataSource(id) {
    return this.HttpUtil.get({
      url: `/api/datasources/${id}`
    })
      .then(res => {
        return res.data.dataSource
      });
  }

  /**
   ** meterType - "consumption", "solar_generation"
   **/
  getDataSources(params?) {
    return this.HttpUtil.get({
      url: '/api/datasources',
      params: params
    })
      .then(res => {
        return res.data.dataSources
      });
  };

  async getDataSourcesOfUser(userId) {
    return this.HttpUtil.get({
      url: `/api/users/${userId}/data-sources`
    }).then(res => res.data)
  }

  async getUserDataSources(userId) {
    return this.HttpUtil.get({
      url: `/api/users/${userId}/user-data-sources`
    }).then(res => res.data)
  }
  getContracts(dataSourceId) {
    return this.HttpUtil.get({ url: `/api/datasources/${dataSourceId}/contracts` })
      .then(res => { return res.data.contracts });
  }
  getTariffInstances(datasourceId, params) {
    return this.HttpUtil.get({ url: `/api/datasources/${datasourceId}/tariffInstances`, params: params })
      .then(res => { return res.data.contracts });
  }
  getMaxInspectionDay(dataSourceIds) {
    return this.HttpUtil.get({
      url: '/api/datasources/maxInspectionDay',
      params: { 'dataSourceIds': dataSourceIds }
    })
      .then(res => {
        return res.data.maxInspectionDay
      });
  }

  async deleteDataSource(dataSourceId) {
    return await this.HttpUtil.del({
      url: `/api/datasources/${dataSourceId}`
    })
  }

  async loadDataSourceTotal(dataSource, startDate, endDate, period, totalCB) {
    let params = {};

    if (period == 'year') {
      params.period = moment(endDate).format('YYYY');
      params.type = 'invoice';
    } else if (period != 'year' && startDate && endDate) {
      params.startDate = moment(startDate).format("YYYY-MM-DD");
      params.endDate = moment(endDate).format("YYYY-MM-DD");
    } else {
      params.type = 'invoice';
    };

    dataSource.noLoading = false;
    dataSource.invoice = null;
    dataSource.activeEnergy = null;
    //dataSource.formattedInvoicePeriod = `${moment(startDate).format("DD MMM")} - ${moment(endDate).format("DD MMM")}`;
    if (dataSource.type == "group") {
      params.groupId = dataSource.id;
      this.groupService.groupTotal(params)
        .then(totalData => totalCB(dataSource, totalData))
        .catch(error => console.log(error));
    } else {
      params.meterId = dataSource.id;
      this.measurementService.total(params)
        .then(totalData => totalCB(dataSource, totalData))
        .catch(error => console.log(error));
    };
  };

  async dataSourceTotalComparative(params) {
    let dataSourceId = params.dataSourceId;
    return this.HttpUtil.get({ url: `/api/datasources/${dataSourceId}/totals/comparative`, params: params, cache: true })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log(err);
      });
  };

  async loadTotals(params) {
    let dataSourceId = params.dataSourceId;
    return this.HttpUtil.get({ url: `/api/datasources/${dataSourceId}/totals`, params: params, cache: true })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.log(err);
      });
  };

  async loadDataSourceDemand(dataSource, endDate, period, demandCB) {
    let params = {
      period: period == 'year' ? moment(endDate).format("YYYY") : moment(endDate).format("YYYY-MM")
    };

    if (dataSource.type == "group") {
      params.groupId = dataSource.id;
      this.groupService.getDemand(params).then(demand => demandCB(dataSource, demand));
    } else if (dataSource.type == "meter") {
      params.meterId = dataSource.id;
      this.measurementService.getDemand(params).then(demand => demandCB(dataSource, demand));
    }
  };

  async getOptimalDemandResults(dataSourceId) {
    return this.HttpUtil.get({
      url: `/api/datasources/${dataSourceId}/contracts/optimaldemand`,
    })
      .then(res => {
        console.log(res);
        return res.data.dataSource;
      })
      .catch(err => {
        console.log(err);
      });
  };

  async getPowerFactorCorrection(dataSource, vm) {
    const that = this

    function setDataFrame(measurements, capacitiveStartHour, capacitiveEndHour) {
      let df = new DataFrame(measurements, ['avgActivePower', 'avgReactivePower', 'PF', 'date']);
      df = df.withColumn('avgApparentPower',
        (row) => Math.sqrt(Math.pow(row.get('avgActivePower'), 2) + Math.pow(row.get('avgReactivePower'), 2)))

      capacitiveStartHour = moment(`2018-01-01T${capacitiveStartHour}`);
      capacitiveEndHour = moment(`2018-01-01T${capacitiveEndHour}`);

      df = df.withColumn('isCapacitiveHour', (row) => (((moment(row.get('date')).hour() >= capacitiveStartHour.hour()) && (moment(row.get('date')).minute() >= capacitiveStartHour.minute())) || (moment(row.get('date')).hour() >= capacitiveStartHour.hour() + 1)) && (((moment(row.get('date')).hour() <= capacitiveEndHour.hour()) && (moment(row.get('date')).minute() < capacitiveEndHour.minute())) || (moment(row.get('date')).hour() < capacitiveEndHour.hour())));
      df = df.withColumn('isInductiveHour', (row) => !row.get('isCapacitiveHour'));

      df = df.sortBy('date');

      return df
    };


    function computeCapacitorBank(df) {
      let newFps = 0.92;
      df = df.withColumn('Qold',
        (row) => Math.sqrt(Math.pow(row.get('avgApparentPower'), 2) - Math.pow(row.get('avgActivePower'), 2)) * Math.sign(row.get('PF')));
      df = df.withColumn('newS',
        (row) => row.get('avgActivePower') / newFps);
      df = df.withColumn('Qnew',
        (row) => Math.sqrt(Math.pow(row.get('newS'), 2) - Math.pow(row.get('avgActivePower'), 2)));
      df = df.withColumn('capacitorBank',
        (row) => row.get('Qold') - row.get('Qnew'));
      let capacitorBank = df.stat.max('capacitorBank');

      return capacitorBank
    };


    function resultPassive(df, Qbc) {
      let result = df;
      result = result.withColumn('newQ', (row) => row.get('avgReactivePower') + Qbc);
      result = result.withColumn('newFPS', (row) => row.get('avgActivePower') / Math.sqrt(Math.pow(row.get('avgActivePower'), 2) + Math.pow(row.get('newQ'), 2)) * Math.sign(row.get('newQ')));

      return result
    };


    function result_active_passive(df, Qbc) {
      let passive = resultPassive(df, Qbc);

      let qtFinesCapacitiveNew = passive.filter((row) => ((row.get('isCapacitiveHour')) && (row.get('newFPS') < 0)) && (row.get('newFPS') > -0.92)).count();
      let qtFinesCapacitiveOld = passive.filter((row) => ((row.get('isCapacitiveHour')) && (row.get('PF') < 0)) && (row.get('PF') > -0.92)).count();

      return {
        bestType: qtFinesCapacitiveNew <= qtFinesCapacitiveOld ? '' : 'Automático',
        finesOld: qtFinesCapacitiveOld,
        finesNew: qtFinesCapacitiveNew
      }
    };


    function getCapacitorBank(measurements, capacitiveStartHour, capacitiveEndHour) {
      let df = setDataFrame(measurements, capacitiveStartHour, capacitiveEndHour);
      let inductiveData = df.filter((row) => row.get('isInductiveHour') && row.get('PF') > 0);
      let fineData = inductiveData.filter((row) => row.get('PF') < 0.92 && row.get('avgActivePower') > 0);
      if (fineData.count() > 0) {
        let QCapacitorBank = computeCapacitorBank(fineData);
        return {
          'sizeCapacitorBank': QCapacitorBank,
          'type': result_active_passive(df, QCapacitorBank),
          'startDate': df.head(1).getRow(0).get('date'),
          'endDate': df.tail(1).getRow(0).get('date')
        };
      } else if (inductiveData.count() > 0) {
        return {
          'sizeCapacitorBank': 0,
          'onlyInductive': true,
          'type': 'Automático',
          'startDate': df.head(1).getRow(0).get('date'),
          'endDate': df.tail(1).getRow(0).get('date')
        };
      }
    };

    let params = {};
    params.startDate = vm.startDate;
    params.endDate = vm.endDate;

    let contracts;
    let measurementsPower;

    if (dataSource.type == 'meter') {
      params.meterId = dataSource.id
      measurementsPower = await this.measurementService.powers(params);
      contracts = await this.contractService.getMeterContracts(dataSource.id, vm.startDate, vm.endDate);
    } else {
      params.dataSourceId = vm.dataSourceId;
      measurementsPower = await this.measurementService.powersGroup(params);
      contracts = await this.contractService.getGroupContracts(dataSource.id, vm.startDate, vm.endDate);
    };

    let mainContract = contracts.find((contract) => contract.instances.find((instance) => instance.mainTariff))
    const mainInstance = mainContract.instances.filter(instance => instance.mainTariff == true)[0];
    console.log(measurementsPower)
    let answer = getCapacitorBank(measurementsPower.powers,
      mainInstance.fields.capacitiveStartHour,
      mainInstance.fields.capacitiveEndHour);
    let response = {
      status: 200,
      data: answer
    }
    return response
  }

  createInvoice(datasourceId, params) {
    return this.HttpUtil.post({
      url: `/api/datasources/${datasourceId}/invoices`,
      params: params
    })
      .then(res => {
        return res.data
      });
  }

  editInvoice(datasourceId, params, invoice) {
    return this.HttpUtil.post({
      url: `/api/datasources/${datasourceId}/invoices`,
      params: params,
      data: invoice
    })
      .then(res => {
        return res.data
      });
  }

  createInvoiceItem(invoiceId, datasourceId, invoiceItem) {
    return this.HttpUtil.post({
      url: `/api/datasources/${datasourceId}/invoices/${invoiceId}/invoice-items`,
      data: invoiceItem
    })
      .then(res => {
        return res.data
      });
  }

  editInvoiceItem(invoiceId, datasourceId, invoiceItemId, invoiceItem) {
    return this.HttpUtil.put({
      url: `/api/datasources/${datasourceId}/invoices/${invoiceId}/invoice-items/${invoiceItemId}`,
      data: invoiceItem
    })
      .then(res => {
        return res.data
      });
  }

  deleteInvoiceItem(invoiceId, datasourceId, invoiceItemId) {
    return this.HttpUtil.del({
      url: `/api/datasources/${datasourceId}/invoices/${invoiceId}/invoice-items/${invoiceItemId}`,
    })
      .then(res => {
        return res.data
      });
  }
}

export const ng2DatasourceService = {
  name: DataSourceService.name,
  def: downgradeInjectable(DataSourceService)
}
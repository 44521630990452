import { Injectable } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class CalendarService {

  defaultCalendar = {
    period: "day",
    inspectionDay: null
  };

  observers = {
    'default': []
  };

  calendarsChannels = {
    //'default' : JSON.parse(JSON.stringify(defaultCalendar))
    'default': null
  };

  CalendarEvents = {
    REGISTER: 1,
    UPDATE: 2
  };

  Events = this.CalendarEvents

  constructor() { }

  /**
    @param observerId Skip notify the updater using observerId. If null/undefined
    the updater will also be notified
    @param newCalendarData
    @param channel instace channel
  **/
  notifyObservers(channel = 'default', observerId, event) {
    if (this.observers[channel] != undefined) {
      this.observers[channel].forEach(observer => {
        if (!(observerId && observer.id == observerId)) {
          this.notifyObserver(observer, channel, event);
        }
      });
    }
  }

  notifyObserver(observer, channel = 'default', event) {
    if (observer && this.calendarsChannels[channel] != undefined) {
      const calendarData = JSON.parse(JSON.stringify(this.calendarsChannels[channel]));
      observer.call(calendarData, event);
    }
  }

  //the observer is a callback function
  registerObserver(observerFunction, channel = 'default') {

    let channelObserver = this.observers[channel];

    if (channelObserver === undefined) {
      this.observers[channel] = [];
    }
    let id = new Date().getTime() * Math.random();
    let observer = {
      id: id,
      call: observerFunction
    };

    this.observers[channel].push(observer);
    this.notifyObserver(observer, channel, this.CalendarEvents.REGISTER);
    return id;
  }

  /**
    @param observerId Skip notify the updater using observerId. If null/undefined
    the update will also be notified
    @param newCalendarData
    @param channel instace channel
  **/
  updateCalendarData(observerId, newCalendarData, channel = 'default', event = this.CalendarEvents.UPDATE) {
    newCalendarData.startDate = moment(newCalendarData.startDate).format('YYYY-MM-DDTHH:mm:ss');
    newCalendarData.endDate = moment(newCalendarData.endDate).format('YYYY-MM-DDTHH:mm:ss');
    this.calendarsChannels[channel] = JSON.parse(JSON.stringify(newCalendarData));
    this.notifyObservers(channel, observerId, event);
  }

  unregisterObserver(observerId, channel = 'default') {
    let observerIndex = this.observers[channel].findIndex(function (o) {
      return o.id == observerId;
    });

    return this.observers[channel].splice(observerIndex, 1).length == 1;
  }

}

export const ng2CalendarService = {
  name: CalendarService.name,
  def: downgradeInjectable(CalendarService)
}

//@ts-nocheck
import * as moment from "moment";
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { downgradeInjectable } from "@angular/upgrade/static";

declare var $: JQueryStatic

@Injectable({ providedIn: 'root' })
export class Auxiliar {

  constructor() { }

  applyTariffAndTotal(items) {
    var total = { symbol: '', valueRaw: 0, value: 0, activeEnergy: 0 }
    items.forEach(function (item, index) {
      item.price = currency(item.activeEnergy, item.invoiceDate);
      total.activeEnergy += item.activeEnergy;
      total.valueRaw += isNaN(item.price.valueRaw) ? 0 : item.price.valueRaw;

      if (!total.symbol && item.price.symbol) { total.symbol = item.price.symbol }
    });
    total.activeEnergy = $filter('toWatts')(total.activeEnergy);
    total.value = $filter('currency')(total.valueRaw, '', 2);
    return { items: items, total: total }
  }

  buildDateSeries(startDt, endDt, period, items, valueProperty, timezone, inspectionDay = null) {
    var labels = [];
    var values = [];
    var newItems = [];
    valueProperty = valueProperty || 'activeEnergy';
    var sdt = moment.tz(moment(startDt).format("YYYY-MM-DD"), timezone);
    var edt = moment.tz(moment(endDt).format("YYYY-MM-DD"), timezone);
    startDt = moment.tz(moment(startDt).format("YYYY-MM-DD"), timezone);
    sdt.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    edt.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    //Generate labels from start date and inspection date
    if (period === 'day') {
      var k = 0;
      var max = 0;
      //Increment 1 day for each date until the inspection date
      while (edt.diff(sdt, 'days') >= 0) {
        var value = null;
        var price = {};
        var item = items.find(i => sdt.tz(timezone).format('YYYY-MM-DD') == moment(i.date).tz(timezone).format('YYYY-MM-DD')) || {};
        if (item && item[valueProperty]) {
          value = item[valueProperty];
          max = value > max ? value : max;
        }
        // if(items[k] && sdt.tz(timezone).format('YYYY-MM-DD') == moment(items[k].date).tz(timezone).format('YYYY-MM-DD')){
        //   item = items[k];
        //   value = items[k][valueProperty];
        //   max = value > max ? value : max;
        //   k++;
        // }
        labels.push(parseInt(sdt.tz(timezone).format('x')));
        values.push({
          y: value == 0 ? null : value,
          name: parseInt(sdt.tz(timezone).format('x'))

        });
        newItems.push(item);
        sdt.add(1, 'day');
      }
    } else if (period === 'month') {
      var k = 0;
      var max = 0;
      var months = moment(edt).diff(sdt, 'months') + 1;
      var counter = 0;
      //Increment 1 day for each date until the inspection date
      while (months - counter >= 0) {
        var value = null;
        var item = items.find(i => sdt.tz(timezone).month() == moment(i.date).tz(timezone).month() && sdt.tz(timezone).year() == moment(i.date).tz(timezone).year()) || {};
        if (item && item[valueProperty]) {
          value = item[valueProperty];
          max = value > max ? value : max;
        }
        // if(items[k] && sdt.tz(timezone).format('YYYY-MM-DD') == moment(items[k].date).tz(timezone).format('YYYY-MM-DD')){
        //   item = items[k];
        //   value = items[k][valueProperty];
        //   max = value > max ? value : max;
        //   k++;
        // }
        labels.push(parseInt(sdt.tz(timezone).format('x')));
        values.push({
          y: value == 0 ? null : value,
          name: parseInt(sdt.tz(timezone).format('x'))
          /*,drilldown: true*/
        });
        newItems.push(item);
        //if(sdt.month()!=12){
        sdt.month(sdt.month() + 1);
        counter++;
      }
    } else if (period === 'invoice') {
      var k = 0;
      var max = 0;
      var ii = 0;

      var startDate = moment.tz(startDt, timezone);

      let yearsCount = moment(endDt).diff(startDate, 'years') + 1;
      //Increment 1 month for each date until the inspection date
      while (ii < 12 && yearsCount > 0) {
        var item = { activeEnergy: 0, group: ii + 1 };
        var value = null;
        if (items[k] && (moment(items[k].date).tz(timezone).format('MM') == (ii + 1)
          || (inspectionDay != null && moment(items[k].date).tz(timezone).format('MM') - 1 == (ii + 1)
            && moment(items[k].date).tz(timezone).format('DD') <= inspectionDay))) {
          item = items[k];
          value = items[k][valueProperty];
          max = value > max ? value : max;
          k++;
        }
        //labels.push(ii+1);
        labels.push(parseInt(moment(startDate).month(ii).tz(timezone).format('x')));
        values.push({
          y: value == 0 ? null : value,
          name: parseInt(moment(startDate).month(ii).tz(timezone).format('x'))
          /*,drilldown: true*/
        });
        newItems.push(item);
        ii++;

        if (ii == 12) {
          ii = 0;
          yearsCount--;
          startDate.add(1, 'years');
        }
      }
    } else if (period === 'hour') {
      var k = 0;
      var max = 0;
      var h = 0;
      var startDate = moment.tz(startDt, timezone).hours(h);
      h = startDate.hours();

      let daysCount = edt.diff(sdt, 'days') + 1;

      while (h < 24 && daysCount > 0) {
        var value = null;
        var item = items.find(i => moment(startDate).tz(timezone).format('YYYY-MM-DD HH') == moment(i.date).tz(timezone).format('YYYY-MM-DD HH')) || {};
        if (item) {
          if (typeof valueProperty === "function") {
            value = valueProperty(item);
          } else {
            value = item[valueProperty];
          }
          max = value > max ? value : max;
        }
        //Create labels using momentjs date object with differente hours
        labels.push(+moment(startDate).hours(h).format('x'));
        values.push({
          y: value == 0 ? null : value,
          name: +moment(startDate).hours(h).format('x')
        });
        newItems.push(item);
        h++;
        if (h == 24) {
          h = 0;
          daysCount--;
          startDate.add(1, 'days');
        }
        startDate.hours(h)
      }
    }
    else if (period === 'minute') {
      var k = 0;
      var max = 0;
      var h = 0;
      var min = 0;
      var startDate = moment.tz(startDt, timezone).hours(h).minutes(min);
      h = startDate.hours();
      min = startDate.minutes();

      let daysCount = moment(endDt).diff(startDt, 'days') + 1;

      while (min < 60 && h < 24 && daysCount > 0) {
        var value = null;
        var item = {};
        if (items[k] &&
          moment(startDate).tz(timezone).format('YYYY-MM-DD HH:mm') == moment(items[k].date).tz(timezone).format('YYYY-MM-DD HH:mm')) {
          item = items[k];

          if (typeof valueProperty === "function") {
            value = valueProperty(items[k]);
          } else {
            value = items[k][valueProperty];
          }
          max = value > max ? value : max;
          k++;
        }

        //Create labels using momentjs date object with differente hours
        labels.push(+moment(startDate).hours(h).minutes(min + 15).format('x'));
        values.push({
          y: value == 0 ? null : value,
          name: +moment(startDate).hours(h).minutes(min).format('x')
        });
        newItems.push(item);
        min = min + 15;
        if (min == 60) {
          min = 0;
          h++;
          if (h == 24) {
            h = 0;
            daysCount--;
            startDate.add(1, 'days');
          }
        }
        startDate.hours(h).minutes(min);
      }
    }
    else if (period === 'year') {
      var k = 0;
      var max = 0;
      var ii = 0;

      var startDate = moment.tz(startDt, timezone);

      let yearsCount = moment(endDt).diff(startDate, 'years') + 1;
      //Increment 1 month for each date until the inspection date
      while (yearsCount > 0) {
        var item = { activeEnergy: 0, group: ii + 1 };
        var value = null;

        if (items[k] && moment(items[k].date).tz(timezone).year() == moment(startDt).set({ year: moment(startDt).year() + ii }), 'years') {
          item = items[k];
          value = items[k][valueProperty];
          max = value > max ? value : max;
          k++;
        }
        //labels.push(ii+1);
        labels.push(parseInt(moment(startDate).tz(timezone).format('x')));
        values.push({
          y: value == 0 ? null : value,
          name: parseInt(moment(startDate).tz(timezone).format('x'))
          /*,drilldown: true*/
        });
        newItems.push(item);
        ii++;
        startDate.add(1, 'years');
        yearsCount--;
      }
    }
    return { labels: labels, values: values, max: max, newItems: newItems }
  }

  diffDays(startDt, endDt) {
    return Math.floor((Date.parse(endDt) - Date.parse(startDt)) / 86400000)
  }

  cardColor(d, k) {
    var hsvToRgb = function (h, s, v) {
      var r, g, b;
      var i = Math.floor(h * 6);
      var f = h * 6 - i;
      var p = v * (1 - s);
      var q = v * (1 - f * s);
      var t = v * (1 - (1 - f) * s);
      switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
      }
      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];

    }
    return hsvToRgb((77 * Math.tanh(d * k) + 77) / 360, 0.7, 0.75)
  }

  download(res, contentType) {
    const contentDisposition = res.headers('Content-Disposition');
    const filename = contentDisposition.match(/filename="(.+)"/i)[1];

    contentType = contentType || res.headers('Content-Type');

    const blob = new Blob([res.data], { type: contentType });

    // Create an invisible A element
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(blob);

    // Use download attribute to set set desired file name
    a.setAttribute('download', filename);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  // service implementation
  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = _.range(startPage, endPage + 1);

    var offset = (currentPage - 1) * pageSize;

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
      offset: offset
    };
  }

  /**
  @param inspectionDay
  @param date - If date param is passed so is used that date's month for inspectionDate
  **/
  getInspectionDate(inspectionDay, date) {
    var inspectionDate = new Date();
    var month = 0;

    if (inspectionDay == 0) {
      let referenceDate = date ? moment(date) : moment();
      inspectionDate.setDate(referenceDate.date(1).add(1, 'month').subtract(1, 'day').date());
    } else {
      inspectionDate.setDate(inspectionDay);
    }

    inspectionDate.setHours(12, 0, 0);

    if (date == undefined) {
      date = new Date();
      date.setHours(12, 0, 0);
      if (Date.parse(inspectionDate) < Date.parse(date)) {
        inspectionDate.setMonth(inspectionDate.getMonth() + 1);
      }
    }
    else {
      inspectionDate.setMonth(date.getMonth());
      inspectionDate.setFullYear(date.getFullYear());
    }

    return inspectionDate;
  }

  applyDateTimeMask(selector) {
    $(selector).inputmask("datetime", {
      mask: "1/2/y - h:s",
      placeholder: "DD/MM/YYYY - HH:MM",
      leapday: "-02-29",
      separator: "/",
      autoclear: false,
      alias: "dd-mm-yyyy",
      "onincomplete": function () { },
      "oncomplete": function () { }
    });
  }

  applyDateMask(selector) {
    $(selector).inputmask("date", {
      mask: "1/2/y",
      placeholder: "DD/MM/YYYY",
      leapday: "-02-29",
      separator: "/",
      autoclear: false,
      alias: "dd-mm-yyyy",
      "onincomplete": function () { console.log('inputmask incomplete'); },
      "oncomplete": function () { console.log('inputmask complete'); }
    });
  }

  paceAdjustment() {
    $('.pace-progress').css("top", "80px");
  
    $(document).scroll(function (e) {
      $(window).scrollTop() > 100 ? $('#header').addClass('test') : $('#header').removeClass('test');
  
      if ($(window).scrollTop() > 0) {
        $('.pace-progress').css("top", "0px");
      } else {
        $('.pace-progress').css("top", "80px");
      }
    });
  }

  forceResizeWindow() {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  
  }
}

export const ngAuxiliarFactory = {
  name: Auxiliar.name,
  def: downgradeInjectable(Auxiliar)
}
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { TokenInterceptor } from './middleware/token.interceptor';
import { ForgotComponent } from './forgot/forgot.component';
import { ComponentContainerModule } from '../shared/component-container/component-container.module';
import { httpInterceptorProviders } from '../shared/middleware';
import { InviteComponent } from './invite/invite.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { LogoutComponent } from './logout.component';
import { GlobalLoadingModule } from '../shared/global-loading/global-loader.module';
import { RequestCache } from '../shared/middleware/request-cache.service';


@NgModule({
  declarations: [
    LoginComponent, 
    ForgotComponent, 
    InviteComponent,
    NewPasswordComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentContainerModule,
    GlobalLoadingModule
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        httpInterceptorProviders,
        AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        RequestCache
      ],
    };
  }
}

import * as angular from 'angular';

angular
  .module('dashboard-services')
  .service('KpiService', KpiService);

KpiService.$inject = ['HttpUtil'];

/* @ngInject */
function KpiService(HttpUtil) {
  const KpiService = {
    getKpis: getKpis,
    getKpi: getKpi,
    getAllKpiPoints: getAllKpiPoints,
    getKpiPoints: getKpiPoints,
    getKpiPointsByDataSource: getKpiPointsByDataSource,
    getKpiPoint: getKpiPoint,
    getOperationTypes: getOperationTypes,
    createKpi: createKpi,
    updateKpi: updateKpi,
    deleteKpi: deleteKpi,
    createKpiPoint: createKpiPoint,
    updateKpiPoint: updateKpiPoint,
    deleteKpiPoint: deleteKpiPoint,
    getConstants: getConstants,
  };

  return KpiService;

  function getKpis(params) {
    try {
      return HttpUtil.get({
        url: '/api/kpis',
        params: params,
        method: 'get'
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }

  }

  function getKpi(params) {
    try {
      return HttpUtil.get({
        url: `/api/kpis/${params.id}`,
        method: 'get'
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function getAllKpiPoints(params){
    try{
      return HttpUtil.get({ 
        url: `/api/kpis/kpi-points`, 
        params:params, 
        method: 'get' 
      }).then(res => {
        return res.data
      });
    } catch(error){
      return new Error(error);
    }
  }

  function getKpiPoints(kpiId, params) {
    try {
      return HttpUtil.get({
        url: `/api/kpis/${kpiId}/kpi-points`,
        params: params,
        method: 'get'
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function getKpiPointsByDataSource(params) {
    try {
      return HttpUtil.get({
        url: `/api/kpis/${params.kpiId}/datasource-kpis/${params.datasourceId}/kpi-points`,
        params: params,
        method: 'get'
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function getKpiPoint(params) {
    try {
      return HttpUtil.get({
        url: `/api/kpis/${params.kpi}/kpi-points/${params.id}`,
        params: params,
        method: 'get'
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function getOperationTypes(params) {
    try {
      return HttpUtil.get({
        url: '/api/kpis/operations',
        params: params,
        method: 'get'
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function createKpi(params) {
    try {
      return HttpUtil.post({
        url: '/api/kpis',
        method: 'post',
        data: params
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function updateKpi(params) {
    try {
      return HttpUtil.put({
        url: `/api/kpis/${params.id}`,
        method: 'put',
        data: params
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function deleteKpi(params) {
    try {
      return HttpUtil.del({
        url: `/api/kpis/${params.id}`,
        method: 'delete',
        data: params
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function createKpiPoint(params) {
    try {
      return HttpUtil.post({
        url: `/api/kpis/${params.kpiId}/datasource-kpis/${params.dataSourceKPIId}/kpi-points`,
        method: 'post',
        data: params
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function updateKpiPoint(params) {
    try {
      return HttpUtil.put({
        url: `/api/kpis/${params.kpiId}/datasource-kpis/${params.dataSourceKPIId}/kpi-points/${params.id}`,
        method: 'put',
        data: params
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function deleteKpiPoint(kpiPoint) {
    try {
      return HttpUtil.del({
        url: `/api/kpis/${params.kpiId}/kpi-points/${params.id}`,
        params: params,
        method: 'delete'
      }).then(res => {
        return res.data
      });
    } catch (error) {
      return new Error(error);
    }
  }

  function getConstants() {
    return {
      dividend: {
        label: "consumption",
        type: "Consumo",
        unit: "kWh",
        id: 1
      },
      divisor: [{
        label: 'size',
        type: 'Espaço',
        unit: 'm²',
        id: 2
      }, {
        label: "people_count",
        type: "Num Pessoas",
        unit: null,
        id: 3
      },
      {
        label: 'celsius',
        type: 'Temp Celsius',
        unit: '°C',
        id: 4
      }]
    }
  }
}

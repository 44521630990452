<div class="sticky menu-include">
  <div class="main-nav-wrapper" #navWrapper>
    <nav id="main-nav" class="main-nav" [ngClass]="{ scrollmenu : scrollMenu == true}">
      <ul id="menu" app-kit-menu #menu>
        <!--Only on mobile-->
        <li class="only-mobile">
          <a ngRouterLink="main.user">
            <span aria-hidden="true" class="fa fa-user"></span>
            <span class="nav-label">{{ "main.menu.account" | transloco }}</span>
            <!--<span class="fa arrow"></span>-->
          </a>
        </li>
        <!--END Only on mobile-->
        <li>
          <span class="submenu">{{ "main.menu.monitoring" | transloco }}</span>
          <ul class="not-collapse">
            <!--- TODO: O branch provavelmente é usado quanto se está em página com abas --->
            <li [ngClass]="{
                active:
                  screen == 'main.measuring-points' ||
                  (branch == true && screen == undefined)
              }" *wchHasFeature="'sectionDataSources'">
              <a ngRouterLink="main.measuring-points">
                <span aria-hidden="true" class="fa measuring_points_icon"></span>
                <span class="nav-label">{{
                  "main.menu.overview" | transloco
                  }}</span>
              </a>
            </li>


            <li [ngClass]="{
                active:
                  (branch != true && screen == undefined) ||
                  screen == 'main.dashboard' ||
                  screen == 'main.dashboard.meter'
              }" *wchHasFeature="'sectionMeasurements'">
              <a ngRouterLink="main.dashboard">
                <span aria-hidden="true" class="fa measurements_icon"></span>
                <span class="nav-label">{{
                  "main.menu.measurement" | transloco
                  }}</span>
              </a>
            </li>

            <li [ngClass]="{ 'active' :  screen == 'main.breakdown'}">
              <a ngRouterLink="main.breakdown">
                <div class="algn-itens">
                  <mat-icon>stacked_bar_chart</mat-icon>
                  <span class="nav-label">{{
                    "main.menu.breakdown" | transloco
                    }}</span>
                </div>
              </a>
            </li>

            <li [ngClass]="{ active: screen == 'main.analysis' }" *wchHasFeature="'sectionComparativeAnalysis'">
              <a ngRouterLink="main.analysis">
                <span aria-hidden="true" class="fa analisys_icon"></span>
                <span class="nav-label">{{
                  "main.menu.analysis" | transloco
                  }}</span>
              </a>
            </li>

            <li [ngClass]="{ active: screen == 'alerts' }" *wchHasFeature="'sectionAlerts'">
              <a ngRouterLink="alerts">
                <span aria-hidden="true" class="fa alerts_icon"></span>
                <span class="nav-label">{{
                  "main.menu.alerts" | transloco
                  }}</span>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <span class="submenu">{{ "main.menu.financial" | transloco }}</span>
          <ul class="not-collapse">
            <li [ngClass]="{ active: screen == 'main.freeMarket' }" *wchHasFeature="'sectionFreeMarket'">
              <a ngRouterLink="main.freeMarket">
                <span aria-hidden="true" class="fa free_market_icon"></span>
                <span class="nav-label">{{
                  "main.menu.free-market" | transloco
                  }}</span>
              </a>
            </li>

            <li [ngClass]="{ active: screen == 'contracts' }" *wchHasFeature="'sectionContract'">
              <a ngRouterLink="contracts">
                <span aria-hidden="true" class="fa contracts_icon"></span>
                <span class="nav-label">{{
                  "main.menu.contracts-and-tariffs" | transloco
                  }}</span>
              </a>
            </li>
            <li [ngClass]="{ active: screen == 'main.economy' }" *wchHasFeature="'sectionEconomy'">
              <a ngRouterLink="main.economy">
                <span aria-hidden="true" class="fa economy_icon"></span>
                <span class="nav-label">{{
                  "main.menu.economy" | transloco
                  }}</span>
              </a>
            </li>

            <li [ngClass]="{
                active:
                  screen == 'main.energy-bills' ||
                  screen == 'main.energy-bills.list'
              }" *wchHasFeature="'energyBillManager'">
              <a ngRouterLink="main.energy-bills">
                <span aria-hidden="true" class="fa invoices_icon"></span>
                <span class="nav-label">{{ "main.menu.bills" | transloco }}</span>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <span class="submenu">{{ "main.menu.management" | transloco }}</span>
          <ul class="not-collapse">
            <li [ngClass]="{ active: screen == 'registrations' }" *wchHasFeature="'sectionOrganizations'">
              <a ngRouterLink="registrations">
                <span aria-hidden="true" class="fa registrations_icon"></span>
                <span class="nav-label">{{
                  "main.menu.registrations" | transloco
                  }}</span>
              </a>
            </li>

            <li [ngClass]="{ active: screen == 'main.export' }" *wchHasFeature="'sectionExport'">
              <a ngRouterLink="main.export">
                <span aria-hidden="true" class="fa export_icon"></span>
                <span class="nav-label">{{
                  "main.menu.export" | transloco
                  }}</span>
              </a>
            </li>
          </ul>
        </li>

        <li class="only-mobile">
          <a ngRouterLink="logout">
            <span aria-hidden="true" class="fa fa-fw fa-power-off"></span>
            <span class="nav-label">{{ "page.logout-bt" | transloco }}</span>
            <!--<span class="fa arrow"></span>-->
          </a>
        </li>
      </ul>
      <ul class="footer-menu" #footerMenu>
        <li *ngIf="currentUser.organizationObj?.contactUs" class="footer-sub-menu"
          [ngClass]="{ active: screen == 'main.contact-us' }">

          <a (click)="goTocontactUs()">
            <span aria-hidden="true" class="fa comment_icon"></span>
            <span class="nav-label">{{ "main.menu.contact-us" | transloco }}</span>
          </a>
        </li>
        <li>
          <a href="https://greenanthelp.zendesk.com/hc/pt-br" target="_blank">
            <span aria-hidden="true" class="fa help_icon"></span>
            <span class="nav-label">{{ "main.menu.help" | transloco }}</span>
          </a>
          <span *ngIf="footerImages.length > 0">
            <div *ngFor="let footerImage of footerImages">
              <img [src]="footerImage.small" alt="" class="full-footer-logo" [ngClass]="footerImage.smallImageClass" />
              <img [src]="footerImage.textImage" alt="" class="small-footer-logo"
                [ngClass]="footerImage.textImageClass" />
            </div>
          </span>
        </li>
      </ul>
    </nav>
  </div>
</div>
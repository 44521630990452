import * as angular from 'angular';
import * as numeral from 'numeral';

const formatWatt = function (input, unit = 'W', convert = true, decimals = '00') {

  var raw = input;
  var symbol = unit;
  let format = `0,0.${decimals}`;

  if (convert) {
    if (Math.abs(input) > (1000 * 1000 * 1000)) {
      input = input / 1000 / 1000 / 1000;
      symbol = `G${unit}`;
    }
    else if (Math.abs(input) > (1000 * 1000)) {
      input = input / 1000 / 1000;
      symbol = `M${unit}`;
    }
    else if (Math.abs(input) >= 1000) {
      input = input / 1000;
      symbol = `k${unit}`;
      //format = '0,0.0';
    }
    else if (Math.abs(input) < 1000 && unit == 'W') {
      format = '0,0';
    }
  }

  return {
    formattedInput: numeral(input).format(format),
    symbol: symbol
  }
}

const DashboardFilter = angular
  .module('dashboard-filters', [])
  .filter('paginated', [function ($) {
    return function (items, page, itemsperpage) {
      var i = 0;
      if (items) {
        items.forEach(function (item, index) {
          i++
          item.position = i;
        });
        page--;
        var st = page * itemsperpage;
        var end = st + itemsperpage;
        end = end > items.length ? items.length : end
        return items.slice(st, end);
      }


    }
  }])
  .filter('formatWatt', ["$filter", function ($filter) {
    return function (input, convert, symbol, decimals) {

      let formatted = formatWatt(input, symbol, convert, decimals);
      return {
        value: formatted.formattedInput,
        symbol: formatted.symbol,
        valueRaw: input
      };
    };
  }])
  .filter('genericFormatter', ["$filter", function ($filter) {
    return function (input, unit, decimals, convert) {

      let formatted = formatWatt(input, unit, convert, decimals);
      let value = formatted.formattedInput;
      if (value < 0) {
        value = -value
      }
      let symbol = formatted.symbol;
      return `${value} ${symbol}`
    };
  }])
  .filter('toWatts', ["$filter", function ($filter) {
    return function (input, convert) {

      let formatted = formatWatt(input, 'W', convert);
      return {
        value: formatted.formattedInput,
        symbol: formatted.symbol + 'h',
        valueRaw: input
      };
    };
  }])
  .filter('FP', ["$filter", function ($filter) {
    return function (input) {
      return numeral(input).format('0.00')
    };
  }])
  .filter('zipcode', function () {
    return function (input) {
      if (!input) {
        return input;
      }
      if (input.toString().length === 8) {
        return input.toString().slice(0, 5) + "-" + input.toString().slice(5);
      } else if (input.toString().length === 5) {
        return input.toString();
      } else {
        return input;
      }
    };
  })
  .filter('duration', function () {
    return function (input) {
      if (input < 3600) {
        var minutes = parseInt((input / 60) % 60);
        var seconds = parseInt(input % 60);
        return minutes + " min " + (seconds ? seconds + " seg" : "");
      } else {
        var hour = parseInt((input / 60) / 60);
        var minutes = parseInt((input / 60) % 60);
        var seconds = parseInt(input % 60);
        var hourSuffix = "";
        if (hour > 1) {
          hourSuffix = " hrs ";
        } else {
          hourSuffix = " hr ";
        }

        return hour + hourSuffix + minutes + " min " + (seconds ? seconds + " seg" : "");
      }
    }
  }).filter('formatInvoice', ["$filter", '$sce', function ($filter, $sce) {
    return function (invoice) {
      if (invoice && invoice.value) {
        let moneyFormat = "0,0.00";

        // if(invoice.value > 100000){
        //   moneyFormat  ='0,'
        // }

        return $sce.trustAsHtml(`<span class="unit">${invoice.currency}</span> <span class="money-value">${numeral(invoice.value).format(moneyFormat)}</span>`);
      } else {
        return $sce.trustAsHtml(`<span class="unit">R$</span> <span class="money-value">0</span>`);
      }
    }
  }]).filter('formatConsumption', ["$filter", '$sce', function ($filter, $sce) {
    return function (consumption) {
      if (consumption) {
        //let moneyFormat = "0,0.00";
        let formatted = formatWatt(consumption, 'W', true);
        // if(invoice.value > 100000){
        //   moneyFormat  ='0,'
        // }

        return $sce.trustAsHtml(`<span class="money-value">${formatted.formattedInput}</span> <span class="unit">${formatted.symbol + 'h'}</span>`);
      }
    }
  }])
  .filter('formatMoney', ["$filter", '$sce', function ($filter, $sce) {
    return function (value) {
      if (value || value === 0) {
        let moneyFormat = "0,0.00";

        // if(value > 100000){
        //   moneyFormat  ='0,'
        // }

        return numeral(value).format(moneyFormat);
      }
    }
  }])
  .filter('inspectionDay', ['$translate', function ($translate) {
    return function (inspectionDay) {
      return inspectionDay == 0 ? $translate.instant('global.branch.month-end') : `${$translate.instant('main.navigator.hour')} ${inspectionDay}`;
    }
  }])
  .filter('formatPercentage', [function () {
    return function (value) {
      let format = "0.0"
      return numeral(value).format(format);
    }
  }])
  .filter('defaultIfNull', [function () {
    return function (value, defaultValue) {
      return value ? value : defaultValue;
    }
  }]);

export default DashboardFilter.name


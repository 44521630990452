<app-my-loader></app-my-loader>
<div class="container-fluid login-wrapper">
  <div class="row">
    <div class="col-sm-4">

      <div id="login" class="vertical-center-row">
        <div class="logo-header">
          <h1 class="logo text-center">
              <img class="logo-icon" src="/assets/images/custom_icons/logo_greenant_login.png" alt="icon" />
          </h1>
        </div>

        <div>
          <h2 class="text-center greenant-count">{{ 'forgot.headerNewPassword' | transloco }} </h2><br>
          <div
              *ngIf="message"
              class="alert alert-theme alert-dismissible"
              [ngClass]="message.error ? 'alert-danger': 'alert-success'">
              <div>
                  <i class="fa fa-2 icon-notity"
                  [ngClass]="message.error ? 'fa-exclamation-circle': 'fa-check-circle'"
                  aria-hidden="true"></i>
                  <span class="message-error-login">{{ message.msg }}</span>
              </div>
          </div>
          <form novalidate [formGroup]="form" (ngSubmit)="changePassword(form)">
            <input type="hidden" name="token" formControlName="token">

            <div class="form-group">
              <div class="div-email">
                <label>{{'forgot.ph-NewPassword' | transloco }}</label>
                <input type="password" class="email first-field last-field form-control" name="password" placeholder="{{'forgot.ph-NewPassword' | transloco }}" formControlName="password">
              </div>
              <div class="div-email">
                <label>{{'forgot.ph-NewPasswordConfirmation' | transloco }}</label>
                <input type="password" class="email first-field last-field form-control" name="passwordConfirmation" placeholder="{{'forgot.ph-NewPasswordConfirmation' | transloco }}" formControlName="passwordConfirmation">
              </div>
              <div>
                <button
                    type="submit"
                    name="login"
                    class="btn btn-login form-control">
                        {{ "forgot.value-submit" | transloco }}
                </button>
              </div>

            </div>
          </form>
          <div class="bottom-text">{{ 'signup.login-question' | transloco }} <a href="/login">{{ 'signup.login-link' | transloco }}</a> </div>
        </div>
      </div>

      <div class="text-center">
        <div class="terms">
          <a target="_blank" href="http://www.greenant.com.br/termos-de-servico/">{{ 'signup.terms.p2' | transloco }}</a> {{ 'signup.terms.p3' | transloco }}
          <a target="_blank" href="http://www.greenant.com.br/politica-de-privacidade/">{{ 'signup.terms.p4' | transloco }} </a>
        </div>

        <div class="copyright">&copy; {{currentDate | date:'yyyy'}} GreenAnt</div>
      </div>
    </div>
    <div class="col-sm-8 embed-responsive embed-responsive-image">
      <img [src]="splashPageURL" alt="_parent" class="embed-responsive-item">
    </div>
  </div>
</div>

<script>
  $('.pace-progress').css("top", "0px");
</script>

import { HttpClient } from "@angular/common/http";
import { downgradeInjectable } from "@angular/upgrade/static";
import * as angular from "angular";
import { CookieService } from "ngx-cookie-service";
import { DistributorService } from "webapp/app/shared/services/distributor.service";
import { ng2LocationService } from "webapp/app/shared/services/location.service";
import { ngUserService } from "webapp/app/shared/services/user.service";

import "./app/services/alert-service.js";
import "./app/services/ccee.service";
import "./app/services/appliance.service.js";
//import "webapp/app/shared/services/datasource.service";
//import "webapp/app/shared/services/meter.service";
import "./app/services/branch.service.js";
import "./app/services/category.service.js";
//import "webapp/app/shared/services/charts-service";
import "./app/services/classification.service.js";
import "./app/services/company.service.js";
// <!--Services-->
//import "webapp/app/shared/services/calendar.service";
//import "./app/auth/auth.service";
//import "webapp/app/shared/services/config.service";
import "./app/services/composition.service.js";
import "./app/services/consumption.service.js";
//import "../../webapp/src/app/shared/services/energy-bill.service.js";
import "./app/services/credentials.service.js";
//import "../../webapp/src/app/shared/services/distributor.service.js";
angular.module('dashboard-services').service(DistributorService.name, downgradeInjectable(DistributorService));
//import "./app/services/location.service.js";
//import "../../webapp/src/app/exporter/services/export.service.js";
import "./app/services/kpi.service.js";
import "./app/services/organization.service.js";
import "./app/services/role.service.js";
//import "webapp/app/shared/services/contract.service";
import "./app/services/tariff.service.js";
import "./app/services/task.service.js";
//import "webapp/app/shared/services/group.service";
//import "webapp/app/shared/services/measurement.service";
//import "webapp/app/shared/services/user.service";
angular.module('AppModule').factory(ngUserService.name, ngUserService.def);
angular.module('dashboard-services').factory(ng2LocationService.name, ng2LocationService.def);
angular.module('dashboard-services').service(HttpClient.name, downgradeInjectable(HttpClient));
angular.module('dashboard-services').service(CookieService.name, downgradeInjectable(CookieService.name));


import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "webapp/app/auth/middleware/token.interceptor";
import { CacheInterceptor } from "./cache.interceptor";
import { LoaderInterceptor } from "./global-loading.interceptor";



export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
]

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'webapp/environments/environment';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { AuthService } from '../auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject(NG_ROUTER) private $state,
    private auth: AuthService,
    private cookie: CookieService
  ) {}

  intercept(
    request: HttpRequest < unknown > ,
    next: HttpHandler
  ): Observable < HttpEvent < unknown >> {
    const isOwnBackendUrl = this.isOwnBackendUrl(request.url);
    console.log(request)

    let newReq: HttpRequest < any > | null = null;

    newReq = request.clone({
      headers: request.headers.set(
        'Authorization',
        'JWT ' + this.cookie.get('auth')
      ),
      withCredentials: true,
    });
    console.log(newReq)

    return next.handle(newReq).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleUnauthorized(error);
      })
    );
  }

  handleUnauthorized = (error: HttpErrorResponse) => {
    const isOwnBackendUrl = this.isOwnBackendUrl(error.url);
    if (isOwnBackendUrl && error.status === HttpStatusCode.Unauthorized) {
      // auto logout if 401 response returned from api
      this.auth.logout();
      this.$state.go('login');
      return EMPTY;
    } else {
      return throwError(error);
    }
  };

  private isOwnBackendUrl(url: string | null) {
    return url?.startsWith(environment.backendUrl);
  }
}
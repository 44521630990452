import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class NavStateService extends BehaviorSubject<boolean> {

  constructor() {
    super(false)
  }

  toogle() {
    this.next(!this.value)
  }
}

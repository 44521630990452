import { Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'wch-component-container',
  templateUrl: './component-container.component.html',
  styleUrls: ['./component-container.component.scss']
})
export class ComponentContainerComponent implements OnInit {
  @Input() ref: string | undefined

  constructor() { }

  ngOnInit(): void {}

}

export const ng2ComponentContainer = {
  name: 'wchComponentContainer',
  def: downgradeComponent({ component: ComponentContainerComponent })
}

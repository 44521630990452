import { InjectionToken } from '@angular/core';
import * as SailsSocket from 'sails.io.js';
import * as io from 'socket.io-client';
import { environment } from 'webapp/environments/environment';

//https://sailsjs.com/documentation/reference/web-sockets/socket-client

export const sailsConn = SailsSocket(io).sails.connect(environment.sailsUrl)

export const SAILS_SOCKET = new InjectionToken<SailsSocket.Socket>('Sails socket connection')

export const socketFactory = () => {
    return sailsConn
}
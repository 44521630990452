import * as angular from 'angular';

angular
  .module('dashboard-services')
  .service('CredentialsService', CredentialsService);

CredentialsService.$inject = ['HttpUtil', 'AuthService'];

/* @ngInject */
function CredentialsService(HttpUtil, AuthService) {
  return {
    getCredentials: getCredentials,
    getDistributors: getDistributors,
    createCredentials: createCredentials,
    updateCredential: updateCredential,
    deleteCredentials: deleteCredentials,
    getCredentialAccess: getCredentialAccess,
    getInstallNumbers: getInstallNumbers,
    addInstallNumber: addInstallNumber,
    removeInstallNumber: removeInstallNumber,
  };

  function getCredentials(populateMetadatas = false) {
    return HttpUtil.get({
      url: '/api/credentials',
      params: {
        populateMetadatas: populateMetadatas,
      },
    })
      .then((res) => {
        return res.data.credentials;
      })
      .catch((error) => {
        throw error;
      });
  }

  function getDistributors() {
    return HttpUtil.get({ url: '/api/credentials/distributors' })
      .then((res) => {
        return res.data.distributors;
      })
      .catch((error) => {
        throw error;
      });
  }

  function createCredentials(data) {
    return HttpUtil.post({ url: '/api/credentials', data: data })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  function deleteCredentials(id) {
    return HttpUtil.del({
      url: `/api/credentials/${id}`,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw Error(error);
      });
  }

  function updateCredential(id, body) {
    return HttpUtil.put({
      url: `/api/credentials/${id}`,
      method: 'put',
      data: body,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw Error(error);
      });
  }

  function getCredentialAccess(robotApiId) {
    return HttpUtil.get({
      url: `/api/credentials/access/${robotApiId}`,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw Error(error);
      });
  }

  function getInstallNumbers(credId) {
    return HttpUtil.get({
      url: `/api/credentials/${credId}/installnumbers`,
    })
      .then((res) => res.data.installNumbers)
      .catch((error) => {
        throw error;
      });
  }

  function addInstallNumber(credId, installNumber) {
    return HttpUtil.put({
      url: `/api/credentials/${credId}/installnumbers/${installNumber}/add`,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }

  function removeInstallNumber(credId, installNumber) {
    return HttpUtil.put({
      url: `/api/credentials/${credId}/installnumbers/${installNumber}/remove`,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

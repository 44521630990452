import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'webapp/app/auth/auth.service';
import { LoaderService } from 'webapp/app/shared/global-loading/loader.service';
import { NG_ROUTER, NG_STATE_PARAMS } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { setupDowngradeName } from 'webapp/hybrid-helpers/setupDowngradeName';


@Component({
	selector: 'app-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['../login/login.component.scss'],
	host: {
	  'class': 'login'
	},
	encapsulation: ViewEncapsulation.None
})
export class NewPasswordComponent implements OnInit {
	readonly currentDate = new Date();

	readonly splashPages = [
		"/assets/images/green_analitycs.png",
		"/assets/images/green_contratos.png"
	];
	readonly bgColors = [
		'#cccc99',
		'#7affbf'
	];

	form = this.fb.group({
		password: [null, [Validators.required,  Validators.minLength(6)]],
		passwordConfirmation: [null, [Validators.required, Validators.minLength(6), this.passwordConfirming]],
		token: [null, [Validators.required]]
	  })

	splashPageURL: any;
	message: {error: boolean, msg: string} | undefined = undefined

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_STATE_PARAMS) private $stateParams,
		private i18n: TranslocoService,
		private auth: AuthService,
		private loader: LoaderService,
		private sanitize: DomSanitizer,
		private fb: FormBuilder
	  ) { }

	ngOnInit(){
		let rand = Math.floor(Math.random() * this.splashPages.length);
		this.splashPageURL = this.sanitize.bypassSecurityTrustResourceUrl(
		this.splashPages[rand]
		);
		$('.embed-responsive.embed-responsive-image').eq(0).css('background-color', this.bgColors[rand]);
	}

	changePassword({ valid, value }: AbstractControl) {
		this.loader.isLoading.next(true);
		this.form.get('token')?.setValue(this.$stateParams.token);
		console.log(this.form.value)
		if (this.form.valid) {
			this.auth.newPassword(this.form.value).then(
				(res) => {
					this.message = {error: false, msg: res.message};
					this.loader.isLoading.next(false);
				}).catch((err) => {
					console.log(err)
					let errMsg = err?.error?.error || err?.error?.message || err?.message || err;
					this.message = {error: true, msg: errMsg};
					this.loader.isLoading.next(false);
				});
		} else { //Message mandatory fields
			this.message = {error: true, msg: this.getValidationErrors()};
			this.loader.isLoading.next(false);
		}

	}

	getValidationErrors(): string {
		if(this.form.get('password')?.errors) return this.i18n.translate('signup.validation.password');
		if(this.form.get('passwordConfirmation')?.errors) return this.i18n.translate('signup.validation.passwordConfirmation');
		return this.i18n.translate('signup.validation.mandatory')
	}
	
	passwordConfirming(c: AbstractControl): { invalid: boolean } | null {
		if (c.get('password')?.value !== c.get('passwordConfirmation')?.value) {
			return {invalid: true};
		}
		return null;
	}
}

export const ng2NewPassowordComponent = {
	name: setupDowngradeName(NewPasswordComponent.name),
	def: downgradeComponent({component: NewPasswordComponent})
}

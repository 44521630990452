import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPage } from './dashboard.component';
import { ConsumptionChartComponent } from './charts/consumption-chart/consumption-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ComponentContainerModule } from '../shared/component-container/component-container.module';



@NgModule({
  declarations: [
    DashboardPage,
    ConsumptionChartComponent
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    ComponentContainerModule

  ]
})
export class DashboardModule { }

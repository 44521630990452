import * as angular from 'angular';
import {
  Transition,
  UrlService
} from 'angular-ui-router';
import {
  authRoutes
} from './auth/auth.routing';
import DashboardControllers from './dashboard-controllers.module';
/// <reference path="./oclazyload.d.ts" />

const getOcDependency = (origin): oc.ILazyLoad => origin.injector().get('$ocLazyLoad')

const DashboardRoutes = angular.module('dashboard-routes', [DashboardControllers])

DashboardRoutes.config(['$stateProvider', '$urlServiceProvider',
  function ($stateProvider, $urlServiceProvider: UrlService) {

    let states = [
      ...authRoutes,
      {
        name: 'main',
        templateUrl: '/app/pages/main.html',
        controller: 'MainController',
        controllerAs: 'mainVm',
      },
      {
        name: 'main.measuring-points.**',
        url: '/measuring-points',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/measuring-points/measuring-points.module').then(mod => $ocLazyLoad.load(mod.MeasuringPointsModule))
        }
      },
      {
        name: 'main.breakdown.**',
        url: '/breakdown',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('../../../webapp/src/app/breakdown/breakdown.module').then(mod => $ocLazyLoad.load(mod.NgBreakdownModule))
        }
      },
      {
        name: 'main.dashboard.**',
        url: '/dashboard',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/dashboard/dashboard.module').then(mod => $ocLazyLoad.load(mod.DashboardModule))
        }
      },
      {
        name: 'main.analysis.**',
        url: '/analysis',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/analysis/analysis.module').then(mod => $ocLazyLoad.load(mod.AnalysisModule))
        }
      },
      {
        parent: 'main',
        name: 'alerts.**',
        url: '/alerts',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/alerts/alerts.module').then(mod => $ocLazyLoad.load(mod.AlertsModule))
        }
      },
      {
        name: 'main.freeMarket.**',
        url: '/free-market',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/free-market/free-market.module').then(mod => $ocLazyLoad.load(mod.FreeMarketModule))
        }
      },
      {
        parent: 'main',
        name: 'contracts.**',
        url: '/contracts',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/contracts/contracts.module').then(mod => $ocLazyLoad.load(mod.ContractsModule))
        }
      },

      {
        name: 'main.economy.**',
        url: '/economy',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/economy/economy.module').then(mod => $ocLazyLoad.load(mod.EconomyModule))
        }
      },
      {
        name: 'main.energy-bills.**',
        url: '/energy-bills',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/energy-bill/energy-bill.module').then(mod => $ocLazyLoad.load(mod.EnergyBillModule))
        }
      },
      {
        parent: 'main',
        name: 'registrations.**',
        url: '/registrations',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/registrations/registrations.module').then(mod => $ocLazyLoad.load(mod.RegistrationsModule))
        }
      },
      {
        name: 'main.export.**',
        url: '/export?tab&query',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('../../../webapp/src/app/exporter/exporter.module').then(mod => $ocLazyLoad.load(mod.NgExporterModule))
          //return await import('./pages/export/export.module').then(mod => $ocLazyLoad.load(mod.ExportModule))
        }
      },
      {
        name: 'main.uploads.**',
        url: '/upload',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/uploads/uploads.module').then(mod => $ocLazyLoad.load(mod.UploadsModule))
        }
      },
      {
        name: 'main.user.**',
        url: '/user',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('webapp/app/user/user.module').then(mod => $ocLazyLoad.load(mod.NgUserModule))
        }
      },
      {
        name: 'main.contact-us.**',
        url: '/contact-us',
        lazyLoad: async function ($transition$: Transition) {
          const $ocLazyLoad = getOcDependency($transition$)
          return await import('./pages/contact-us/contact-us.module').then(mod => $ocLazyLoad.load(mod.ContactUsModule))
        }
      }
    ];

    states.forEach((state) => $stateProvider.state(state));
    $urlServiceProvider.rules.otherwise({
      state: 'main.dashboard'
    });
    //$urlRouterProvider.otherwise('/dashboard');
  }
]);

export default DashboardRoutes.name

import * as angular from 'angular';

angular
    .module('dashboard-services')
    .service('CCEEService', CCEEService);

    CCEEService.$inject = ['HttpUtil'];

function CCEEService(HttpUtil) {
    return {
        getCCEEProfileClasses: getCCEEProfileClasses,
        getCCEESubMarkets: getCCEESubMarkets
    }

    function getCCEEProfileClasses(){        
        return HttpUtil.get({
        url: '/api/ccee/profileclasses',
        method: 'get',
        }).then(res => {
        return res.data.profileClasses
        });
    }

    function getCCEESubMarkets(){        
        return HttpUtil.get({
        url: '/api/ccee/submarkets',
        method: 'get',
        }).then(res => {
        return res.data.subMarkets
        });
    }
}
  
import * as angular from 'angular';

angular
    .module('dashboard-services')
    .factory('CategoryService', CategoryService);

CategoryService.$inject = ['HttpUtil'];

function CategoryService(HttpUtil) {
    var categoryService = {
        findAll: findAll,
        applianceType: applianceType,
        remove: remove,
        save: save,
        enableDetection: enableDetection,
        saveDetection: saveDetection
    }

    return categoryService;

    function findAll(params) {
        return HttpUtil.get({ url: '/api/category', params: params });
    }

    function applianceType(params) {
        return HttpUtil.get({ url: '/api/applianceType', params: params });
    }

    function save(params) {
        if (params.id) {
            return HttpUtil.post({ url: '/api/category/' + params.id, data: params });
        } else {
            return HttpUtil.post({ url: '/api/category', params: params });
        }
    }

    function remove(params) {
        if (params.id) {
            return HttpUtil.del({ url: '/api/category/' + params.id, params: params });
        }
    }

    function enableDetection(params) {
        if (params.uid) {
            return HttpUtil.get({ url: '/api/detection/' + params.uid });
        }
    }

    function saveDetection(params) {
        if (params.categoryId) {
            return HttpUtil.post({ url: '/api/category/' + params.categoryId + '/detection', data: params });
        }
    }
}
import { Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import { HttpUtil } from "./http.util";

@Injectable({ providedIn: 'root' })
export class GroupService {

  currentGroup = {};

  constructor(
    private HttpUtil: HttpUtil,
  ) { }


  getDataSources() {
    return this.HttpUtil.get({ url: '/api/datasources', params: { type: "group" } })
      .then(res => { return res.data.dataSources });
  }

  getContracts(dataSourceId) {
    return this.HttpUtil.get({ url: `/api/datasources/${dataSourceId}/contracts` })
      .then(res => { return res.data.contracts });
  }

  groupTotal(group) {
    return this.HttpUtil.get({ url: `/api/groups/${group.groupId}/total`, params: group, cache: true });
  }

  groupMeasurements(params): Promise<any> {
    if (params.type && params.type == "invoice") {
      return this.HttpUtil.get({ url: `/api/groups/${params.groupId}/total`, method: 'get', params: params, cache: true })
        .then(res => res.data.measurement);
    } else {

      return this.HttpUtil.get({ url: `/api/groups/${params.groupId}/measurements`, method: 'get', params: params, cache: true })
        .then(res => res.data.measurement);
    }
    // if(params.type && params.type == "invoice"){
    //   return this.HttpUtil.get({url: `/api/branches/${params.branchId}/groups/${params.groupId}/total`, method: 'get', params: params})
    //   .then(res => res.data.measurement);
    // } else{
    //
    //   return this.HttpUtil.get({url: `/api/branches/${params.branchId}/groups/${params.groupId}/measurements`, method: 'get', params: params})
    //   .then(res => res.data.measurement);
    // }
  }

  saveGroup(group) {
    if (group.id) {
      return this.HttpUtil.put({ url: `/api/groups/${group.id}`, method: 'put', data: group });
    } else {
      return this.HttpUtil.post({ url: `/api/groups/`, method: 'post', data: group });
    }
  }

  deleteGroup(group) {
    if (group.id) {
      return this.HttpUtil.del({ url: `/api/groups/${group.id}`, method: 'delete' });
    }
  }
  //
  //  groups(params){
  //   if(params && params.branchId){
  //     return this.HttpUtil.get({ url: `/api/branches/${params.branchId}/datasources`, ttl: 60*1000, purge:params.purge});
  //   } else {
  //     return this.HttpUtil.get({ url: `/api/groups`})
  //       .then( res => { return res.data.groups });
  //   }
  // }

  getDemand(params) {
    if (params.groupId && params.period) {
      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/demand`, params: params, ttl: 60 * 1000 })
        .then(resp => {
          return resp.data;
        });
    }
  }

  getReactiveSurplus(params) {
    if (params.groupId && (params.period || (params.startDate && params.endDate))) {
      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/reactive`, params: params, ttl: 60 * 1000 })
        .then(resp => {
          return resp.data;
        });
    }
  }

  getForecasts(params) {
    if (params.type && params.type == "invoice") {
      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/forecasts/total`, params: params })
        .then(res => res.data.measurement);
    } else {

      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/forecasts`, params: params })
        .then(res => res.data.measurement);
    }
  }

  getForecastsTotal(params) {
    return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/forecasts`, params: params })
      .then(res => res.data.measurement);
  }

  getFits(params) {
    if (params.type && params.type == "invoice") {
      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/fits/total`, params: params })
        .then(res => res.data.measurement);
    } else {

      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/fits`, params: params })
        .then(res => res.data.measurement);
    }
  }

  getGroup(params) {
    return this.HttpUtil.get({ url: '/api/groups/' + params.groupId, params: params })
      .then(res => { return res.data.group });
  }

  getGroupDataSources(params): Promise<any[]> {
    return this.HttpUtil.get({ url: '/api/groups/' + params.groupId + '/datasources', params: params })
      .then(res => { return res.data.dataSources });
  }

  getUsers(params) {
    return this.HttpUtil.get({ url: '/api/groups/' + params.groupId + '/users', params: params })
      .then(res => { return res.data.users });
  }

}

export const ng2GroupService = {
  name: GroupService.name,
  def: downgradeInjectable(GroupService)
}

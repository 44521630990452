import * as angular from 'angular';
import { ng2ConsumptionChart } from 'webapp/app/dashboard/charts/consumption-chart/consumption-chart.component';
import { ng2SourceSelector } from 'webapp/app/dashboard/source-selector/source-selector.component';
import { ng2AppShell } from 'webapp/app/shared/app-shell/app-shell.component';
import { ng2ComponentContainer } from 'webapp/app/shared/component-container/component-container.component';



import { gaCalendarNavigator } from './directives/calendar-navigator/calendar-navigator.directive';
import { ngCompanySelector } from './directives/company-selector/company-selector.component';
import { ngComponentContainer } from './directives/component-container/component-container';

import { ngDatasourceSelector } from './directives/datasource-selector/datasource-selector.component';
import { ngGaLoading } from './directives/loading/loading.component';
import { ngMeterCloseCycle } from './directives/meter-modals/meter-close-cycle.directive';
import { ngOrganizationSelectorMulti } from './directives/organization-selector-multi/organization-selector-multi.component';
import { ngOrganizationSelector } from './directives/organization-selector/organization-selector.component';
import { powerFactorChart } from './directives/power-factor-chart/power-factor-chart.directive';
import { ngRealtimeComponent } from './directives/realtime/realtime.directive';
import { ngSourceSelector } from './directives/source-selector/source-selector.component';
import { ngGaWelcome } from './directives/welcome/welcome.component';

const DashboardDirectives = angular.module('dashboard-directives', [])

DashboardDirectives.directive('convertToNumber', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel?.$parsers.push(function (val) {
        return parseInt(val, 10);
      });
      ngModel?.$formatters.push(function (val) {
        return '' + val;
      });
    }
  };
});

//ngDatasourceSelector
DashboardDirectives.directive(ngComponentContainer.name, ngComponentContainer.def);
//DashboardDirectives.directive(ng2HeaderComponent.name, ng2HeaderComponent.def);
DashboardDirectives.directive(ng2AppShell.name, ng2AppShell.def);
//DashboardDirectives.directive(ngSourceSelector.name, ngSourceSelector.def);
DashboardDirectives.directive(ng2SourceSelector.name, ng2SourceSelector.def);
//DashboardDirectives.directive(ng2NavbarComponent.name, ng2NavbarComponent.def);
DashboardDirectives.directive(ng2ComponentContainer.name, ng2ComponentContainer.def);
DashboardDirectives.directive(ng2ConsumptionChart.name, ng2ConsumptionChart.def);
DashboardDirectives.component(ngOrganizationSelector.name, ngOrganizationSelector.def);
DashboardDirectives.component(ngOrganizationSelectorMulti.name, ngOrganizationSelectorMulti.def);
DashboardDirectives.component(ngDatasourceSelector.name, ngDatasourceSelector.def);
DashboardDirectives.directive(ngSourceSelector.name, ngSourceSelector.def);
DashboardDirectives.component(ngCompanySelector.name, ngCompanySelector.def);
DashboardDirectives.component(ngGaLoading.name, ngGaLoading.def);
DashboardDirectives.component(ngGaWelcome.name, ngGaWelcome.def);
DashboardDirectives.component(ngMeterCloseCycle.name, ngMeterCloseCycle.def);
DashboardDirectives.directive(gaCalendarNavigator.name, gaCalendarNavigator.def);
DashboardDirectives.component(powerFactorChart.name, powerFactorChart.def);
DashboardDirectives.component(ngRealtimeComponent.name, ngRealtimeComponent.def);


export default DashboardDirectives.name


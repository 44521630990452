
MainController.$inject = ['UserService', '$location'];

/* @ngInject */
function MainController(UserService, $location) {
  var vm = this;

  vm.$onInit = function () {
    console.info("MainController init()");
    UserService.getCurrentUser().then(user => {
      vm.currentUser = user;
      vm.currentUser.isPersonificated = UserService.isPersonificated();
      var lang = vm.currentUser.language.replace('-', '_');

      if ($location.absUrl().indexOf("dashboard.greenant.com.br") > 0) {

        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
          }, i[r].l = 1 * new Date(); a = s.createElement(o),
            m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

        ga('create', 'UA-83607245-1', { 'userId': vm.currentUser.id });
        ga('send', 'pageview', {
          'dimension1': vm.currentUser.email,
          'dimension2': new Date()
        });


        /*hotjar*/
        (function (h, o, t, j, a, r) {
          h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
          h._hjSettings = { hjid: 278923, hjsv: 5 }; a = o.getElementsByTagName('head')[0];
          r = o.createElement('script'); r.async = 1; r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');


        //mixpanel
        (function (e, a) {
          if (!a.__SV) {
            var b = window; try { var c, l, i, j = b.location, g = j.hash; c = function (a, b) { return (l = a.match(RegExp(b + "=([^&]*)"))) ? l[1] : null }; g && c(g, "state") && (i = JSON.parse(decodeURIComponent(c(g, "state"))), "mpeditor" === i.action && (b.sessionStorage.setItem("_mpcehash", g), history.replaceState(i.desiredHash || "", e.title, j.pathname + j.search))) } catch (m) { } var k, h; window.mixpanel = a; a._i = []; a.init = function (b, c, f) {
              function e(b, a) {
                var c = a.split("."); 2 == c.length && (b = b[c[0]], a = c[1]); b[a] = function () {
                  b.push([a].concat(Array.prototype.slice.call(arguments,
                    0)))
                }
              } var d = a; "undefined" !== typeof f ? d = a[f] = [] : f = "mixpanel"; d.people = d.people || []; d.toString = function (b) { var a = "mixpanel"; "mixpanel" !== f && (a += "." + f); b || (a += " (stub)"); return a }; d.people.toString = function () { return d.toString(1) + ".people (stub)" }; k = "disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
              for (h = 0; h < k.length; h++)e(d, k[h]); a._i.push([b, c, f])
            }; a.__SV = 1.2; b = e.createElement("script"); b.type = "text/javascript"; b.async = !0; b.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === e.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//) ? "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js"; c = e.getElementsByTagName("script")[0]; c.parentNode.insertBefore(b, c)
          }
        })(document, window.mixpanel || []);
        mixpanel.init("2ad331468e235bdb414a88961b00975e");

        mixpanel.identify(vm.currentUser.id);
        mixpanel.people.set({
          "$email": vm.currentUser.email,
          "$created": vm.currentUser.createdAt,
          "$last_login": moment().format(),
          "organizationPlan": vm.currentUser.organizationObj.billingplan.description
        });

        mixpanel.track("acesso", {
          "email": vm.currentUser.email,
          "data": moment().format(),
          "organization": vm.currentUser.organizationObj.name,
          "organizationPlan": vm.currentUser.organizationObj.billingplan.description
        });
      } else {
        console.info("Not in production");
      }
    });
  };
}

export const ngMainController = {
  name: 'MainController',
  def: MainController
}
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { KpiTopbarComponent } from "./kpi-topbar/kpi-topbar.component";

@NgModule({
    declarations: [
        KpiTopbarComponent
    ],
    exports: [
        KpiTopbarComponent
    ],
    imports: [
      CommonModule,
      TranslocoModule,
      ReactiveFormsModule
    ]
  })
  export class AnalysisModule { }
(function () {
    'use strict';
    angular
        .module('dashboard-directives')
        .directive('modalDelete', ['UserService', 'MeterService', 'GroupService', 'DataSourceService', function(UserService, MeterService, GroupService, DataSourceService) {
            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    data: '=data',
                    sufix: '=sufix',
                    title: '=title',
                    btnclick: '=btnclick',
                    colorClass: '@?colorClass',
                    size: '@?size'
                },
                link: function($scope) {
                    $scope.userService = UserService;
                    $scope.meterService = MeterService;
                    $scope.groupService = GroupService;
                    $scope.dataSourceService = DataSourceService;
                },
                templateUrl: '/app/directives/modal-delete/modal-delete.htm'
            }
        }])
})();
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import { CookieService } from "ngx-cookie-service";
import { environment } from "webapp/environments/environment";
import { Login } from "./login.model";

@Injectable({ providedIn: 'any' })
export class AuthService {
  private token: string | null = null

  constructor(
    private http: HttpClient,
    private cookies: CookieService
  ) { }

  saveAuthCookie(token, personificate = false, keepConnected = false) {
    if (personificate) {
      this.cookies.set('original_auth', this.cookies.get('auth'));
    }
    let params: any = {};
    if (keepConnected) {
      let now = new Date();
      params.expires = new Date(now.getFullYear(), now.getMonth() + 3, now.getDate());
    }
    this.cookies.set('auth', token, params);
  }

  saveUserEmail(email, personificate = false) {
    if (personificate) {
      this.cookies.set('original_email', this.cookies.get('email'));
    }

    this.cookies.set('email', email);
  }

  getUserEmail(emailKey = 'email') {
    return this.cookies.get(emailKey);
  }

  getAuthCookie(authKey = 'auth') {
    return this.cookies.get(authKey);
  }

  isAuthenticated(authKey = 'auth') {
    return this.cookies.check(authKey)
  }

  isPersonificated() {
    return this.cookies.check('original_auth')
  }

  deleteAuthCookie() {
    this.cookies.delete('auth');
    this.cookies.delete('original_auth');
    this.cookies.delete('email');
    this.cookies.delete('original_email');
  }

  setRedirectUrlCookie(url) {
    if (url) {
      this.cookies.set('redirectUrl', url);
    }
  }

  popRedirectUrlCookie() {
    let redirectUrl = this.cookies.get('redirectUrl');
    this.cookies.delete('redirectUrl');
    return redirectUrl
  }

  async auth(userParam) {
    if (this.isAuthenticated()) {
      this.token = this.getAuthCookie();
      return { token: this.token };
    } else {
      const params = userParam;
      //get token by cookie
      return await this.login(params)
    }
  }

  personificate(userParam) {
    let token = userParam.token || this.getAuthCookie();
    let keepCookies =
      userParam.keepCookies != undefined ? userParam.keepCookies : true;

    let params = { email: userParam.email, password: '', token: token, keepCookies: keepCookies };
    
    //get token by cookie
    return this.login(params)
  }

  despersonificate() {
    let originalToken = this.getAuthCookie('original_auth');
    let originalEmail = this.getAuthCookie('original_email');

    console.info("despersonifcate remove cookies");
    this.cookies.delete('original_auth');
    this.cookies.delete('original_email');
    return this.personificate({ email: originalEmail, token: originalToken, keepCookies: false });
  }

  login(body: { email, password, keepCookies?, keepConnected?, token?}) {
    return this.http.post<Login>(environment.backendUrl + '/api/login', body).toPromise()
    //TODO: Remover quando a req passar a emitir 400 e não 200 ao falhar
    .then((data) => {
      if(!data?.user) throw data
      return data
    })
    .then(data => {
      if (data?.user) {
        this.token = data.token;
        this.saveAuthCookie(this.token, body.keepCookies, body.keepConnected);
        this.saveUserEmail(data.user.email, body.keepCookies);
      }
      return data;
    });
  }

  logout(param?: any): Promise<any> {
    this.deleteAuthCookie();
    this.token = '';
    if (this.cookies.check('original_auth')) {
      return this.http.post(environment.backendUrl + '/api/logout', param?.user).toPromise().then(() => {
        return true;
      });
    } else {
      console.log('NOT TRUE')
      return this.http.post(environment.backendUrl + '/api/logout', param?.user).toPromise();
    }
  }

  forgot(userParam) {
    const params = { user: userParam };
    return this.http.post(environment.backendUrl + '/api/forgot', params.user).toPromise() as Promise<any>;
  }

  signup(userParam) {
    const params = { user: userParam };
    return this.http.post(environment.backendUrl + '/api/signup', params.user).toPromise() as Promise<any>;
  }

  active(token) {
    const params = { token: token };
    return this.http.post(environment.backendUrl + '/api/active', { token: params.token }).toPromise() as Promise<any>;
  }

  newPassword(user) {
    const params = { user: user };
    return this.http.post(environment.backendUrl + '/api/newPassword', params.user).toPromise() as Promise<any>;
  }
}

export const ng2AuthService = {
  name: AuthService.name,
  def: downgradeInjectable(AuthService)
}
<app-my-loader></app-my-loader>
<div class="container-fluid login-wrapper">
  <div class="row">
    <div class="col-sm-4">
      <div id="login">
        <div class="logo-header">
          <h1 class="logo text-center">
            <img
              class="logo-icon"
              src="/assets/images/custom_icons/logo_greenant_login.png"
              alt="icon"
            />
          </h1>
        </div>

        <div>
          <h2 class="text-center greenant-count">
            {{ "login.header" | transloco }}
          </h2>
          <br />
          <div
            *ngIf="message"
            class="alert alert-theme alert-danger alert-dismissible"
          >
            <div>
              <i
                class="fa fa-exclamation-circle fa-2 icon-notity"
                aria-hidden="true"
              ></i>
              <span class="message-error-login">{{ message }}</span>
            </div>
          </div>
          <form [formGroup]="form" (ngSubmit)="onSubmit(form)" novalidate>
            <div class="form-group">
              <div class="div-email">
                <label>{{ "login.ph-email" | transloco }}</label>
                <input
                  type="text"
                  class="first-field email form-control"
                  formControlName="email"
                  name="email"
                />
              </div>
              <div class="div-password">
                <label>{{ "login.ph-password" | transloco }}</label>
                <input
                  type="password"
                  class="last-field form-control"
                  formControlName="password"
                  name="password"
                />
              </div>
              <br />
              <div class="login-help">
                <div>
                  <div class="float-left">
                    <input type="checkbox" name="keepConnected" />
                  </div>
                  <div class="float-left div-keep-connected">
                    {{ "login.keep-conected" | transloco }}
                  </div>
                </div>
                <a class="forgot" href="/forgot">
                  {{ "login.forgot-password" | transloco }}</a
                >
              </div>
              <button
                type="submit"
                id="loginButton"
                class="btn btn-login form-control"
              >
                {{ "login.value-submit" | transloco }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="text-center">
        <div class="terms">
          <a target="_blank" href="http://www.greenant.com.br/termos-de-servico/">{{
            "signup.terms.p2" | transloco
          }}</a>
          {{ "signup.terms.p3" | transloco }}
          <a
            target="_blank"
            href="http://www.greenant.com.br/politica-de-privacidade/"
            >{{ "signup.terms.p4" | transloco }}
          </a>
        </div>

        <div class="copyright">
          &copy; {{ currentDate | date: "yyyy" }} GreenAnt
        </div>
      </div>
    </div>
    <div class="col-sm-8 embed-responsive embed-responsive-image">
      <img [src]="splashPageURL" alt="_parent" class="embed-responsive-item">
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppKitMenuDirective } from './app-kit-menu.directive';
import { AppKitResponsiveDirective } from './app-kit-responsive.directive';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [
    AppKitMenuDirective,
    AppKitResponsiveDirective
  ],
  exports: [
    AppKitMenuDirective,
    AppKitResponsiveDirective
  ],
  imports: [
    CommonModule,
    LayoutModule
  ]
})
export class AppKitModule { }

import * as angular from 'angular';

angular
  .module('dashboard-services')
  .service('TariffService', TariffService);

TariffService.$inject = ['HttpUtil'];

/* @ngInject */
function TariffService(HttpUtil) {
  const TariffService = {
    getFormSchemaByTariffType: getFormSchemaByTariffType
  };

  return TariffService;

  function getFormSchemaByTariffType(tariffTypeId) {
    if (!tariffTypeId) return;

    return HttpUtil.get({
      url: `/api/tarifftypes/${tariffTypeId}/schema`,
      method: 'get'
    }).then(res => {
      return res.data;
    }).catch(err => {
      console.log(err);
    });
  }
}
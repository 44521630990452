
ApplianceModalController.$inject = ['$scope', '$log', 'Auxiliar', 'ClassificationService', 'ApplianceService', 'CategoryService', 'CalendarService', 'notify'];

function ApplianceModalController($scope, $log, Auxiliar, ClassificationService, ApplianceService, CategoryService, CalendarService, notify) {
    var vm = this;

    vm.init = init;
    vm.$onDestroy = onDestroy;

    $scope.currentObject = {};
    $scope.pageSize = 6;
    $scope.pager = {};

    var meterId = {}

    $scope.calendarOptions = {
        displayControls: false,
        displayPeriodSelector: false,
        displayBorder: false,
        period: "custom"
    };

    $scope.dateChangeListener = function (dates) {
        vm.dates = dates;
        if (dates && $scope.meter.selected) {
            $scope.changePage(null);
        }
    };

    function onDestroy() {

        if (vm.observerId) {
            CalendarService.unregisterObserver(vm.observerId, "secondary");
        }

        $('#modalClassificationApplianceDetail').remove();
    }

    function init() {
        console.info("init modal");

        showLoading();

        if (!vm.observerId) {
            vm.observerId = CalendarService.registerObserver(onNotify, "secondary");
        }

        $scope.$parent.vm.applianceModalScope = $scope;
        meterId = $scope.$parent.vm.sourceObject.id;
        $scope.modalOpen = false;
    }

    function onNotify(calendarData) {
        showLoading();

        vm.dates = calendarData;
        vm.dates.period = "custom";

        $scope.changePage(null);
    }

    function calcEnergy(duration, power) { return (power / 3600) * duration; };

    /*Populate modal with consumptions list*/
    var loadConsumptionsCallBack = function (consumptions, currentObject, cb) {
        currentObject.consumptions = consumptions;

        var totalActivePower = 0;

        for (var i = 0; i < consumptions.length; i++) {
            var consumption = consumptions[i];

            consumption.duration = moment(consumption.endEventTimestamp).diff(moment(consumption.beginEventTimestamp), 'seconds');
            consumption.activeEnergy = calcEnergy(consumption.duration, consumption.activePower);
        }

        hideLoading();

        if (cb) {
            cb();
        }
    };

    function loadApplianceConsumptions(limit, offset, startDate, endDate, cb) {
        var params = {
            meterId: meterId,
            name: $scope.currentObject.name,
            applianceId: $scope.currentObject.id,
            startDate: startDate,
            endDate: endDate
        };

        if (offset == null) {
            ApplianceService.getAppliancesConsumptions(Object.assign({ count: true }, params)).then(
                function successCallback(consumptions) {
                    $scope.currentObject.count = consumptions[0].count;
                    $scope.currentObject.averageActivePower = consumptions[0].avg;
                    $scope.pager = Auxiliar.getPager($scope.currentObject.count, 1, $scope.pageSize);
                    hideLoading();
                },
                function errorCallback(err) {
                    console.info(err);
                    hideLoading();
                }
            );
        }

        var filterParams = {};
        if (limit) {
            filterParams.limit = limit;
        }

        if (offset) {
            filterParams.offset = offset;
        }

        ApplianceService.getAppliancesConsumptions(Object.assign(filterParams, params)).then(
            function successCallback(res) {
                loadConsumptionsCallBack(res, $scope.currentObject, cb);
            },
            function errorCallback(err) {
                console.info(err);
                hideLoading();
            }
        );
    };

    function loadClassificationConsumptions(limit, offset, startDate, endDate, cb) {
        var params = {
            meterId: meterId,
            classificationId: $scope.currentObject.classificationId,
            startDate: startDate,
            endDate: endDate
        };

        if (offset == null) {
            ClassificationService.getClassificationsConsumptions(Object.assign({ count: true }, params)).then(
                function successCallback(consumptions) {
                    $scope.currentObject.count = consumptions[0].count;
                    $scope.currentObject.averageActivePower = consumptions[0].avg;
                    $scope.pager = Auxiliar.getPager($scope.currentObject.count, 1, $scope.pageSize);
                    hideLoading();
                },
                function errorCallback(err) {
                    hideLoading();
                    console.info(err);
                }
            );
        }

        var filterParams = {};
        if (limit) {
            filterParams.limit = limit;
        }

        if (offset) {
            filterParams.offset = offset;
        }

        ClassificationService.getClassificationsConsumptions(Object.assign(filterParams, params)).then(
            function successCallback(res) {
                loadConsumptionsCallBack(res, $scope.currentObject, cb);
            },
            function errorCallback(err) {
                console.info(err);
                hideLoading();
            }
        );
    };

    $scope.openApplianceDetails = function (item) {
        openModal(item, 'appliance');
        updateCalendar();
    };


    $scope.openClassificationDetails = function (item) {
        openModal(item, 'classification');
        updateCalendar();
    };

    function updateCalendar() {
        if (!vm.observerId) {
            vm.observerId = CalendarService.registerObserver(onNotify, "secondary");
        }

        let datesCopy = {};
        angular.copy($scope.$parent.vm.dates, datesCopy);
        datesCopy.period = "custom";
        CalendarService.updateCalendarData(null, datesCopy, "secondary");
    }

    function openModal(item, type) {
        $('#modalClassificationApplianceDetail').modal("show");
        $('#modalClassificationApplianceDetail').appendTo("body");

        $('#modalClassificationApplianceDetail').on('hidden.bs.modal', function (e) {
            $scope.closeModal();
        });

        $scope.modalOpen = true;

        angular.copy(item, $scope.currentObject);
        $scope.currentObject.type = type;
    }

    $scope.saveAppliance = function (appliance) {
        $scope.closeModal();

        ApplianceService.updateAppliance(appliance).then(
            function (response) {
                $('#modalClassificationApplianceDetail').modal('hide');

                $scope.$emit("saveApplianceModal");

                notify({
                    messageTemplate: "<i class='fa fa-check-circle-o fa-2 icon-notity' aria-hidden='true'></i><span>Aparelho atualizado com sucesso.</span>",
                    templateUrl: "/app/components/angular-notify.html",
                    position: "right",
                    duration: (3 * 1000)
                });


                hideLoading();
            },
            function errorCallback(res) {
                if (res.data.Error) {
                    //$scope.loadingById('#loadingClassificationApplianceDetail', false);
                    var data = res.data.Error;
                    var first = Object.keys(data.Errors)[0];
                    $scope.currentObject.message = data.Errors[first][0].message; //show first validation message*/
                    hideLoading();
                }
            }
        );
    };

    $scope.saveClassification = function (classification) {
        $scope.closeModal();
        // $scope.$parent.loadingById('#loadingClassificationApplianceDetail', true);

        ApplianceService.createAppliance(classification).then(
            function (response) {
                // $scope.$parent.loadingById('#loadingClassificationApplianceDetail', false);
                $('#modalClassificationApplianceDetail').modal('hide');

                $scope.$emit("saveApplianceModal");

                notify({
                    messageTemplate: "<i class='fa fa-check-circle-o fa-2 icon-notity' aria-hidden='true'></i><span>Aparelho identificado com sucesso.</span>",
                    templateUrl: "/app/components/angular-notify.html",
                    position: "right",
                    duration: (3 * 1000)
                });

                hideLoading();
            },
            function errorCallback(res) {
                if (res.data.Error) {
                    // $scope.$parent.loadingById('#loadingClassificationApplianceDetail', false);
                    var data = res.data.Error;
                    var first = Object.keys(data.Errors)[0];
                    $scope.currentObject.message = data.Errors[first][0].message; //show first validation message*/

                    hideLoading();
                }
            });
    };

    $scope.getApplianceType = function (val) {
        return CategoryService.applianceType({ language: 'pt_BR', findByName: val }).then(function (response) {
            return response.data.applianceType.map(function (item) {
                return item;
            });
        });
    };

    $scope.typeSelected = function ($item, $model, $label) {
        if ($item) {
            $scope.currentObject.applianceTypeId = { id: $item.id };
            $scope.currentObject.lang = $item.lang;
            $scope.currentObject.label = $item.label;
            $scope.currentObject.labelToShow = $item.name;
            if (!$scope.currentObject.name) {
                $scope.currentObject.name = $item.name;
            }
        }
    };

    $scope.changePage = function (pageIndex) {

        showLoading();

        var endDate = vm.dates.endDate;
        var startDate = vm.dates.startDate;

        var offset = null;
        if (pageIndex != null) {
            $scope.pager = Auxiliar.getPager($scope.currentObject.count, (pageIndex), $scope.pageSize);
            offset = $scope.pager.offset;
        }

        if ($scope.currentObject.type == "classification") {
            loadClassificationConsumptions($scope.pageSize, offset, startDate, endDate, endLoadingCallback);
        }
        else if ($scope.currentObject.type == "appliance") {
            loadApplianceConsumptions($scope.pageSize, offset, startDate, endDate, endLoadingCallback);
        }
    };


    function endLoadingCallback() {
        hideLoading();
    }

    $scope.closeModal = function () {
        $scope.modalOpen = false;

        if (vm.observerId) {
            CalendarService.unregisterObserver(vm.observerId, "secondary")
            vm.observerId = undefined;
        }

        $scope.currentObject = {}
    };

    function showLoading() {
        $("#modal-body").addClass("opacity");
        $("#modal-body-loading").css("display", "block");
    }

    function hideLoading() {
        $("#modal-body").removeClass("opacity");
        $("#modal-body-loading").css("display", "none");
    }
}

export const ngApplianceModalController = {
    name: 'ApplianceModalController',
    def: ApplianceModalController
}

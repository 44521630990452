<div class="panel panel-ga" [ngClass]="ref ?? ''">
  <div class="panel-heading">
    <h3 class="panel-title" aria-label="title">
      <ng-content select="div[slot=title]"></ng-content>
    </h3>
    <div class="menu-slot" aria-label="menu">
      <ng-content select="[slot=menu]"></ng-content>
    </div>
  </div>
  <div class="panel-body" style="position: relative" aria-label="body">
    <ng-content select="[slot=body]"> </ng-content>
    <!-- <div>
      <div class="loading-component">
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
      </div>
    </div> -->
  </div>
  <div aria-label="footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
</div>

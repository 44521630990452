import * as angular from 'angular';

angular
    .module('dashboard-services')
    .factory('ConsumptionService', ConsumptionService);

ConsumptionService.$inject = ['HttpUtil'];

function ConsumptionService(HttpUtil) {

    var ConsumptionService = {
        consumptionClassification: consumptionClassification,
        consumptionClassificationTotal: consumptionClassificationTotal,
        consumptionAppliance: consumptionAppliance,
        consumptionApplianceTotal: consumptionApplianceTotal
    };

    return ConsumptionService;

    function consumptionClassification(params) {
        if (params.classificationId) {
            return HttpUtil.get({ url: '/api/meters/' + params.meterId + '/consumptions/classifications/' + params.classificationId, params: params })
                .then(res => res.data.consumptions);
        }
        return HttpUtil.get({ url: '/api/meters/' + params.meterId + '/consumptions/classifications/', params: params })
            .then(res => res.data.consumptions);
    }

    function consumptionClassificationTotal(params) {
        return HttpUtil.get({ url: '/api/meters/' + params.meterId + '/consumptions/classifications/', params: params });
    }

    function consumptionAppliance(params) {
        if (params.name) {
            return HttpUtil.get({ url: '/api/meters/' + params.meterId + '/consumptions/appliances/' + params.name, params: params })
                .then(res => res.data.consumptions);
        }
        return HttpUtil.get({ url: '/api/meters/' + params.meterId + '/consumptions/appliances/', params: params })
            .then(res => res.data.consumptions);
    }

    function consumptionApplianceTotal(params) {
        return HttpUtil.get({ url: '/api/meters/' + params.meterId + '/consumptions/appliances/', params: params });
    }
}
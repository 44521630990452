import {
  HttpClientModule
} from '@angular/common/http';
import {
  NgModule
} from '@angular/core';
import {
  BrowserModule
} from '@angular/platform-browser';
import {
  setAngularJSGlobal,
  UpgradeModule
} from '@angular/upgrade/static';
import * as angular from 'angular';
import NgDashboardModule from '@repo/projects/ng-app/src/main';
import {
  auxiliarProvider,
  ngCacheFactoryProvider,
  ngHttpProvider,
  ngQProvider,
  ngRootScopeProvider,
  ngRouterParamsProvider,
  ngRouterProvider,
  ngTransitionProvider,
  notifyProvider,
  sharedSourceProvider,
  userServiceProvider
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import {
  AppRoutingModule
} from './app-routing.module';
import {
  AppComponent
} from './app.component';
import {
  AuthModule
} from './auth/auth.module';
import {
  DashboardModule
} from './dashboard/dashboard.module';
import {
  AppShellModule
} from './shared/app-shell/app-shell.module';
import {
  ChartConfigService
} from './shared/chart/chart-config.service';
import {
  ComponentContainerModule
} from './shared/component-container/component-container.module';
import {
  TenantModule
} from './shared/tenant/tenant.module';
import {
  TranslocoRootModule
} from './transloco/transloco-root.module';
import {
  CookieService
} from 'ngx-cookie-service';
import {
  httpInterceptorProviders
} from './shared/middleware';
import {
  SAILS_SOCKET,
  socketFactory
} from './setup/sails';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  CalendarModule
} from './shared/calendar/calendar.module';
import {
  MatDialogModule
} from '@angular/material/dialog';
import {
  OverlayModule
} from '@angular/cdk/overlay';

import {
  MatDatepickerModule
} from '@angular/material/datepicker';
import {
  MatNativeDateModule
} from '@angular/material/core';
import {
  MatChipsModule
} from '@angular/material/chips';
import {
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { GlobalLoadingModule } from './shared/global-loading/global-loader.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
setAngularJSGlobal(angular)

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UpgradeModule,
    AuthModule.forRoot(),
    HttpClientModule,
    TranslocoRootModule,
    ComponentContainerModule,
    DashboardModule,
    AppShellModule,
    TenantModule,
    BrowserAnimationsModule,
    MatDialogModule,
    OverlayModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatSelectModule,
    GlobalLoadingModule,
    Ng2SearchPipeModule
  ],
  providers: [
    ngRouterProvider,
    ngHttpProvider,
    ngQProvider,
    ngCacheFactoryProvider,
    auxiliarProvider,
    ngRootScopeProvider,
    ChartConfigService,
    CookieService,
    ngRouterParamsProvider,
    ngTransitionProvider,
    sharedSourceProvider,
    notifyProvider,
    { provide: SAILS_SOCKET, useFactory: socketFactory },
    httpInterceptorProviders
  ],
  //bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) {}

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, [NgDashboardModule],
      //{ strictDi: true }
    );
  }
}
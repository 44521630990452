import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AuthService } from 'webapp/app/auth/auth.service';
import { HttpUtil } from 'webapp/app/shared/services/http.util';
import { UserResponse } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class UserService {

  private permissions = [];
  private currentUser;

  constructor(
    private HttpUtil: HttpUtil,
    private AuthService: AuthService
  ) { }


  /**
  ** userType - "consumption", "solar_generation"
  **/
  getUsers(): Promise<UserResponse['user']> {
    return this.HttpUtil.get({ url: '/api/users' }).then(
      (res) => {
        this.permissions = res.data.user.permissions;
        return res.data.user;
      }
    );
  }

  getUser(id) {
    return this.HttpUtil.get({ url: `/api/users/${id}` }).then(res => {
      return res.data;
    })
  }

  getUsersByInvite(token) {
    return this.HttpUtil.get({ url: '/api/users?activeUserToken=' + token }).then(res => {
      return res.data.user;
    });
  }

  getOrganizationUsers(params) {
    return this.HttpUtil.get({ url: '/api/organizations/users', params: params }).then(res => {
      return res.data;
    });
  }

  saveUser(userId, body) {
    return this.HttpUtil.post({ url: `/api/users/${userId}`, data: body })
      .then(res => res.data);
  }

  activeInvite(params) {
    return this.HttpUtil.post({ url: '/api/activeInvite', data: params });
  }

  changePassword(params) {
    return this.HttpUtil.post({ url: '/api/users/' + params.id + '/password', data: params });
  }

  changeLanguage(params) {
    return this.HttpUtil.post({ url: '/api/users/' + params.id + '/language', data: params });
  }

  //logged
  invite(params) {
    return this.HttpUtil.post({ url: '/api/invite', data: params });
  }

  getUserFeature() {
    return this.HttpUtil.get({ url: '/api/feature' }).then(res => {
      return res.data.features.map(value => value.name);
    });
  }

  async getCurrentUser() {
    const res = await this.HttpUtil.get({ url: '/api/users', cache: true });
    return res.data.user;
  }

  async getUserPermissions() {
    const res = await this.HttpUtil.get({ url: '/api/users', cache: true });
    return (res.data as UserResponse).user.permissions;
  }

  getBranches(params) {
    return this.HttpUtil.get({ url: '/api/users/' + params.userId + '/branches', params: params })
      .then(res => { return res.data.branches });
  }

  getMeters(params) {
    return this.HttpUtil.get({ url: '/api/users/' + params.userId + '/meters', params: params })
      .then(res => { return res.data.meters });
  }

  insertBranchUser(params) {
    return this.HttpUtil.post({ url: `/api/users/${params.userId}/branches/${params.branchId}` });
  }

  deleteBranchUser(params) {
    return this.HttpUtil.del({ url: `/api/users/${params.userId}/branches/${params.branchId}` });
  }

  insertUserMeter(params) {
    return this.HttpUtil.post({ url: `/api/users/${params.userId}/data-sources/${params.meterId || params.groupId}` });
  }

  deleteUserMeter(params) {
    return this.HttpUtil.del({ url: `/api/users/${params.userId}/data-sources/${params.meterId || params.groupId}` });
  }

  updateUserMeter(params) {
    return this.HttpUtil.put({ url: `/api/users/${params.userId}/data-sources/${params.meterId || params.groupId}`, params: params })
      .then(res => res.data);
  }

  personificate(user) {
    return this.AuthService.personificate(user);
  }

  despersonificate() {
    return this.AuthService.despersonificate();
  }

  isPersonificated() {
    return this.AuthService.isPersonificated();
  }
}

export const ngUserService = {
  name: UserService.name,
  def: downgradeInjectable(UserService)
}
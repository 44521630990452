import * as angular from 'angular';

function hasPermission(UserService) {
  var directive = {
    multiElement: true /* HTML compiler will collect DOM nodes between nodes with the attributes directive-name-start and directive-name-end, and group them together as the directive elements*/,
    //priority: 601,/*Directives with greater numerical priority are compiled first*/
    priority: 0,
    restrict: "AE",
    transclude: true,
    terminal: true,
    scope: {
      organization: "<",
      permissions: "@",
    },
    link: function ($scope, $element, $attr, ctrl, $transclude) {
      const paramPermissions = $scope.permissions.trim();

      UserService.getCurrentUser().then(currentUser => {
        const isAdmin = currentUser.isAdmin;

        if (isAdmin || paramPermissions.length === 0) {
          return transclude();
        }

        const requiredPermissions = paramPermissions
          .split(" ")
          .map(perm => perm.trim());

        UserService.getUserPermissions().then(userPermissions => {
          const hasPermission = userPermissions
            .filter(byOrganization)
            .map(perm => perm.name)
            .some(perm => requiredPermissions.includes(perm));

          if (!hasPermission) {
            $($element).remove();
          } else {
            transclude();
          }
        });
      });

      function transclude() {
        return $transclude(function (clone) {
          $element.append(clone);
        })
      }

      function byOrganization(permission) {
        const paramOrganization = $scope.organization;
        if (!paramOrganization) {
          return true;
        } else if (Array.isArray(paramOrganization)) {
          return paramOrganization.find(org => org.id == permission.organization)
        } else {
          return permission.organization == paramOrganization;
        }
      }
    }
  };
  return directive;
}

export const ngHasPermission = {
  name: 'hasPermission',
  def: hasPermission
}


class TariffSelectorController {
  constructor(
    $scope,
    $translate,
    UserService,
    OrganizationService,
    ContractService
  ) {
    this.$scope = $scope;
    this.$translate = $translate;

    this.userService = UserService;
    this.organizationService = OrganizationService;
    this.contractService = ContractService;
  }

  $onInit() {
    const vm = this;
    vm.selectorId = vm.gaId ? vm.gaId : 'tariffSelector';
    vm.disabled = vm.disabled ? vm.disabled : false;

    vm.unshiftOldTariffs =
      vm.unshiftOldTariffs != undefined ? vm.unshiftOldTariffs : true;
    vm.notDeleteSelected =
      vm.notDeleteSelected != undefined ? vm.notDeleteSelected : false;
    vm.placeholder = vm.placeholder
      ? vm.placeholder
      : 'tariff-selector.placeholder';

    vm.userService
      .getCurrentUser()
      .then((user) => {
        vm.currentUser = user;
        vm.isAdmin = vm.currentUser.isAdmin;
      })
      .then(() => vm.loadTariffTypes())
      .then(() => {
        if (!vm.selectedTariff) {
          return vm.loadUserTariffs();
        }
      });

    vm.watchOrganizationParam();
    vm.watchSelectedTariffParam();
    vm.watchFilteredTariffs();
  }

  watchSelectedTariffParam() {
    const vm = this;
    vm.$scope.$watch('tsVm.selectedTariff', function (newTariff) {
      vm.selectedTariff = newTariff;
      newTariff && (vm.tariffName = newTariff.name);
    });
  }

  watchFilteredTariffs() {
    const vm = this;
    vm.$scope.$watch('tsVm.filteredTariffs', function (tariffsToRemove) {
      if (!vm.tariffs || !tariffsToRemove) return;
      vm.filterTariffsToRemove(tariffsToRemove);
    });
  }

  watchOrganizationParam() {
    const vm = this;

    vm.$scope.$watch('tsVm.organization', function (newOrg) {
      if (vm.unfilteredTariffs != null) {
        vm.filterItemsByOrganization(newOrg);
      }
    });
  }

  filterTariffsToRemove(tariffsToRemove) {
    const vm = this;
    if (!tariffsToRemove) return;
     
    vm.tariffs = vm.tariffs.filter(
      (t) => !tariffsToRemove.some((ttr) => ttr.id == t.id)
    );
  }

  clearSearchOnBackspace($event) {
    const vm = this;

    const KEY_BACKSPACE = 8;
    const KEY_DELETE = 46;
    const insertOrDelete =
      $event.keyCode === KEY_BACKSPACE || $event.keyCode === KEY_DELETE;

    if (insertOrDelete) {
      vm.tariffName = null;
      vm.selectedTariff = null;

      vm.onTariffChanged(null);
      if (vm.unshiftOldTariffs && vm.oldTariff) {
        vm.tariffs.unshift(vm.oldTariff);
      }
    }
  }

  getOrgId(org) {

    if (!org) return;
    
    if (typeof org == 'object') {
      return org.id ? org.id : undefined;
    } else if (typeof org == 'string' || typeof org == 'number') {
      return org;
    } else {
      return undefined;
    }
  }

  loadUserTariffs() {
    const vm = this;
    if (vm.tariffs) return Promise.resolve();

    const params = vm.subType ? { subType: vm.subType } : undefined;
    if (params != null && vm.subType == 'regulated') {
      params.privateAndPublic = true;
    }
    return vm.contractService
      .getTariffs(params)
      .then((res) => res.data.tariffs)
      .then((tariffs) => vm.filterTariffsByType(tariffs))
      .then((tariffs) => {
        vm.unfilteredTariffs = angular.copy(tariffs);
        vm.tariffs = tariffs;
      })
      .then(() => vm.filterTariffsToRemove(vm.excludeTariffs));
  }

  filterTariffsByType(tariffs) {
    const vm = this;
    if (!vm.types) return tariffs;

    const types = vm.types.split(' ');
    return tariffs.filter((tariff) => types.includes(tariff.tariffType.name));
  }

  loadTariffTypes() {
    const vm = this;
    if (vm.tariffTypes) return;

    vm.contractService
      .tariffTypes()
      .then((tariffTypes) => (vm.tariffTypes = tariffTypes));
  }

  onTariffSelected(selectedTariff) {
    const vm = this;

    vm.tariffName = selectedTariff.name;

    vm.tariffs = vm.notDeleteSelected ? vm.tariffs : vm.tariffs.filter((t) => t.id != selectedTariff.id);

    vm.selectedTariff = selectedTariff;
    vm.oldTariff = !vm.oldTariff ? selectedTariff : vm.selectedTariff;

    vm.onTariffChanged(vm.selectedTariff);
  }

  filterItemsByOrganization(organization) {
    const vm = this;
    const orgId = vm.getOrgId(organization);
    vm.tariffs = vm.unfilteredTariffs.filter(
      (tariff) => tariff.organization == orgId || tariff.organization == null
    );
  }
}

const TariffSelector = {
  templateUrl: '/app/directives/tariff-selector/tariff-selector.html',
  controller: TariffSelectorController,
  controllerAs: 'tsVm',
  bindings: {
    gaId: '@',
    //Future uncertain - Might be deprecated/removed
    selectedTariff: '=',
    //Callback function for new tariff selected
    onTariffChanged: '<',
    //Defines if removed tariffs should be re-added to list when new tariff selected
    //Boolean. Defaults to true
    unshiftOldTariffs: '<',
    notDeleteSelected: '<',
    // A list of tariffs that will be removed from the tariff list
    excludeTariffs: '=',
    // Tariff types, separated by spaces
    types: '<',
    // Filters Contracts Based on Tariff Type.
    // Accepted Values are: 'free', 'regulated', 'conventional'
    subType: '@',
    // I18n key for input text placeholder
    placeholder: '@',
    //Organization filter - Display Tariffs from provided organization
    organization: '<',
    //Disables the Input
    disabled: '=',
  },
};

angular
  .module('dashboard-directives')
  .component('tariffSelector', TariffSelector);

import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';

declare let $: JQueryStatic;

@Directive({ selector: '[app-kit-menu]' })
export class AppKitMenuDirective implements AfterViewInit {
	constructor(private elr: ElementRef) { }

	ngAfterViewInit() {
		//@ts-ignore
		$(this.elr.nativeElement).metisMenu();
	}
}

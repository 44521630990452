import * as angular from 'angular';

angular
  .module('dashboard-services')
  .service('CompositionService', CompositionService);

CompositionService.$inject = ['HttpUtil'];

/* @ngInject */
function CompositionService(HttpUtil) {

  return {
    saveFreeMarketComposition: saveFreeMarketComposition,
    saveConventionalComposition: saveConventionalComposition,
    saveCaptiveComposition: saveCaptiveComposition,
    saveCommunionComposition: saveCommunionComposition,
    saveSplitComposition: saveSplitComposition,
    saveFreeGeneratorComposition: saveFreeGeneratorComposition,
    saveComposition: saveComposition,
    createComposition: createComposition,
    updateComposition: updateComposition,
    getComposition: getComposition
  };

  function saveFreeMarketComposition(composition) {
    return this.saveComposition('freeMarketCommon', composition)
  }

  function saveConventionalComposition(composition) {
    return this.saveComposition('regulatedCommon', composition);
  }

  function saveCaptiveComposition(composition) {
    return this.saveComposition('regulatedDemand', composition);
  }

  function saveCommunionComposition(composition) {
    return this.saveComposition('freeMarketCommunion', composition);
  }

  function saveSplitComposition(composition) {
    return this.saveComposition('freeMarketSharing', composition);
  }
  
  function saveFreeGeneratorComposition(composition) {
    return this.saveComposition('freeMarketGenerator', composition);
  }

  function saveComposition(contractType, body) {
    return !body.composition.id
      ? this.createComposition(contractType, body)
      : this.updateComposition(body);
  }

  function createComposition(contractType, body) {
    return HttpUtil.post({
      url: `/api/compositions/${contractType}`,
      method: 'post',
      data: body,
    });
  }

  function updateComposition(body) {
    return HttpUtil.put({
      url: `/api/compositions/${body.composition.id}`,
      method: 'put',
      data: body,
    });
  }

  function getComposition(id) {
    return HttpUtil.get({
      url: `/api/contracts/${id}`
    }).then(res => res.data);
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { downgradeComponent } from '@angular/upgrade/static';
import * as moment from 'moment';
import { chartStyle, seriesConsumption } from 'ng-app/directives/consumption-chart/consumption-chart.util';
import * as tinycolor from 'tinycolor2';
import { AUXILIAR } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { ApiParams } from '../../../shared/models/params.model';
import { ConsumptionChartService, IConsumptionComponent } from './consumption-chart.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-consumption-chart',
  templateUrl: './consumption-chart.component.html',
  styleUrls: ['./consumption-chart.component.scss']
})
export class ConsumptionChartComponent implements OnInit {
  @Input() params: Params | undefined = {
    dataSourceId: 2805,
    endDate: '2021-06-30',
    meterId: 2805,
    resolution: 'days',
    startDate: '2021-05-31'
  }

  Highcharts: typeof Highcharts = Highcharts
  chartOptions = {}

  disableForecastFit: boolean = false
  remainingDays: number | undefined;
  latestInstallationDate: any;

  sourceObject
  viewSelected
  viewBalance
  showSelectView
  viewTotal

  isFreeMarketSharing
  chartSelected
  period

  chart

  constructor(
    private service: ConsumptionChartService,
    @Inject(AUXILIAR) private auxiliar: any
  ) { }

  ngOnInit(): void {
    const a = {sourceType: 'group'} as IConsumptionComponent
  }

  async start(cmp: IConsumptionComponent, params: Partial<ApiParams>) {
    const { sourceType } = cmp
    this.disableForecastFit = await this.service.isInstallationLateEnough(cmp);
    if (this.disableForecastFit) {
      this.remainingDays = 90 - moment().diff(this.latestInstallationDate, 'days');
    }
    const compositions = this.sourceObject.contracts;
    this.isFreeMarketSharing = Array.isArray(compositions) ? compositions.length > 1 && compositions.find(contract => (contract.type.type == 'freeMarketSharing')) != undefined : false;


    if (sourceType == "group") {
      if (this.sourceObject.bidirectional == true) {
        this.viewSelected = this.viewBalance;
        this.showSelectView = false;
      } else {
        this.viewSelected = this.viewTotal
        this.showSelectView = true;
      }
    }
    if (sourceType == "meter") {

    }

    this.chartSelected = this.chartSelected == null ? this.viewSelected : this.chartSelected;
    if (params.period == 'hour' || params.period == 'year') {
      this.chartSelected = this.viewSelected;
    }
    const [contracts, consumptions] = await this.service.start(cmp, params) || []
    this.drawChart(consumptions, cmp, params);
    this.fillStatistics(contracts, {})

    this.period = params.period;
  }

  drawChart(consumptions, cmp, params: Partial<ApiParams>) {
    this.drawConsumptionChart(consumptions || [], cmp, params);

    if (params.period != 'year') {
      //this.drawAccumulatedChart(consumptions || []);
    }
  }

  async drawConsumptionChart(consumptions, cmp, params: Partial<ApiParams>) {
    const vm = this;

    this.viewSelected = (this.sourceObject.bidirectional == true ? this.viewBalance : this.viewTotal)
    let consumptionSeriesAttribute = (this.viewSelected == this.viewTotal ? 'activeEnergy' : 'consumedEnergy');

    const data = this.auxiliar.buildDateSeries(
      params.startDate,
      params.endDate,
      params.resolution,
      consumptions,
      consumptionSeriesAttribute,
      cmp.timezone,
      this.sourceObject.inspectionDay
    );

    //TODO REFACTOR
    let items = data.newItems;
    let labels = data.labels;
    let values = data.values;

    let max = data.max;
    let resolution = params.resolution;
    let timezone = cmp.timezone;
    let intermediatePeakValues: any[] = [];
    let peakValues: any = [];

    const injectedData = this.auxiliar.buildDateSeries(
      params.startDate,
      params.endDate,
      params.resolution,
      consumptions,
      'injectedEnergy',
      cmp.timezone
    );

    let injectedValues = this.viewSelected == this.viewTotal ? [] : injectedData.values;

    let mainSerieType = 'column';
    let newValues: any[] = [];
    let forecastCount = 0;
    for (let i = 0; i < values.length; i++) {
      let value = values[i];
      let item = items[i];
      //filter negative values
      if (value < 0 && value > -100) {
        value = 0;
      }

      if (item.forecast) {
        newValues.push({
          color: tinycolor('#AEAEAE')
            .brighten(1.0 * forecastCount)
            .toString(),
          y: value.y,
        });
        forecastCount++;
      }

      if (item.offPeak) {
        newValues.push({
          color: 'var(--chartColor)',
          y: item.offPeak[consumptionSeriesAttribute] == 0 ? null : item.offPeak[consumptionSeriesAttribute],
        });
      } else {
        newValues.push({
          color: 'var(--chartColor)',
          y: null,
        });
      }

      if (item.intermediatePeak) {
        intermediatePeakValues.push({
          color: 'var(--secondary-color)',
          y: item.intermediatePeak[consumptionSeriesAttribute] == 0 ? null : item.intermediatePeak[consumptionSeriesAttribute],
        });
      } else {
        intermediatePeakValues.push({
          color: 'var(--secondary-color)',
          y: null,
        });
      }

      if (item.peak) {
        peakValues.push({
          color: 'var(--chartColordark)',
          y: item.peak[consumptionSeriesAttribute] == 0 ? null : item.peak[consumptionSeriesAttribute],
        });
      } else {
        peakValues.push({
          color: 'var(--chartColordark)',
          y: null,
        });
      }
    }
    values = newValues;

    let marginTop = vm.viewSelected == vm.viewBalance ? 45 : 45;
    let tickAmount = vm.viewSelected == vm.viewBalance ? undefined : 3;

    let consumptionSeriesData = []

    if (params.period == 'year') {
      // consumptionSeriesData = await this.drawContractedLines(
      //   consumptions || [],
      //   consumptionSeriesData,
      //   labels
      // );
    } else {
      seriesConsumption({
        mainSerieType,
        values,
        intermediatePeakValues,
        peakValues,
        injectedValues,
        injectedData
      })
    }

    this.chart =
      chartStyle({
        marginTop,
        resolution,
        timezone,
        vm,
        items,
        labels,
        tickAmount,
        injectedValues,
        consumptionSeriesData
      })
  }

  fillStatistics(obj1, obj2) {

  }

}

export const ng2ConsumptionChart = {
  name: 'appConsumptionChart',
  def: downgradeComponent({ component: ConsumptionChartComponent })
}

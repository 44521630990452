import * as angular from 'angular';

angular
    .module('dashboard-services')
    .factory('ApplianceService', ApplianceService);

ApplianceService.$inject = ['HttpUtil', 'ConsumptionService'];

function ApplianceService(HttpUtil, ConsumptionService) {
    var exportService = {
        getAppliances: getAppliances,
        getAppliancesConsumptions: getAppliancesConsumptions,
        createAppliance: createAppliance,
        updateAppliance: updateAppliance
    }

    return exportService;

    function getAppliances(params) {
        return HttpUtil.get({ url: `/api/meters/${params.meterId}/appliances` })
            .then(res => { return res.data.appliances })
    }

    function getAppliancesConsumptions(params) {
        if (params.applianceId) {
            return HttpUtil.get({ url: `/api/meters/${params.meterId}/appliances/${params.applianceId}/consumptions`, params: params })
                .then(res => { return res.data.consumptions })
        }
        else {
            return HttpUtil.get({ url: `/api/meters/${params.meterId}/appliances/consumptions/${params.name}`, params: params })
                .then(res => { return res.data.consumptions })
        }
    }

    function createAppliance(params) {
        var reqParams = {
            name: params.name,
            applianceTypeId: params.applianceTypeId,
            classificationId: params.classificationId
        };

        return HttpUtil.post({ url: `/api/meters/${params.meterId}/appliances`, data: reqParams })
            .then(res => { return res.data })
    }

    function updateAppliance(appliance) {
        var updateAppliance = {
            id: appliance.id,
            applianceTypeId: appliance.applianceTypeId.id,
            name: appliance.name
        };

        return HttpUtil.put({ url: `/api/meters/${appliance.meterId}/appliances/${appliance.id}`, data: updateAppliance })
            .then(res => { return res.data })
    }
}

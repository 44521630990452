import { Injectable } from '@angular/core';
import { ApiParams, SourceType } from '../../../shared/models/params.model';
import * as moment from 'moment';
import { GroupService } from 'webapp/app/shared/services/group.service';
import { MeterService } from 'webapp/app/shared/services/meter.service';
import { ContractService } from 'webapp/app/shared/services/contract.service';

export interface IConsumptionComponent {
  sourceType: SourceType,
  sourceObject: any;
  latestInstallationDate: any;
  timezone: any;
}

@Injectable({
  providedIn: 'root'
})
export class ConsumptionChartService {

  constructor(
    private groupService: GroupService,
    private meterService: MeterService,
    private contractService: ContractService
  ) { }

  async start(cmp: IConsumptionComponent, params: Partial<ApiParams>): Promise<any | undefined> {
    const { sourceType, sourceObject } = cmp
    if (sourceType == "group") {
      const groupContracts = this.contractService.getGroupContracts(
        sourceObject.id,
        moment(params.startDate).format('YYYY-MM-DD'),
        moment(params.endDate).format('YYYY-MM-DD')
      )
      const groupMeasuments = this.groupService.groupMeasurements(params)
      return Promise.all([
        groupContracts,
        groupMeasuments
      ])
    }
    if (sourceType == "meter") {

    }
  }

  toShowForecast(params: Partial<ApiParams>) {
    return moment().isBetween(
      moment(params.startDate),
      //@ts-ignore
      moment(params.endDate).set({ "hour": 23, "minute": 59, "second": 59 }), '[]') ||
      moment(params.startDate).isAfter(moment());
  }

  async isInstallationLateEnough({ sourceType, sourceObject, latestInstallationDate }: IConsumptionComponent) {
    if (sourceType == 'meter') {
      //vm.latestInstallationDate = sourceObject.installationStartDate;
      return {
        latestInstallationDate: sourceObject.installationStartDate,
        disable: moment().diff(moment(sourceObject.installationStartDate), 'days') < 90
      };
    }
    if (sourceType == 'group') {
      const group = await this.groupService.getGroupDataSources({ groupId: sourceObject.id });
      const assert = group.reduce(async (acc, mt) => {
        const meter = await this.meterService.getMeter({ meterId: mt.id });
        const thisDate = moment(meter.installationStartDate);
        if ((!latestInstallationDate) || (thisDate < moment(latestInstallationDate))) {
          acc.latestInstallationDate = thisDate;
        }
        acc.disable = acc.disable && (moment().diff(latestInstallationDate, 'days') < 90)
        return acc;
      }, {
        latestInstallationDate,
        disable: true
      })
      return assert
    }
  }
}

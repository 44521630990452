import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavStateService } from './nav-state.service';

@Component({
  selector: 'app-shell',
  templateUrl: './app-shell.component.html',
  providers: [NavStateService]
})
export class AppShellComponent implements OnInit, OnDestroy {

  navOpen = false
  onDestroy$ = new Subject<void>()

  @HostBinding('class.nav-toggled') navClass = false

  constructor(private navState: NavStateService) { }

  ngOnInit(): void {
    this.navState
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({ next: (state) => this.navClass = state })
  }

  toogle() {
    this.navOpen = !this.navOpen
  }

  ngOnDestroy() {
    this.onDestroy$.next()
  }
}

export const ng2AppShell = {
  name: 'appShell',
  def: downgradeComponent({ component: AppShellComponent })
}

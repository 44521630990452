import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as moment from 'moment';
import { HttpUtil } from './http.util';
import { SubGroupResponse } from './subgroup.model';
import { TariffType } from './tariff-type.model';

@Injectable({ providedIn: 'root' })
export class ContractService {
  meterContracts = [];
  groupContracts = [];

  constructor(
    private HttpUtil: HttpUtil
  ) { }


  getContracts(params) {
    return this.HttpUtil.get({
      url: '/api/contracts/',
      method: 'get',
      params: params,
    }).then((res) => res.data.contracts);
  }

  getTariffs(params) {
    return this.HttpUtil.get({
      url: '/api/tariffs/',
      method: 'get',
      params: params
    });
  }

  getTariff(contractId) {
    return this.HttpUtil.get({
      url: '/api/tariffs/' + contractId,
      method: 'get'
    });
  }

  saveContract(contract) {
    if (contract.id) {
      return this.HttpUtil.put({
        url: '/api/contracts/' + contract.id,
        method: 'put',
        data: contract
      });
    } else {
      return this.HttpUtil.post({
        url: '/api/contracts/',
        method: 'post',
        data: contract
      });
    }
  }

  saveTariff(contract) {
    if (contract.id) {
      return this.HttpUtil.put({
        url: '/api/tariffs/' + contract.id,
        method: 'put',
        data: contract
      });
    } else {
      return this.HttpUtil.post({
        url: '/api/tariffs/',
        method: 'post',
        data: contract
      });
    }
  }
  deleteTariff(tariff) {
    if (tariff.id) {
      return this.HttpUtil.del({
        url: '/api/tariffs/' + tariff.id,
        method: 'delete'
      });
    }
  }

  deleteContract(contract) {
    if (contract.id) {
      return this.HttpUtil.del({
        url: '/api/contracts/' + contract.id,
        method: 'delete'
      });
    }
  }

  tariffTypes(params): Promise<TariffType[]> {
    return this.HttpUtil.get({
      url: "/api/tarifftypes",
      method: "get",
      params: params,
      cache: true
    }).then(res => {
      return res.data;
    });
  }

  getTariffType(id) {
    return this.HttpUtil.get({
      url: '/api/tarifftypes/' + id,
      method: 'get'
    });

  }

  getContractTypes(params) {
    return this.HttpUtil.get({
      url: '/api/contracttypes',
      params: params
    });
  }

  getContractTypeByType(type) {
    return this.getContractTypes({ type: type }).then((res) => res.data);
  }

  getContractTypeBySubType(subType) {
    return this.getContractTypes({ subType: subType }).then(
      (res) => res.data
    );
  }

  getContractType(id) {
    return this.HttpUtil.get({
      url: `/api/contracttypes/${id}`
    });
  }

  getTariffInstances(contract) {
    return this.HttpUtil.get({
      url: '/api/tariffs/' + contract.id + "/instances",
      method: 'get'
    });
  }

  getTariffInstance(contract, contractIntanceId) {
    return this.HttpUtil.get({
      url: '/api/tariffs/' + contract.id + "/instances/" + contractIntanceId,
      method: 'get'
    });
  }

  saveTariffInstance(contract) {
    if (contract.instance.id) {
      return this.HttpUtil.put({
        url: '/api/tariffs/' + contract.id + "/instances/" + contract.instance.id,
        method: 'put',
        data: contract.instance
      });
    } else {
      delete contract.instance.id;

      return this.HttpUtil.post({
        url: '/api/tariffs/' + contract.id + "/instances/",
        method: 'post',
        data: contract.instance
      });
    }
  }

  deleteTariffInstance(contractInstance) {

    return this.HttpUtil.del({
      url: '/api/tariffs/' + contractInstance.tariff + "/instances/" + contractInstance.id,
      method: 'delete'
    });

  }

  getMeterContracts(meterId, startDate, endDate) {

    if (meterId && startDate && endDate) {
      let params = {
        startDate: startDate,
        endDate: endDate
      };

      let contracts = this.meterContracts[meterId + "-" +
        moment(startDate).format("YYYY-MM-DD") + "-" +
        moment(endDate).format("YYYY-MM-DD")];

      if (contracts) {
        return new Promise(function (resolve, reject) {
          resolve(contracts);
        });
      } else {
        return this.HttpUtil.get({
          url: '/api/meters/' + meterId + '/contracts/',
          method: 'get',
          params: params
        }).then(res => {

          let contracts = res.data.contracts;

          this.meterContracts[meterId + "-" +
            moment(startDate).format("YYYY-MM-DD") + "-" +
            moment(endDate).format("YYYY-MM-DD")] = contracts;

          return contracts
        });
      }
    }
  }
  //@ts-ignore
  getGroupContracts(groupId, startDate, endDate): Promise<any> | undefined {

    if (groupId && startDate && endDate) {
      let params = {
        startDate: startDate,
        endDate: endDate
      };

      let contracts = this.groupContracts[groupId + "-" +
        moment(startDate).format("YYYY-MM-DD") + "-" +
        moment(endDate).format("YYYY-MM-DD")];

      if (contracts) {
        return new Promise(function (resolve, reject) {
          resolve(contracts);
        });
      } else {
        return this.HttpUtil.get({
          url: '/api/groups/' + groupId + '/contracts/',
          method: 'get',
          params: params
        }).then(res => {

          let contracts = res.data.contracts;

          this.groupContracts[groupId + "-" +
            moment(startDate).format("YYYY-MM-DD") + "-" +
            moment(endDate).format("YYYY-MM-DD")] = contracts;

          return contracts
        });
      }
    }
  }

  getContractDataSources(contract) {
    return this.HttpUtil.get({
      url: '/api/contracts/' + contract.id + "/contract-data-sources",
      method: 'get'
    }).then(res => {
      return res.data.contractDataSources;
    });
  }

  getDataSources(contractId) {
    return this.HttpUtil.get({
      url: `/api/contracts/${contractId}/data-sources`,
      method: 'get'
    }).then(res => {
      return res.data;
    });
  }

  getContractTariffs(contract) {
    return this.HttpUtil.get({
      url: '/api/contracts/' + contract.id + "/tariffs",
      method: 'get'
    }).then(res => {
      return res.data.contractTariffs;
    });
  }

  getTariffSubGroups(): Promise<SubGroupResponse['subGroups']> {
    return this.HttpUtil.get({
      url: '/api/tariffs/subGroups',
      method: 'get'
    }).then(res => {
      return res.data.subGroups;
    });
  }
}

export const ng2ContractService = {
  name: ContractService.name,
  def: downgradeInjectable(ContractService)
}
// my-loader.component.ts
import { Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { LoaderService } from './loader.service';


@Component({
  selector: 'app-my-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class GlobalLoadingComponent implements OnInit {

  loading: boolean = true;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
    
  }

}

export const ng2GlobalLoadingComponent = {
  name: 'globalLoading',
  def: downgradeComponent({
    component: GlobalLoadingComponent,
    propagateDigest: false,
  }),
};

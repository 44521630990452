import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SourceSelectorModule } from 'webapp/app/dashboard/source-selector/source-selector.module';
import { ToolSourceSelectorComponent } from './tool-source-selector/tool-source-selector.component';
import { TopBarSearchComponent } from './top-bar-search/top-bar-search.component';

@NgModule({
  declarations: [
    TopBarSearchComponent,
    ToolSourceSelectorComponent
  ],
  exports: [
    TopBarSearchComponent,
    ToolSourceSelectorComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    SourceSelectorModule
  ]
})
export class HeaderSearchModule { }

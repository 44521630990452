import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { RequestCache } from './request-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private cache: RequestCache) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.method === "GET") {
            const cachedResponse = this.cache.get(req);
            return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
        }
        else {
            if (req.method === "POST" || req.method === "PATCH" || req.method === "PUT" || req.method === "DELETE") {
                this.cache.clear();
            }
            return this.sendRequest(req, next, this.cache);
        }
    }

    sendRequest(
        req: HttpRequest<any>,
        next: HttpHandler,
        cache: RequestCache): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    cache.put(req, event);
                }
            }),
            retry(1)
        );
    }
}

import { Injectable } from '@angular/core';
import { HttpUtil } from 'webapp/app/shared/services/http.util';
import { Distribuitor } from './distributors.model';

@Injectable({ providedIn: 'root' })
export class DistributorService {
  constructor(private HttpUtil: HttpUtil) { }

  distributors(): Promise<Distribuitor[]> {
    return this.HttpUtil.get({ url: '/api/distributors', method: 'get' })
    .then((res) => res.data);
  }

  getDistributor(id) {
    return this.HttpUtil.get({
      url: '/api/distributors/',
      method: 'get',
      params: {
        distributorId: id,
      },
    });
  }
}

import * as angular from 'angular';

angular
  .module('dashboard-services')
  .factory('BranchService', BranchService);

BranchService.$inject = ['HttpUtil'];

function BranchService(HttpUtil) {

  var BranchService = {
    saveBranch: saveBranch,
    branches: branches,
    getBranch: getBranch,
    deleteBranch: deleteBranch,
    currentBranch: currentBranch,
    getUsers: getUsers,
    getDataSources: getDataSources
  };

  var currentBranch = {};

  return BranchService;

  function branches(params) {
    return HttpUtil.get({ url: '/api/branches', params: params });
  }

  function getBranch(branch) {
    return HttpUtil.get({ url: '/api/branches/' + branch.id })
      .then((resp) => {
        return resp.data.branches;
      });
  }

  function deleteBranch(branch) {
    if (branch.id) {
      return HttpUtil.del({ url: '/api/branches/' + branch.id, method: 'delete' });
    }
  }

  function saveBranch(branch) {
    if (branch.id) {
      return HttpUtil.put({ url: '/api/branches/' + branch.id, method: 'put', data: branch });
    } else {
      return HttpUtil.post({ url: '/api/branches/', method: 'post', data: branch });
    }
  }

  function getUsers(params) {
    return HttpUtil.get({ url: '/api/branches/' + params.branchId + '/users', params: params }).then(res => { return res.data.users });
  }

  function getDataSources(params) {
    return HttpUtil.get({ url: '/api/branches/' + params.id + '/data-sources' })
      .then(res => { return res.data.dataSources });
  }
}

import * as angular from 'angular';

angular
  .module('dashboard-services')
  .factory('OrganizationService', OrganizationService);

OrganizationService.$inject = ['HttpUtil'];

/* @ngInject */
function OrganizationService(HttpUtil) {
  return {
    getOrganizations: getOrganizations,
    getOrganization: getOrganization,
    saveOrganization: saveOrganization,
    getBillingplans: getBillingplans,
    getBranches: getBranches,
    getMeters: getMeters,
    getUsers: getUsers,
    getUsersByOrganization: getUsersByOrganization,
    getUserOrganizations: getUserOrganizations,
    getUserRoleOrganizations: getUserRoleOrganizations,
    getSavingsPotential: getSavingsPotential
  }

  function getOrganizations(params = {}) {
    !params.hasOwnProperty('omitStyles') && (params.omitStyles = true);

    return HttpUtil.get({
      url: '/api/organizations/',
      method: 'get',
      params: params,
      cache: true
    }).then(res => {
      return res.data.organizations
    });
  }

  function getOrganization(id) {

    return HttpUtil.get({
      url: `/api/organizations/${id}`,
      method: 'get',
      cache: true
    }).then(res => {
      return res.data.organization
    });
  }

  function getBillingplans() {
    return HttpUtil.get({
      url: '/api/billingplans/',
      method: 'get',
    }).then(res => {
      return res.data.billingplans
    });
  }

  /**
  ** Save Organization
  **/
  function saveOrganization(params) {
    if (params.id) {
      return HttpUtil.put({ url: '/api/organizations/' + params.id, method: 'put', data: params });
    } else {
      return HttpUtil.post({ url: '/api/organizations', method: 'post', data: params });
    }
  }

  function getBranches(params) {
    return HttpUtil.get({ url: '/api/organizations/' + params.organizationId + '/branches', params: params })
      .then(res => { return res.data.branches });
  }

  function getMeters(params) {
    return HttpUtil.get({ url: '/api/organizations/' + params.organizationId + '/meters', params: params })
      .then(res => { return res.data.meters });
  }

  function getUsers(params) {
    return HttpUtil.get({ url: '/api/organizations/' + params.organizationId + '/users', params: params })
      .then(res => { return res.data });
  }

  async function getUsersByOrganization(params) {
    try {
      const res = await HttpUtil.get({ url: '/api/organizations/users', params: params })
      return res.data;
    } catch (error) {
      return error;
    }
  }

  async function getUserOrganizations(user) {
    if (user.isAdmin) {
      const organizations = await getOrganizations({ fields: 'id,name' })
      return organizations
    }
    else {
      const roles = await getUserRoleOrganizations(user.id);
      if (roles.length > 1) {
        const organizations = await getOrganizations({ fields: 'id,name' })
        return organizations
      }
      else {
        return user.organization;
      }
    }
  }

  async function getUserRoleOrganizations(userId, params) {
    try {
      const res = await HttpUtil.get({ url: `/api/users/${userId}/role-organizations`, params: params });
      return res.data;
    } catch (error) {
      console.error(error)
      return res.json(500, { Error: { Errors: _h.tl('unexpected.server.error') } });
    }
  }

  function getSavingsPotential(params) {
    return HttpUtil.get({
      url: `/api/organizations/${params.organizationId}/savings-potential`,
      params: params,
      method: 'get'
    }).then(res => {
      return res.data
    });
  }
}

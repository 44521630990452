import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { downgradeComponent } from "@angular/upgrade/static";
import { TranslocoService } from "@ngneat/transloco";
import * as angular from "angular";
import { LoaderService } from "webapp/app/shared/global-loading/loader.service";
import { UserService } from "webapp/app/shared/services/user.service";
import { NG_ROUTER, NG_STATE_PARAMS } from "webapp/hybrid-helpers/ajs-upgraded-providers";
import { setupDowngradeName } from "webapp/hybrid-helpers/setupDowngradeName";
import { AuthService } from "../auth.service";

@Component({
	selector: 'app-invite',
	templateUrl: './invite.component.html',
	styleUrls: [ './invite.component.scss', '../login/login.component.scss'],
	host: {
	  'class': 'login'
	},
	encapsulation: ViewEncapsulation.None
})
export class InviteComponent implements OnInit {
	done: boolean = false;

	readonly currentDate = new Date();

	readonly splashPages = [
		"/assets/images/green_analitycs.png",
		"/assets/images/green_contratos.png"
	];

	readonly bgColors = [
		'#cccc99',
		'#7affbf'
	];

	form = this.fb.group({
		firstName: [null, [Validators.required]],
		lastName: [null, [Validators.required]],
		email: [null, [Validators.required, Validators.email]],
		password: [null, [Validators.required, Validators.minLength(6)]],
		passwordConfirmation: [null, [Validators.required, Validators.minLength(6), this.passwordConfirming]],
		token: [null, [Validators.required]],
		acceptTerms: [null, [Validators.required, Validators.requiredTrue]]
	  })

	splashPageURL: any;
	message: {error: boolean, msg: string} | undefined = undefined

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_STATE_PARAMS) private $stateParams,
		private i18n: TranslocoService,
		private auth: AuthService,
		private loader: LoaderService,
		private UserService: UserService,
		private sanitize: DomSanitizer,
		private fb: FormBuilder
	  ) { }

	ngOnInit(): void {
		let rand = Math.floor(Math.random() * this.splashPages.length);
		this.splashPageURL = this.sanitize.bypassSecurityTrustResourceUrl(
		this.splashPages[rand]
		);
		$('.embed-responsive.embed-responsive-image').eq(0).css('background-color', this.bgColors[rand]);
		this.loadUserInfo();
	}

	fadeIn(id) {
		$(id).fadeIn("slow");
	}

	signup({ valid, value }: AbstractControl) {
		const that = this
		if (valid) {
			this.loader.isLoading.next(true)
			this.UserService.activeInvite(value).then(
				(res) => {
					 // Success - redirect to main page
					this.message = {error: false, msg: res.message};
					this.goTo('login')
					this.loader.isLoading.next(false)

				}).catch((error) => {
					let errMsg = error?.data.Errors[Object.keys(error?.data.Errors)[0]][0].message || error?.error || error;
					this.message = {error: true, msg: errMsg};
					this.loader.isLoading.next(false)
				})
		} else { //Message mandatory fields
			this.message = {error: true, msg: this.getValidationErrors()};
		}
	};


	loadUserInfo() {
		const that = this
		this.UserService.getUsersByInvite(this.$stateParams.token).then((user) => {
				if (user != undefined) {
					that.form.setValue({firstName: user.firstName,
										lastName: user.lastName,
										email: user.email,
										password: null,
										passwordConfirmation: null,
										token: that.$stateParams.token,
										acceptTerms: null});
				} else {
					that.goTo('login');
				}
			},
			(err) => {
				if (err.status == 401 || err.status == 403) {
					that.goTo('login'); return true;
				}
				that.message = err;
			}
		);
	}

	goTo(state: string){
		this.$state.transitionTo(state)
	}

	getValidationErrors(): string {
		if(this.form.get('email')?.errors) return this.i18n.translate('signup.validation.email');
		if(this.form.get('password')?.errors) return this.i18n.translate('signup.validation.password');
		if(this.form.get('passwordConfirmation')?.errors) return this.i18n.translate('signup.validation.passwordConfirmation');
		if(this.form.get('acceptTerms')?.errors) return this.i18n.translate('signup.validation.terms');
		return this.i18n.translate('signup.validation.mandatory')
	}

	passwordConfirming(c: AbstractControl): { invalid: boolean } | null {
		if (c.get('password')?.value !== c.get('passwordConfirmation')?.value) {
			return {invalid: true};
		}
		return null;
	}
}

export const ng2InviteComponent = {
	name: setupDowngradeName(InviteComponent.name),
	def: downgradeComponent({component: InviteComponent})
}

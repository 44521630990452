import { AfterViewChecked, AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NG_ROOT_SCOPE, NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { SessionUser } from '../../models/session-user.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  host: { 'class': 'sticky menu-include' },
  encapsulation: ViewEncapsulation.None

})
export class NavbarComponent implements OnInit, AfterViewChecked {
  @ViewChild('navWrapper', { static: false }) navWrapper!: ElementRef;
  @ViewChild('menu', { static: false }) menu!: ElementRef;
  @ViewChild('footerMenu', { static: false }) footerMenu!: ElementRef;

  branch
  screen
  scrollMenu: boolean = false;
  footerImages: any[] = []
  footerImage = {
    small: '',
    smallImageClass: '',
    textImage: '',
    textImageClass: ''
  }
  currentUser: SessionUser = {} as any

  constructor(
    @Inject(NG_ROUTER) private $state,
    @Inject(NG_ROOT_SCOPE) private $rootScope,
    private UserService: UserService,
  ) {
    this.screen = this.$state.current.name
    this.$rootScope.$on('$stateChangeStart',
      (event, toState, toParams, fromState, fromParams, options) => {
        this.screen = toState.name;
        //prevent black screen when click on "medições" menu twice
        if ((fromState.name == "main.dashboard.meter" || fromState.name == "main.dashboard.group") && toState.name == "main.dashboard") {
          event.preventDefault();
        }
      });
  }

  ngOnInit(): void {
    this.UserService.getCurrentUser().then(user => {
      this.currentUser = user;
    })
  }

  ngAfterViewChecked(){
    this.scrollMenu = (this.navWrapper.nativeElement.offsetHeight < (this.footerMenu.nativeElement.offsetHeight + this.menu.nativeElement.offsetHeight)) ? true : false
  }

  //TODO: Migrate to be using routerLink
  goTocontactUs() {
    this.$state.go('main.contact-us', { id: this.currentUser.organization })
  }
}

export const ng2NavbarComponent = {
  name: 'appNavbar',
  def: downgradeComponent({ component: NavbarComponent })
}

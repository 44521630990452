import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import * as moment from 'moment';
import { HttpUtil } from './http.util';
import { Auxiliar } from './auxiliar';

@Injectable({ providedIn: 'root' })
export class MeasurementService {

  localTotalCache = [];

  constructor(
    private HttpUtil: HttpUtil,
    private Auxiliar: Auxiliar
  ) { }

  measurements(params) {
    if (params.groupId) {
      return this.getGroupMeterMeasurements(params);
    } else {
      if (params.type && params.type == "invoice") {
        return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/measurements/total', params: params })
          .then(res => res.data.measurement);
      } else {

        return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/measurements', params: params })
          .then(res => res.data.measurement);
      }
    }

  }

  getGroupMeterMeasurements(params) {
    if (params.type && params.type == "invoice") {
      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/datasources/${params.meterId}/measurements/total`, params: params, cache: true })
        .then(res => res.data.measurement);
    } else {

      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/datasources/${params.meterId}/measurements`, params: params, cache: true })
        .then(res => res.data.measurement);
    }
  }

  getForecasts(params) {
    if (params.type && params.type == "invoice") {
      return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/forecasts/total', params: params, cache: true })
        .then(res => res.data.measurement)
        .catch(err => {
          return err
        });
    } else {
      return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/forecasts', params: params, cache: true })
        .then(res => res.data.measurement)
        .catch(err => {
          return err
        });
    }
  }

  getForecastsTotal(params) {
    return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/forecasts/total', params: params, cache: true })
      .then(res => res.data.measurement)
      .catch(err => {
        return err
      });
  }

  getFits(params) {
    if (params.type && params.type == "invoice") {
      return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/fits/total', params: params, cache: true })
        .then(res => res.data.measurement)
        .catch(err => {
          return err
        });
    } else {

      return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/fits', params: params, cache: true })
        .then(res => res.data.measurement)
        .catch(err => {
          return err
        });
    }
  }

  powers(params) {
    if (params.meterId) {
      return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/powers', params: params, cache: true })
        .then(resp => { return { powers: resp.data.powers, contracts: resp.data.contracts } });
    }
  }

  powersGroup(params) {
    if (params.dataSourceId) {
      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.dataSourceId}/powers`, params: params, cache: true })
        .then(resp => { return { powers: resp.data.powers, contracts: resp.data.contracts } });
    }
  }

  total(params) {
    if (params.groupId) {
      return this.getGroupMeterTotal(params);
    } else {
      var key = "";
      if (params.period && params.startDate && params.endDate) {
        key = params.meterId + "_" + params.period + "_" + params.type + "_" + params.startDate + "_" + params.endDate;
      }
      else if (params.period) {
        key = params.meterId + "_" + params.period + "_" + params.type;
      }
      else {
        key = params.meterId + "_" + params.startDate + "_" + params.endDate;
      }

      //this.localTotalCache[key] != undefined && moment().isBefore(this.localTotalCache[key].date)
      if (this.localTotalCache[key] != undefined && moment().isBefore(this.localTotalCache[key].date)) {
        return new Promise((resolve, reject) => {
          resolve(angular.copy(this.localTotalCache[key].res));
        });
      } else {
        return this.HttpUtil.get({ method: 'get', url: '/api/meters/' + params.meterId + '/measurements/total', params: params })
          .then(res => {
            this.localTotalCache[key] = {
              res: angular.copy(res),
              date: moment().add(5, 'minutes')//cache time
            }
            return res;
          });
      }
    }
  }
  getGroupMeterTotal(params) {
    var key = "";
    if (params.period && params.startDate && params.endDate) {
      key = params.meterId + "_" + params.period + "_" + params.type + "_" + params.startDate + "_" + params.endDate;
    }
    else if (params.period) {
      key = params.meterId + "_" + params.period + "_" + params.type;
    }
    else {
      key = params.meterId + "_" + params.startDate + "_" + params.endDate;
    }


    //this.localTotalCache[key] != undefined && moment().isBefore(this.localTotalCache[key].date)
    if (false) {
      return new Promise((resolve, reject) => {
        resolve(angular.copy(this.localTotalCache[key].res));
      });
    } else {
      return this.HttpUtil.get({ method: 'get', url: `/api/groups/${params.groupId}/datasources/${params.meterId}/measurements/total`, params: params })
        .then(res => {

          this.localTotalCache[key] = {
            res: angular.copy(res),
            date: moment().add(5, 'minutes')//cache time
          }
          return res;
        });
    }
  }

  getDemand(params) {

    if (params.meterId && params.period) {

      return this.HttpUtil.get({ method: 'get', url: `/api/meters/${params.meterId}/demand`, params: params, ttl: 60 * 1000 })
        .then(resp => {
          return resp.data;
        });
    }

  }
  getReactiveSurplus(params) {
    if (params.meterId && (params.period || (params.startDate && params.endDate))) {

      return this.HttpUtil.get({ method: 'get', url: `/api/meters/${params.meterId}/reactive`, params: params, ttl: 60 * 1000 })
        .then(resp => {
          return resp.data;
        });
    }
  }

  getTemplate() {
    return this.HttpUtil.get({
      url: `/api/uploads/measurements/downloadTemplate`,
      responseType: 'blob',
    })
      .then((res) => {
        this.Auxiliar.download(res, null);
        return true;
      })
      .catch((error) => console.error(error));
  }


}

export const ng2MeasurementService = {
  name: MeasurementService.name,
  def: downgradeInjectable(MeasurementService)
}

import * as angular from 'angular';
import { ngApplianceModalController } from './controllers/AplianceModalController';
import { ngMainController } from './pages/main.controller';
import { ng2GlobalLoadingComponent } from 'webapp/app/shared/global-loading/loader.component';

const DashboardControllers = angular.module('dashboard-controllers', []);

DashboardControllers.controller(ngMainController.name, ngMainController.def);
DashboardControllers.controller(ngApplianceModalController.name, ngApplianceModalController.def);

DashboardControllers.directive(ng2GlobalLoadingComponent.name, ng2GlobalLoadingComponent.def);

export default DashboardControllers.name

import * as angular from 'angular';

angular
    .module('dashboard-services')
    .factory('AlertService', AlertService);

AlertService.$inject = ['HttpUtil'];

/* @ngInject */
function AlertService(HttpUtil) {
    var AlertService = {
        getAlerts: getAlerts,
        getAlert: getAlert,
        getAlertTemplates: getAlertTemplates,
        getAlertMeters: getAlertMeters,
        silenceAlert: silenceAlert,
        saveAlert: saveAlert,
        deleteAlert: deleteAlert,
        getHoursMinutesResend: getHoursMinutesResend
    };

    return AlertService;

    /**AlertService public Functions**/

    /**
    ** Get Alerts
    ***/
    function getAlerts() {
        return HttpUtil.get({ url: '/api/alerts' }).then(res => {
            return res.data
        });
    }

    /**
    ** Get Alert by id
    */
    function getAlert(alertId) {
        return HttpUtil.get({ url: `/api/alerts/${alertId}` }).then(res => { return res.data });
    }

    /**
    ** Get Alert Types
    **/
    function getAlertTemplates() {

        var alertTemplate = [];

        alertTemplate.push({
            "keyTemplate": "energy.invoiceConsumedEnergy",
            alert: {
                label: "alerts.label.alert-type-templates.energy.invoiceConsumedEnergy",
                alertType: { id: 1 },
                conditions: { "conditions": { "all": [{ "fact": "invoiceConsumedEnergy", "operator": "greaterThan" }] } },
                conditionsHours: { "conditions": { "all": [{ "fact": "timestamp", "operator": "hours", "value": ["any"] }] } },
                conditionsDays: { "conditions": { "all": [{ "fact": "timestamp", "operator": "days", "value": ["all"] }] } },
                sendInterval: 60,
                event: { "type": "message", "params": { "actions": [{ "type": "email", "value": "", "placeholder": "Ex.: place@greenant.com.br" }] } },
                message: "GreenAnt: A energia total da fatura atual está acima de {{valor}} com o valor de {{ocorrencia}} em {{data}} {{hora}}"
            }
        });

        alertTemplate.push({
            "keyTemplate": "power-factor.inductive",
            alert: {
                label: 'alerts.label.alert-type-templates.power-factor.inductive',
                alertType: { id: 1 },
                conditions: { "conditions": { "all": [{ "fact": "inductivePowerFactor", "operator": "lessThan", "value": 0.92 }] } },
                conditionsHours: { "conditions": { "all": [{ "fact": "timestamp", "operator": "hours", "value": ["06:00", "00:00"] }] } },
                conditionsDays: { "conditions": { "all": [{ "fact": "timestamp", "operator": "days", "value": ["all"] }] } },
                sendInterval: 60,
                event: { "type": "message", "params": { "actions": [{ "type": "email", "value": "", "placeholder": "Ex.: place@greenant.com.br" }] } },
                message: "GreenAnt: O {{atributo}} em {{medidor}} está abaixo de {{valor}}."
            }
        });

        alertTemplate.push({
            "keyTemplate": "power-factor.capacitive",
            alert: {
                label: 'alerts.label.alert-type-templates.power-factor.capacitive',
                alertType: { id: 1 },
                conditions: { "conditions": { "all": [{ "fact": "capacitivePowerFactor", "operator": "lessThan", "value": 0.92 }] } },
                conditionsHours: { "conditions": { "all": [{ "fact": "timestamp", "operator": "hours", "value": ["00:00", "06:00"] }] } },
                conditionsDays: { "conditions": { "all": [{ "fact": "timestamp", "operator": "days", "value": ["all"] }] } },
                sendInterval: 60,
                event: { "type": "message", "params": { "actions": [{ "type": "email", "value": "", "placeholder": "Ex.: place@greenant.com.br" }] } },
                message: "GreenAnt: O {{atributo}} em {{medidor}} está abaixo de {{valor}}."
            }
        });

        alertTemplate.push({
            "keyTemplate": "demand.active",
            alert: {
                label: "alerts.label.alert-type-templates.demand.active",
                alertType: { id: 1 },
                conditions: { "conditions": { "all": [{ "fact": "activePower", "operator": "greaterThan" }] } },
                conditionsHours: { "conditions": { "all": [{ "fact": "timestamp", "operator": "hours", "value": ["any"] }] } },
                conditionsDays: { "conditions": { "all": [{ "fact": "timestamp", "operator": "days", "value": ["all"] }] } },
                sendInterval: 60,
                event: { "type": "message", "params": { "actions": [{ "type": "email", "value": "", "placeholder": "Ex.: place@greenant.com.br" }] } },
                message: "GreenAnt: A {{atributo}} em {{medidor}} está acima de {{valor}}."
            }
        });

        alertTemplate.push({
            "keyTemplate": "demand.reactive",
            alert: {
                label: "alerts.label.alert-type-templates.demand.reactive",
                alertType: { id: 1 },
                conditions: { "conditions": { "all": [{ "fact": "reactivePower", "operator": "greaterThan" }] } },
                conditionsHours: { "conditions": { "all": [{ "fact": "timestamp", "operator": "hours", "value": ["any"] }] } },
                conditionsDays: { "conditions": { "all": [{ "fact": "timestamp", "operator": "days", "value": ["all"] }] } },
                sendInterval: 60,
                event: { "type": "message", "params": { "actions": [{ "type": "email", "value": "", "placeholder": "Ex.: place@greenant.com.br" }] } },
                message: "GreenAnt: A {{atributo}} em {{medidor}} está acima de {{valor}}."
            }
        });

        //multiple condition
        alertTemplate.push({
            "keyTemplate": "multiple",
            alert: {
                alertType: { id: 1 },
                conditions: { conditions: { all: [{}] } },
                conditionsHours: { conditions: { all: [{ fact: "timestamp", operator: "hours", value: [] }] } },
                conditionsDays: { conditions: { all: [{ fact: "timestamp", operator: "days", value: [] }] } },
                event: { "type": "message", "params": { "actions": [{ "type": "email", "value": "", "placeholder": "Ex.: place@greenant.com.br" }] } },
                meters: [],
                message: ""
            }
        });

        return new Promise(function (resolve, reject) {
            resolve(alertTemplate);
        });
    }

    function getHoursMinutesResend() {

        let hoursMinutesResend = [];

        hoursMinutesResend.hours = [];
        hoursMinutesResend.minutes = [];

        for (let i = 0; i < 25; i++) {
            hoursMinutesResend.hours.push(i);
        }

        for (let i = 0; i < 46; i += 15) {
            hoursMinutesResend.minutes.push(i);
        }

        return new Promise(function (resolve, reject) {
            resolve(hoursMinutesResend);
        });
    }

    /**
    ** Get Alert Meters
    **/
    function getAlertMeters(alertId) {
        return HttpUtil
            .get({ url: `/api/alerts/${alertId}/meters` })
            .then(res => res.data.alertMeters);
    }

    /**
    ** silence Alert
    **/
    function silenceAlert(alert) {
        return saveAlert(alert);

        /*return $http({url:`/api/alerts/silence`, method: 'put', data: alert}).then(
          res => { return res.data.notice }
        );*/
    }

    function deleteAlert(alert) {
        return HttpUtil.del({ url: `/api/alerts/${alert.id}` }).then(
            res => { return res.data.notice }
        );
    }

    /**
    ** Create or update Alert
    **/
    function saveAlert(alert) {
        if (alert.id) {
            return HttpUtil.put({ url: `/api/alerts/${alert.id}`, data: alert }).then(
                res => { return res.data.alert }
            );
        } else {
            return HttpUtil.post({ url: `/api/alerts/`, data: alert }).then(
                res => { return res.data.alert }
            );
        }
    }
}

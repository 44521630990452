import "stickyfilljs/dist/stickyfill.min";
/* import "pace-progress/pace.min"; */
import "angular-i18n/angular-locale_pt-br";
import "tether/dist/js/tether.min";
import "tether-shepherd/dist/js/shepherd.min";
//import './vendors/angular-file-upload';
import "moment-timezone/builds/moment-timezone-with-data-2012-2022.min";
import "moment/locale/pt-br";

import './vendors/highcharts';

import "file-saver/FileSaver.min";

import "numeral/locales/pt-br.js";

import "./vendors/textangular-setup";

import AppModule from './app/app.module';

import "./util"
import "./services";
//import "./other-controllers"
//import "./loader-util";
//import "./controller-and-components";
import "./directives";

export default AppModule

//angular.bootstrap(document.documentElement, [DashboardModule])

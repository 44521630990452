import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as moment from 'moment';
import { HttpUtil } from 'webapp/app/shared/services/http.util';
import { AUXILIAR } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Injectable({ providedIn: 'any' })
export class ExportService {
  constructor(
    private HttpUtil: HttpUtil,
    @Inject(AUXILIAR) private auxiliar
  ) { }

  exportMeterMeasurements(exportOptions) {
    var params = {
      id: exportOptions.meter.id,
      startDate: moment(exportOptions.startDate).format('YYYY-MM-DD'),
      endDate: moment(exportOptions.endDate).format('YYYY-MM-DD'),
      resolution: exportOptions.resolution,
      allParams: exportOptions.allParams == 'true',
    };
    return this.exportData(exportOptions, params, '/api/meters/');
  }

  exportGroupMeasurements(exportOptions) {
    var params = {
      id: exportOptions.group.id,
      startDate: moment(exportOptions.startDate).format('YYYY-MM-DD'),
      endDate: moment(exportOptions.endDate).format('YYYY-MM-DD'),
      resolution: exportOptions.resolution,
      allParams: exportOptions.allParams == 'true',
    };
    return this.exportData(
      exportOptions,
      params,
      `/api/branches/${exportOptions.group.branchId}/groups/`
    );
  }

  private exportData(exportOptions, params, urlPrefix) {
    var formats = {
      xlsx:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      csv: 'text/csv',
    };

    var url = '';
    if (exportOptions.resolution == 'invoice') {
      url = urlPrefix + params.id + '/measurements/total';
      params.type = 'invoice';
    } else {
      url = urlPrefix + params.id + '/measurements';
    }

    return this.HttpUtil.get({
      url: url,
      headers: { accept: formats[exportOptions.format] },
      responseType: 'arraybuffer',
      params: params,
    }).then(res => this.processDownload(res));
  }

  exportDataSource(exportOptions) {
    let url = `/api/datasources/${exportOptions.dataSource.id}/measurements`;
    var params: any = {
      startDate: moment(exportOptions.startDate).format('YYYY-MM-DD'),
      endDate: moment(exportOptions.endDate).format('YYYY-MM-DD'),
      resolution: exportOptions.resolution,
      allParams: exportOptions.allParams == 'true',
    };

    var formats = {
      xlsx:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      csv: 'text/csv',
    };

    if (exportOptions.resolution == 'invoice') {
      url += '/total';
      params.type = 'invoice';
    }

    return this.HttpUtil.get({
      url: url,
      headers: { accept: formats[exportOptions.format] },
      responseType: 'arraybuffer',
      params: params,
    }).then(res => this.processDownload(res));
  }

  private processDownload(res) {
    this.auxiliar.download(
      res,
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    return true;
  }

  private handleError(error) {
    const dataView = new DataView(error.data);
    const decoder = new TextDecoder('utf8');
    throw {
      data: JSON.parse(decoder.decode(dataView)),
    };
  }

  exportTariffs(exportParams, acceptFormat) {
    const downForm = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    exportParams.export = true;
    return this.HttpUtil.get({
      url: '/api/tariffInstances',
      params: exportParams,
      headers: {
        accept: acceptFormat,
      },
      responseType: 'arraybuffer',
    })
      .then((res) => this.auxiliar.download(res, downForm))
      .catch((error) => {
        const dataView = new DataView(error.data);
        const decoder = new TextDecoder('utf8');
        throw {
          data: JSON.parse(decoder.decode(dataView)),
        };
      });
  }

  exportEnergyBills(params) {
    return this.HttpUtil.post({
      url: '/api/energyBills/extractToExcel',
      data: params,
      headers: {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer'
    })
    .then(res => this.processDownload(res))
    .catch(err => this.handleError(err));
  }
}

export const ng2ExportService = {
  name: ExportService.name,
  def: downgradeInjectable(ExportService)
}

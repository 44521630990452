<header class="header sticky">
  <div class="branding float-left">
    <h1 class="logo text-center" [title]="'sw-version' | transloco">
      <a href="index.html" *ngFor="let headerImage of headerImages">
        <img class="logo-icon-ga-small" [src]="headerImage.small" alt="" />
        <img class="logo-icon-ga-full" [src]="headerImage.textImage" alt="" />
      </a>
    </h1>
  </div>
  <div class="topbar">
    <button class="main-nav-toggle" type="button" (click)="hideMenu()">
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <div style="float: left;">
      <ng-container *ngComponentOutlet="tool"></ng-container>
    </div>

    <div class="personificate-warning" *ngIf="currentUser.isPersonificated">
      <div class="row">
        <div class="col-sm-2">
          <i class="fa fa-eye"></i>
        </div>
        <div class="col-sm-10">
          {{ "main.view" | transloco }} {{ currentUser.firstName }}
          <a role="menuitem" (click)="despersonificate()" style="display: block">
            {{ "main.leave" | transloco }}
          </a>
        </div>
      </div>
    </div>

    <div class="navbar-tools">
      <div class="user-container dropdown">
        <div
          class="dropdown-toggle"
          data-toggle="dropdown"
          id="dropdownMenu-user"
        >
          <img [src]="gravatarUrl" alt="" style="margin-right: 8px" />
          <span class="welcome-user"
            >{{ "main.welcome" | transloco }}, {{ currentUser.firstName }}</span
          >
          <i class="fa fa-caret-down"></i>
        </div>
        <ul
          class="dropdown-menu"
          role="menu"
          aria-labelledby="dropdownMenu-user"
          style="width: 300px"
        >
          <li
            (click)="goToUserPage()"
            style="cursor: pointer; padding-bottom: 8px"
          >
            <div class="row" style="padding-left: 15px; padding-top: 8px">
              <div class="col-sm-2">
                <img [src]="gravatarUrl" alt="" />
              </div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <strong
                      >{{ currentUser.firstName }}
                      {{ currentUser.lastName }}</strong
                    >
                  </div>
                  <div class="col-sm-12">{{ currentUser.email }}</div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <span class="arrow"></span>
            <a role="menuitem" (click)="goToUserPage()">
              <span class="fa fa-fw fa-user"></span>
              {{ "page.my-account" | transloco }}
            </a>
          </li>

          <li>
            <span class="arrow"></span>
            <a role="menuitem" (click)="goToMetersPage()">
              <img
                [src]="'assets/images/custom_icons/icone_meter.svg'"
                alt=""
                style="width: 18px; margin-left: -1px"
              />
              {{ "page.my-meters" | transloco }}
            </a>
          </li>

          <li>
            <a role="menuitem" (click)="logout()">
              <span class="fa fa-fw fa-power-off"></span>
              {{ "page.logout-bt" | transloco }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

</header>

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from '../../models/user.model';
import { UserService } from '../../services/user.service';

export interface Organization {
    id: number;
    name?: string;
}

/**
 * Implementa a funcionalidade de feature toogle de acordo
 * as permissões do usuário e sua respectiva organização
 */
@Directive({ selector: '[wchHasFeature]', exportAs: '[wchHasFeature]' })
export class HasFeatureDirective implements OnInit {
    /**
     * Permissões necessárias para exibir a parte da tela a que foi atrelada a diretive
     * @type {string|undefined} Uma string contendo duas substrings separadas por um caracter de espaço
     */
    @Input('wchHasFeature') permissions: string | undefined
    /**
     * Filtra a permissão do usuário de acordo a organização
     */
    organization: Organization[] | number | undefined
    @Input()
    set wchHasFeatureOrganization(value) {
        this.organization = value
    }

    constructor(
        private vcr: ViewContainerRef,
        private tmp: TemplateRef<any>,
        private userService: UserService
    ) { }

    async ngOnInit() {
        const checked = await this.check(this.permissions, this.organization)
        if (checked) {
            this.vcr.createEmbeddedView(this.tmp)
        }
    }

    private async check(permissions: string | undefined, organization: Organization[] | number | undefined) {
        const paramPermissions = permissions && permissions.trim();
        const currentUser = await this.userService.getCurrentUser()
        const isAdmin = currentUser.isAdmin;

        if (isAdmin || !paramPermissions?.length) {
            return true
        } else {
            const requiredPermissions = paramPermissions
                .split(" ")
                .map(perm => perm.trim());
            const userPermissions = await this.userService.getUserPermissions()
            const hasPermission = userPermissions
                .filter(perm => this.byOrganization(perm, organization))
                .map(perm => perm.name)
                .some(perm => requiredPermissions.includes(perm));

            return hasPermission
        }
    }

    private byOrganization(permission: Permission, organization: Organization[] | number | undefined) {
        if (!organization) {
            return true;
        }
        if (Array.isArray(organization)) {
            return organization.some(org => org.id == permission.organization)
        } else {
            return permission.organization === organization;
        }
    }
}
import * as angular from 'angular';

angular
  .module('dashboard-services')
  .service('TaskService', TaskService);

TaskService.$inject = ['HttpUtil'];

/* @ngInject */
function TaskService(HttpUtil) {
  return {
    getTasks: getTasks,
    createTask: createTask,
    deleteTask: deleteTask,
    updateTask: updateTask,
    createSubtask: createSubtask,
    deleteSubtask: deleteSubtask,
    updateSubtask: updateSubtask
  }

  function getTasks(params) {
    return HttpUtil.get({
      url: '/api/tasks',
      params: params
    }).then(res => {
      return res.data.tasks
    });
  }

  function createTask(params) {
    return HttpUtil.post({
      url: '/api/tasks',
      data: params
    }).then(res => {
      return res.data.task
    });
  }

  function deleteTask(params) {
    return HttpUtil.del({
      url: '/api/tasks/' + params.id,
      params: { organizationId: params.organizationId || params.organization }
    }).then(res => {
      return res.data.task
    });
  }

  function updateTask(params) {
    return HttpUtil.put({
      url: '/api/tasks/' + params.id,
      data: params
    }).then(res => {
      return res.data.task
    });
  }

  function createSubtask(params) {
    return HttpUtil.post({
      url: '/api/task/' + params.task + '/subtasks',
      params: { organizationId: params.organizationId || params.organization },
      data: params
    }).then(res => {
      return res.data.task
    });
  }

  function deleteSubtask(params) {
    return HttpUtil.del({
      url: '/api/subtasks/' + params.id,
      params: { organizationId: params.organizationId || params.organization }
    }).then(res => {
      return res.data.task
    });
  }

  function updateSubtask(params) {
    return HttpUtil.put({
      url: '/api/subtasks/' + params.id,
      params: { organizationId: params.organizationId || params.organization },
      data: params
    }).then(res => {
      return res.data.task
    });
  }
}

class CompanySelectorController {
  constructor($scope, $timeout, UserService, CompanyService, $translate) {
    this.$scope = $scope;
    this.userService = UserService;
    this.companyService = CompanyService;

    this.$translate = $translate;
    this.$timeout = $timeout;
  }

  async $onInit() {
    const vm = this;
    vm.allCompanies = [];
    vm.companies = [];
    vm.watchSelectedCompany();

    vm.disabled = vm.disabled ? vm.disabled : false;
    vm.userService.getCurrentUser().then((user) => (vm.isAdmin = user.isAdmin));

    vm.compSelectId = vm.selectedCompany
      ? vm.selectedCompany.id
      : 'companySelector';

    vm.placeholder = vm.placeholder
      ? vm.placeholder
      : 'company-selector.placeholder';

    vm.companyService.getCompanies().then((companies) => {
      vm.allCompanies = companies;
      vm.filterCompanies();
      if (vm.compId) {
        vm.selectedCompany = vm.allCompanies.find((c) => c.id == vm.compId);
        vm.companyName =
          vm.selectedCompany.id + ' - ' + vm.selectedCompany.tradingName;
      }
    });
    vm.$scope.$on('company-selector-reset', vm.resetComponent.bind(vm));
    vm.$scope.$on('company-selector-edit', vm.companySelectEventEmitted.bind(vm));
  }

  async $onChanges(changesObj) {
    const vm = this;
    if (
      changesObj.organization &&
      changesObj.organization.previousValue !=
        changesObj.organization.currentValue
    ) {
      vm.selectedCompany = null;
      if (vm.allCompanies && vm.allCompanies.length > 0) {
        vm.filterCompanies();
        if (vm.compId) {
          vm.selectedCompany = vm.allCompanies.find((c) => c.id == vm.compId);
          vm.companyName = vm.selectedCompany.id + ' - ' + vm.selectedCompany.tradingName;
        }
      } else {
        vm.companyService.getCompanies().then((companies) => {
          vm.allCompanies = companies;
          vm.filterCompanies();
          if (vm.compId) {
            vm.selectedCompany = vm.allCompanies.find((c) => c.id == vm.compId);
            vm.companyName = vm.selectedCompany.id + ' - ' + vm.selectedCompany.tradingName;
          }
        });
      }
    }
    if (
      changesObj.compId && vm.allCompanies
    ) {
      vm.selectedCompany = vm.allCompanies.find((c) => c.id == changesObj.compId.currentValue);
      vm.companyName = vm.selectedCompany ? vm.selectedCompany.id + ' - ' + vm.selectedCompany.tradingName : "";
    }
  }

  onChange(ev) {
    this.onCompChanged(null);
  }

  onCompanySelected(company) {
    this.selectedCompany = company;
    this.onCompChanged(company);
  }

  filterCompanies() {
    const vm = this;
    if (vm.organization) {
      vm.companies = vm.allCompanies.filter(
        (comp) => comp.organization == vm.organization.id
      );
    } else {
      vm.companies = vm.allCompanies;
    }
  }

  watchSelectedCompany() {
    const vm = this;
    vm.$scope.$watch('coVm.selectedCompany', function (newCompany) {
      if (newCompany) {
        vm.selectedCompany = newCompany;
        vm.companyName = `${vm.selectedCompany.id} - ${vm.selectedCompany.tradingName}`;
      }
    });
  }
  
  resetComponent() {
    const vm = this;
    delete vm.companyName;
    delete vm.selectedCompany;
  }

  companySelectEventEmitted(event, company) {
    const vm = this;
    if(vm.companies) {
      let comp = (company && company.id) ? company : vm.companies.find((c) => c.id == company);
      vm.onCompanySelected(comp);
    }
  }
}

const CompanySelector = {
  templateUrl: '/app/directives/company-selector/company-selector.html',
  controller: CompanySelectorController,
  controllerAs: 'coVm',
  bindings: {
    organization: '<',
    compId: '<?',
    selectedCompany: '=?',
    onCompChanged: '<',
    // Disables the Input
    disabled: '<',
    // I18n key for input text placeholder
    placeholder: '@',
  },
};

export const ngCompanySelector = {
  name: 'companySelector',
  def: CompanySelector
}
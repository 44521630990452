import * as angular from 'angular';

class RoleService {
  constructor(HttpUtil) {
    this.HttpUtil = HttpUtil;
  }

  getRoles(params) {
    try {
      return this.HttpUtil.get({ url: "/api/roles", params: params}).then(res => {
        return res.data;
      });
    } catch (error) {
      console.log(error);
      return new Error(error);
    }
  }
  
  getOrganizationRoles(organizationId){
    try {
      return this.HttpUtil.get({ url: "/api/roleOrganizations", params: {organizationId: organizationId}}).then(res => {
        return res.data;
      });
    } catch (error) {
      console.log(error);
      return new Error(error);
    }
  }

  createRoleOrganization(body){
    try {
      return this.HttpUtil.post({ url: "/api/roleOrganizations", data: body}).then(res => {
        return res.data;
      });
    } catch (error) {
      console.log(error);
      return new Error(error);
    }
  }

  deleteRoleOrganization(roleOrgId){
    try {
      return this.HttpUtil.del({ url: `/api/roleOrganizations/${roleOrgId}`}).then(res => {
        return res.data;
      });
    } catch (error) {
      console.log(error);
      return new Error(error);
    }
  }
}

angular.module("dashboard-services").service("RoleService", RoleService);

<app-my-loader></app-my-loader>
<div class="container-fluid login-wrapper">
    <div class="row">
        <div class="col-sm-4">
            <div id="login" class="vertical-center-row">
                <div class="logo-header">
                    <h1 class="logo text-center">
                        <img class="logo-icon" src="/assets/images/custom_icons/logo_greenant_login.png" alt="icon" />
                    </h1>
                </div>
                
                <div>
                    <h2 class="text-center greenant-count">{{ 'signup.header' | transloco }} </h2><br>
                    <div
                        *ngIf="message"
                        class="alert alert-theme alert-dismissible"
                        [ngClass]="message.error ? 'alert-danger': 'alert-success'">
                        <div>
                            <i class="fa fa-2 icon-notity"
                            [ngClass]="message.error ? 'fa-exclamation-circle': 'fa-check-circle'"
                            aria-hidden="true"></i>
                            <span class="message-error-login">{{ message.msg }}</span>
                        </div>
                    </div>
                    <form novalidate [formGroup]="form" (ngSubmit)="signup(form)">
                        
                        <div class="form-group">
                            <div class="div-email">
                                <label>{{ 'signup.ph-firstName' | transloco }}</label>
                                <input type="text" class="first-field form-control" name="firstName" placeholder="{{ 'signup.ph-firstName' | transloco }}" formControlName="firstName">
                            </div>
                            <div class="div-email">
                                <label>{{ 'signup.ph-lastName' | transloco }}</label>
                                <input type="text" name="lastName" class="form-control" placeholder="{{ 'signup.ph-lastName' | transloco }}" formControlName="lastName">
                            </div>
                            <div class="div-email">
                                <label>{{ 'signup.ph-email' | transloco }}</label>
                                <input type="text" name="email" class="email form-control" placeholder="{{ 'signup.ph-email' | transloco }}" formControlName="email">
                            </div>
                            <div class="div-password">
                                <label>{{ 'signup.ph-password' | transloco }}</label>
                                <input type="password" class="form-control" name="password" placeholder="{{ 'signup.ph-password' | transloco }}" formControlName="password">
                            </div>
                            <div class="div-password">
                                <label>{{ 'signup.ph-passwordConfirmation' | transloco }}</label>
                                <input type="password" class="last-field form-control" name="passwordConfirmation" placeholder="{{ 'signup.ph-passwordConfirmation' | transloco }}" formControlName="passwordConfirmation">
                            </div>
                            <div class="login-help">
                                <input type="checkbox" name="acceptTerms" formControlName="acceptTerms"> {{ 'signup.terms.p1' | transloco}} <a target="_blank"
                                    href="http://www.greenant.com.br/termos-de-servico/">{{ 'signup.terms.p2' | transloco }}</a> {{ 'signup.terms.p3' | transloco
                                }} <a target="_blank" href="http://www.greenant.com.br/politica-de-privacidade/">{{ 'signup.terms.p4' | transloco }} </a>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    name="signup"
                                    class="btn btn-login form-control">
                                        {{ "signup.value-submit" | transloco }}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="relogin bottom-text text-center">{{ 'signup.login-question' | transloco }} <a href="/login">{{ 'signup.login-link' | transloco }}</a> </div>
                    </div>
                </div>
        </div>
        <div class="col-sm-8 embed-responsive embed-responsive-image">
            <img [src]="splashPageURL" alt="_parent" class="embed-responsive-item">
        </div>
    </div>
</div>

<script>
    $('.pace-progress').css("top", "0px");
</script> 

<div class="row" style="margin-top: 8px">
  <div class="col-sm-6">
    <span class="search-box">
      <i class="fa fa-search" ></i>
      <input
        type="text"
        [formControl]="query"
        class="form-control search-input"
        id="search-input"
        placeholder="{{ 'main.search' | transloco }}"
        style="width: 200px"
      />
    </span>
  </div>
</div>

import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { setupDowngradeName } from 'webapp/hybrid-helpers/setupDowngradeName';
import { AuthService } from '../auth.service';

declare var $: JQueryStatic

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['../login/login.component.scss'],
  host: {
    'class': 'login'
  },
  encapsulation: ViewEncapsulation.None
})
export class ForgotComponent implements OnInit {

  readonly currentDate = new Date();

  readonly splashPages = [
    "/assets/images/green_analitycs.png",
    "/assets/images/green_contratos.png"
  ];
	readonly bgColors = [
		'#cccc99',
		'#7affbf'
	];
  splashPageURL: any;

  form = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  })
  user = {};
  message: {error: boolean, msg: string} | undefined = undefined

  constructor(
    @Inject(NG_ROUTER) private $state,
    private i18n: TranslocoService,
    private auth: AuthService,
    private sanitize: DomSanitizer,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    let rand = Math.floor(Math.random() * this.splashPages.length);
    this.splashPageURL = this.sanitize.bypassSecurityTrustResourceUrl(
      this.splashPages[rand]
    );
    $('.embed-responsive.embed-responsive-image').eq(0).css('background-color', this.bgColors[rand]);
  }

  fadeIn(id) {
    $(id).fadeIn("slow");
  }

  onSubmit({ valid, value }: AbstractControl) {
    if (valid) {
      $("#loadingModal").show();
      const cb = (res) => {

        $("#loadingModal").hide();

        if (!res.error) { // Success - redirect to main page
          this.message = {error: false, msg: res.message};
          console.log(res)

          //user.email = '';
        } else {
          $("#loadingModal").hide();
          this.message = {error: true, msg: res.error};
          console.log(this.message)
        }
      }
      this.auth.forgot(value).then(cb, cb);

    } else { //Message mandatory fields
      this.message = {error: true, msg: this.i18n.translate('forgot.mandatory')};
      console.log(this.message)
    }
  };

  goTo(state: string){
    this.$state.transitionTo(state)
  }
}

export const ng2ForgotComponent = {
  name: setupDowngradeName(ForgotComponent.name),
  def: downgradeComponent({ component: ForgotComponent })
}

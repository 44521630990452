//@ts-nocheck

import * as moment from "moment";
import { hideLoading, showLoading } from "ng-app/util/loader";
import { ApiParams } from "webapp/app/shared/models/params.model";
import { CalendarService } from "webapp/app/shared/services/calendar.service";

export class AbstractChart {
  observerId = null;
  dates = null;

  constructor(protected componentId, protected calendarService: CalendarService, protected notify) { }

  $onInit() {
    this.observerId = this.calendarService.registerObserver((this.onNotify).bind(this), this.channel);
  }

  $onDestroy() {
    if (this.observerId) {
      this.calendarService.unregisterObserver(this.observerId, this.channel);

    }
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  buildRequestParams(dataParams) {
    const params: Partial<ApiParams> = dataParams || {};

    if (this.dates && this.sourceType && this.sourceObject) {
      if (this.dates.resolution == "invoice") {
        params.type = "invoice";
        params.period = moment(this.dates.endDate).format("YYYY");

      } else {
        params.startDate = moment(this.dates.startDate).format('YYYY-MM-DD');
        params.endDate = moment(this.dates.endDate).format('YYYY-MM-DD');
        params.resolution = this.dates.resolution;
      }

      if (this.sourceType == "group") {
        params.groupId = this.sourceObject.id;
        params.branchId = this.sourceObject.branchId;
      } else if (this.sourceType == "meter") {
        params.meterId = this.sourceObject.id;
      }
      params.dataSourceId = this.sourceObject.id;
    }

    return params;
  }

  /*Meter*/
  getMeterData(params, callback) {
    this.measurementService.measurements(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }

  getMeterTotalData(params, callback) {
    this.measurementService.total(params).then(function successCallback(res) {
      callback(res.data.measurement, res.data.measurementGeneration)
    });
  }

  getMeterForecastData(params, callback) {
    this.measurementService.getForecasts(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }
  getMeterForecastTotalData(params, callback) {
    this.measurementService.getForecastsTotal(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }

  getMeterFitsData(params, callback) {
    this.measurementService.getFits(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }
  /*Group*/
  getGroupData(params, callback) {
    this.groupService.groupMeasurements(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }

  getGroupTotalData(params, callback) {
    this.groupService.groupTotal(params).then(function successCallback(res) {
      callback(res.data.measurement, res.data.measurementGeneration)
    });
  }

  getGroupForecastData(params, callback) {
    this.groupService.getForecasts(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }
  getGroupForecastTotalData(params, callback) {
    this.groupService.getForecastsTotal(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }

  getGroupFitsData(params, callback) {
    this.groupService.getFits(params).then(function successCallback(consumptions) {
      callback(consumptions)
    });
  }

  onNotify(calendarData) {
    showLoading(this.componentId);
    this.cleanData();
    if (JSON.stringify(this.dates) !== JSON.stringify(calendarData) &&
      calendarData.inspectionDay != undefined && calendarData.startDate && calendarData.endDate) {
      this.dates = calendarData;
      this.getData(this.buildDataParams());
    }
  }

  cleanData() {

  }

  showLoading() {
    showLoading(this.componentId);
  }

  hideLoading() {
    hideLoading(this.componentId);
  }

  getData(dataParams) {
    throw new Error('You have to implement the method doSomething!');
  }

  /*
    Override this method to pass custom params for get data
  */
  buildDataParams() {
    return {};
  }

  isInsidePeak(item, startPeakTime, endPeakTime) {
    if (startPeakTime && endPeakTime) {

      let beginDate = moment(startPeakTime, "HH:mm");
      let endDate = moment(endPeakTime, "HH:mm");
      let compareDate = moment(moment(item.date).format("HH:mm"), "HH:mm");

      return compareDate.isBetween(beginDate, endDate, null, "[]");
    } else {
      return false;
    }
  }

  deepCalendar(item, targetPeriod, timezone) {

    if (this.dates.resolution != 'hour') {
      if ((targetPeriod && targetPeriod === 'hour') || this.dates.resolution == 'day') {

        this.dates.backTo = this.dates.period;
        this.dates.period = 'hour';
        this.dates.startDate = moment.tz(item.date, timezone).hour(0);
        this.dates.endDate = moment.tz(item.date, timezone).hour(23);
      } else if ((targetPeriod && targetPeriod === 'day') || this.dates.resolution == 'invoice') {

        this.dates.period = 'day';
        this.dates.startDate = moment.tz(item.date, timezone).hour(0);
      }

      this.calendarService.updateCalendarData(null, this.dates);
    }
  }

  colorWeekChart(resolution, labels) {
    //Coloring weeks
    if (resolution === 'day') {
      let weekStart = undefined;
      let weekEnd = undefined;
      let even = false;

      for (let i = 0; i < labels.length; i++) {
        if (moment(labels[i]).format("d") === '0') { //sunday
          weekStart = (i - 0.5);
        } else if (weekStart != undefined && moment(labels[i]).format("d") === '6') { //saturday
          weekEnd = (i + 0.5);
        }
        if (i == labels.length - 1 && weekStart != undefined) {
          weekEnd = (i + 0.5);
        }

        if (weekStart != undefined && weekEnd != undefined) {
          this.chart.xAxis[0].addPlotBand({
            color: even ? 'white' : '#ededed',
            from: weekStart,
            to: weekEnd
          });
          weekStart = undefined;
          weekEnd = undefined;
          even = !even;
        }
      }
    }
  }

  showMessage(message, type = 'success') {

    let notifyMessage = {
      messageTemplate: "<i class='fa fa-check-circle fa-2 icon-notity' aria-hidden='true'></i><span>" + message + "</span>",
      templateUrl: "/app/components/angular-notify.html",
      position: "right",
      duration: (4 * 1000)
    };

    if (type == 'error') {
      notifyMessage.messageTemplate = "<i class='fa fa-exclamation-circle fa-2 icon-notity' aria-hidden='true'></i><span>" + message + "</span>";
      notifyMessage.classes = "cg-notify-message-error";
    }
    this.notify(notifyMessage);
  }

  showErrorMessage(message) {
    this.showMessage(message, 'error');
  }

  showErrorMessages(res) {
    const vm = this;
    let data = res.data.Error;
    let first = Object.keys(data.Errors)[0];
    Object.keys(data.Errors).forEach(key => {
      vm.showErrorMessage(data.Errors[key][0].message);
    });
  }
}

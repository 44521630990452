import _ from 'lodash';

class DataSourceSelectorController {
  constructor(UserService, OrganizationService, DataSourceService, $scope, $translate) {
    this.$translate = $translate;
    this.$scope = $scope;

    this.userService = UserService;
    this.organizationService = OrganizationService;
    this.dataSourceService = DataSourceService;

    this.filterDataSourcesByType = this.filterDataSourcesByType.bind(this);
    this.createOptGroup = this.createOptGroup.bind(this);
  }

  async $onInit() {
    try {
      const vm = this;

      vm.multiSelectId = vm.id ? `#${vm.id}` : '#dataSourceMultiSelect';
      vm.disabled = vm.disabled == "true";
      vm.selectedDataSources =  vm.selectedDataSources ?  vm.selectedDataSources : [];
      vm.automaticCheckSelection = vm.automaticCheckSelection || false;

      vm.multiSelectDataSources = [];
      vm.selectedDataSourceIds = [];
      vm._excludedMeterTypes = [];

      vm.registerEvents();
      vm.initSelectComponent();

      vm.userRoleOrgs = await vm.getUserRoleOrganizations();
      await vm.loadDataSources();
    } catch (error) {
      console.error(error);
    }
  }

  $onChanges(changesObj) {
    const vm = this;
    const orgChanges = changesObj.organization;
    const excludedTypesChanges = changesObj.excludedMeterTypes;

    //Type changes must be evaluated before orgChanges
    if (excludedTypesChanges && excludedTypesChanges.currentValue) {
      vm._excludedMeterTypes = excludedTypesChanges.currentValue;

      if (vm.dataSources) {
        vm.dataSources = vm.filterDataSourcesByExcludedTypes(vm.dataSources);
        vm.rebuildWithSelectedDataSources();
      }
    }

    if (orgChanges) {
      vm.onOrganizationChanges(
        orgChanges.previousValue,
        orgChanges.currentValue
      );
    }
  }

  filterDataSourcesByExcludedTypes(dataSources) {
    const vm = this;
    return dataSources.filter((ds) => {
      return !vm._excludedMeterTypes.includes(ds.meterTypeId);
    })
  }

  async onOrganizationChanges(previousOrg, currentOrg) {
    const vm = this;

    const orgIdChanged = () => {
      const prev = vm.getOrgId(previousOrg);
      const curr = vm.getOrgId(currentOrg);
      return prev != curr
    };

    if (currentOrg && !previousOrg) {
      vm.onOrganizationAdd(undefined, [currentOrg]);
    } else if (previousOrg && currentOrg && orgIdChanged() && Object.keys(previousOrg).length != 0) {
      vm.onOrganizationRemove(undefined, previousOrg);
      vm.onOrganizationAdd(undefined, [currentOrg]);
    } else if (previousOrg && currentOrg && !orgIdChanged()) {
      vm.rebuildWithSelectedDataSources();
    }
  }

  rebuildWithSelectedDataSources() {
    const vm = this;
    vm.selectedDataSources = vm.filterDataSourcesById(vm.dataSources, vm.selectedDataSources);
    vm.selectedDataSourceIds = vm.selectedDataSources.map(ds => ds.id);
    const acceptedDataSources = vm.filterDataSourcesByExcludedTypes(vm.multiSelectDataSources)
    vm.rebuildMultiselect(acceptedDataSources);
  }

  registerEvents() {
    const vm = this;
    vm.$scope.$on('selectedDataSources', vm.onSelectedDataSourcesEventEmitted.bind(vm));
    vm.$scope.$on('organization-add', vm.onOrganizationAdd.bind(vm));
    vm.$scope.$on('organization-role-add', vm.onOrganizationRoleAdd.bind(vm));
    vm.$scope.$on('organization-remove', vm.onOrganizationRemove.bind(vm));
    vm.$scope.$on('datasource-selector-reset', vm.resetComponent.bind(vm));
    vm.$scope.$on('org-update-admin-role', vm.onOrgUpdatedToAdmin.bind(vm));
    vm.$scope.$on('org-update-basic-role', vm.onOrgUpdatedToBasic.bind(vm));
  }

  resetComponent() {
    const vm = this;
    vm.multiSelectDataSources = [];
    vm.selectedDataSourceIds = [];
    vm.disabled = true;

    $(vm.multiSelectId).multiselect('deselectAll');
    $(vm.multiSelectId).multiselect("dataprovider", {});
    $(vm.multiSelectId).multiselect('rebuild');
  }

  async loadDataSources() {
    const vm = this;

    const params = {};
    if (vm.allDataSources != undefined) {
      params.allDataSources = vm.allDataSources;
    }
    vm.dataSources = await vm.dataSourceService.getDataSources(params);

    if (vm.adminRoleOnly == 'true' && !vm.currentUser.isAdmin) {
      const adminOrgIds = vm.userRoleOrgs
      .filter(roleOrg => roleOrg.role.label == 'admin')
      .map(roleOrg => roleOrg.organization.id)

      vm.dataSources = vm.dataSources.filter(ds => adminOrgIds.find(orgId => orgId == ds.organizationId))
    }

    if(vm.type) {
      vm.dataSources = vm.dataSources.filter(dataSource => dataSource.type == vm.type);
    }

    vm.dataSources = vm.dataSources.filter((ds) => {
      return !vm._excludedMeterTypes.includes(ds.meterTypeId);
    });

    vm.mapOrgDataSources = vm.splitDataSourcesByOrganizations(vm.dataSources);
    vm.populateMultiSelect(vm.mapOrgDataSources);

    if(vm.selectedDataSourceIds){
      vm.selectedDataSources = vm.dataSources.filter(dt => vm.selectedDataSourceIds.includes(dt.id));
    }
    // DIRTY HACK ALERT!
    // This is a hack made to support edit mode
    // $onChanges is called before vm.dataSources is properly initialized
    // Solution was to manually trigger an Org Change after DataSources load
    // I'm not proud of it
    if (vm.organization) {
      vm.onOrganizationChanges(undefined, vm.organization);
    }
  }

  filterDataSourcesByType(dataSources) {
    const vm = this;
    const filteredDataSources = dataSources.filter(dataSource => dataSource.type == vm.type)
    return Promise.resolve(filteredDataSources);
  }

  initSelectComponent() {
    const vm = this;
    
    vm.initSelectComponentDefaults();
    
    $(vm.multiSelectId).multiselect({
      enableFiltering: vm.enableFiltering,
      enableCaseInsensitiveFiltering: vm.enableCaseInsensitiveFiltering,
      enableFullValueFiltering: true,
      enableHTML: true,
      enableClickableOptGroups: vm.enableClickableOptGroups,
      filterBehavior: vm.filterBehavior,
      includeSelectAllOption: vm.includeSelectAllOption,
      filterPlaceholder: vm.filterPlaceholder,
      nonSelectedText: vm.nonSelectedText,
      nSelectedText: vm.nSelectedText,
      maxHeight: vm.maxHeight,
      buttonWidth: vm.buttonWidth,
      allSelectedText: vm.allSelectedText,
      selectAllText: vm.selectAllText,
      onChange: vm.onMultiselectChange.bind(vm)
    });
  }

  initSelectComponentDefaults() {
    const vm = this;

    vm.filterPlaceholder = vm.$translate.instant('datasource-selector.filter.placeholder');
    vm.nonSelectedText = vm.$translate.instant('datasource-selector.non.selected');
    vm.allSelectedText = vm.$translate.instant('datasource-selector.all.selected');
    vm.selectAllText = vm.$translate.instant('datasource-selector.select.all');
    vm.nSelectedText = vm.$translate.instant('datasource-selector.n-selected');

    vm.maxHeight = vm.maxHeight || 200;
    vm.buttonWidth = vm.buttonWidth || "100%"
    vm.enableFiltering = vm.enableFiltering || true;
    vm.enableCaseInsensitiveFiltering = vm.enableCaseInsensitiveFiltering || true;
    vm.enableClickableOptGroups = vm.enableClickableOptGroups || true;
    vm.filterBehavior = vm.filterBehavior || 'both';
    vm.includeSelectAllOption = vm.includeSelectAllOption || true;
  }

  populateMultiSelect(data) {
    const vm = this;
    const optGroups = Object.entries(data).map(vm.createOptGroup);
    $(vm.multiSelectId).multiselect("dataprovider", optGroups);
  }

  onMultiselectChange(option, checked) {
    const vm = this;

    // HACK ALERT:
    // The 'onDeselectAll' is not triggered automatically by the component when
    // the user unckecks the 'selectAll' option, so the logic to select/deselect
    // all is centralized here as a workaround
    // It can be detected by checking if 'option == undefined'
    const selectAll = () => !option && checked;
    const deselectAll = () => !option && !checked;
    if(selectAll()) {
      return vm.onSelectAll();
    } else if(deselectAll()) {
      return vm.onDeselectAll();
    }

    const multiSelectDataSource = vm.dataSources.find(ds => ds.id == $(option).val());
    multiSelectDataSource.selected = checked;

    const dataSourceId = multiSelectDataSource.id;
    if(checked) {
      vm.selectedDataSources.push(multiSelectDataSource);
      vm.selectedDataSourceIds.push(dataSourceId);
    } else {
      vm.selectedDataSources = vm.selectedDataSources.filter(ds => ds.id != dataSourceId);
      vm.selectedDataSourceIds = vm.selectedDataSourceIds.filter(id => id != dataSourceId);
    }

    vm.$scope.$apply();
  }

  onSelectAll() {
    const vm = this;
    vm.selectedDataSources = angular.copy(vm.multiSelectDataSources);
    vm.selectedDataSourceIds = vm.selectedDataSources.map(ds => ds.id);
    vm.$scope.$apply();
  }

  onDeselectAll() {
    const vm = this;
    vm.selectedDataSources = angular.copy([]);
    vm.selectedDataSourceIds = angular.copy([]);
    vm.$scope.$apply();
  }

  onSelectedDataSourcesEventEmitted(event, eventDataSources) {
    const vm = this;
    vm.selectedDataSources = vm.selectedDataSources || [];
    eventDataSources.forEach(data => {
      const dataSource = vm.dataSources.find(ds => ds.id == data.id);
      vm.selectedDataSources.push(dataSource)
    });
  }

  onOrganizationAdd(event, organizations = []) {
    const vm = this;
    const orgRoles = vm.findRoleOrgsFromOrgs(organizations);
    if(orgRoles.length == 0) {
      vm.selectedDataSources.forEach(dataSource => {
        orgRoles.push({
          organization: dataSource.organization,
          role: {label: 'basic'}
        })
      });
    }
    vm.onOrganizationRoleAdd(event, orgRoles);
  }

  //TODO: Refactor function name - out of the scope of the component
  onOrganizationRoleAdd(event, organizationRoles = []) {
    const vm = this;
    const byOrgId = (dsOrg, org) => dsOrg == vm.getOrgId(org);

    if (!vm.dataSources) {
      return;
    }
  
    vm.selectedDataSources = vm.filterDataSourcesById(vm.dataSources, vm.selectedDataSources);
    vm.selectedDataSources.forEach(selDs => vm.selectedDataSourceIds.push(selDs.id));

    for(const organizationRole of organizationRoles) {
      const organization = organizationRole.organization;
      const role = organizationRole.role;

      angular
        .copy(vm.dataSources)
        .filter(dataSource => byOrgId(dataSource.organizationId, organization))
        .map(ds => vm.disableSelectionForAdminOrg(ds, role))
        .forEach(ds => {
          if(role.label == 'admin' && vm.automaticCheckSelection) {
            vm.selectedDataSourceIds.push(ds.id);
            vm.selectedDataSources.push(ds);
          }
          vm.multiSelectDataSources.push(ds)
        });
    }

    vm.selectedDataSourceIds.forEach(id => {
      const dataSource = vm.multiSelectDataSources.find(ds => ds.id == id);
      if(dataSource && vm.automaticCheckSelection) {
        dataSource.selected = true;
      }
    })

    vm.rebuildMultiselect(vm.multiSelectDataSources);
  }

  onOrganizationRemove(event, organization) {
    const vm = this;

    vm.selectedDataSources = vm.filterDataSourcesById(vm.dataSources, vm.selectedDataSources);

    const orgId = organization.id ? organization.id : organization;
    const dataSourceByOrgId = ds => ds.organizationId != orgId;

    //Remove Ids belonging to 'organization' from 'selectedDataSourceIds'
    vm.filterOutOrganizationDataSourcesFromSelectedIds(orgId);

    //Remove all DS belonging to 'organization' from 'multiSelectDataSources' list
    vm.multiSelectDataSources = vm.multiSelectDataSources.filter(dataSourceByOrgId);
    //Remove SELECTED DS belonging to 'organization' from 'selectedDataSources' list
    vm.selectedDataSources = vm.selectedDataSources.filter(dataSourceByOrgId);

    vm.rebuildMultiselect(vm.multiSelectDataSources);
  }

  filterOutOrganizationDataSourcesFromSelectedIds(orgId) {
    const vm = this;
    const removedMultiselectDataSources = vm.multiSelectDataSources.filter(ds => ds.organizationId == orgId);
    const removedMultiselectDataSourcesIds = []
    removedMultiselectDataSources.forEach(removedDS => {
      removedMultiselectDataSourcesIds.push(removedDS.id);
      const dataSource = vm.dataSources.find(ds => ds.id == removedDS.id);
      _.remove(vm.selectedDataSourceIds, id => id == dataSource.id);
    });
  }

  onOrgUpdatedToAdmin(event, orgId) {
    const vm = this;
    const orgDataSources = vm.dataSources.filter(ds => ds.organizationId == orgId);
    
    vm.selectedDataSources = vm.selectedDataSources.filter(ds => ds.organizationId != orgId);
    vm.selectedDataSources = vm.selectedDataSources.concat(orgDataSources);
    vm.multiSelectDataSources = vm.multiSelectDataSources.filter(ds => ds.organizationId != orgId);
    
    orgDataSources.forEach(ds => vm.disableSelectionForAdminOrg(ds, {label: 'admin'}));
    
    vm.multiSelectDataSources = vm.multiSelectDataSources.concat(orgDataSources);
    vm.rebuildMultiselect(vm.multiSelectDataSources);
  }

  onOrgUpdatedToBasic(event, orgId) {
    const vm = this;
    vm.multiSelectDataSources
      .filter(ds => ds.organizationId == orgId)
      .map(ds => ds.disabled = false)

    vm.rebuildMultiselect(vm.multiSelectDataSources);
  }

  rebuildMultiselect(dataSources) {
    const vm = this;
    vm.initSelectComponent();
    vm.mapOrgDataSources = vm.splitDataSourcesByOrganizations(dataSources);
    vm.populateMultiSelect(vm.mapOrgDataSources);
    
    vm.selectedDataSourceIds.forEach(selDs => {
      $(vm.multiSelectId).multiselect('select', selDs);
    })
    
    $(vm.multiSelectId).multiselect('rebuild');
  }

  createOptGroup(orgNameDataSourcesEntry) {
    const vm = this;
    const organizationName = orgNameDataSourcesEntry[0];
    const dataSources = orgNameDataSourcesEntry[1];
    return {
      label: `<span class="far fa-building"/><span class="org-icon">${organizationName}</span>`,
      children: dataSources.map(ds => {
        const dsIcon = ds.type == 'meter' ? 'icone_meter' : 'icone_grupo'
        let itemLabel = `<img class="datasource-icon" src="/assets/images/custom_icons/${dsIcon}.svg"/>`
        itemLabel += vm.currentUser.isAdmin && ds.uid ? `${ds.label} (<span class="uid">${ds.uid}</span>)` : ds.label;
        return {
          label: itemLabel,
          title: ds.uid,
          value: ds.id,
          selected: ds.selected,
          disabled: ds.disabled
        };
      })
    };
  }

  splitDataSourcesByOrganizations(dataSources) {
    const orgDataSourcesObj = {};
    dataSources.forEach(dataSource => {
      const orgName = dataSource.organizationName;
      if(orgDataSourcesObj[orgName] != undefined) {
        orgDataSourcesObj[orgName].push(dataSource)
      } else {
        orgDataSourcesObj[orgName] = [dataSource];
      }
    });

    return orgDataSourcesObj;
  }

  filterDataSourcesById(sourceDataSources, filterDataSources) {
    const filteredItems = [];
    if(filterDataSources && sourceDataSources) {
      for (const filterItem of filterDataSources) {
        const foundItem = sourceDataSources.find(sourceItem => sourceItem.id == filterItem.id);
        filteredItems.push(foundItem);
      }
    }

    return filteredItems;
  }

  disableSelectionForAdminOrg(dataSource, role) {
    const vm = this;
    if(role.label == 'admin' && vm.automaticCheckSelection) {
      dataSource.selected = true;
      dataSource.disabled = true;
    } else if(role.label == 'basic') {
      const userRoleInOrg = vm.findRoleOrgFromOrg(dataSource.organizationId);
      if (userRoleInOrg.role.label == 'admin') {
        dataSource.disabled = false;
      } else {
        dataSource.disabled = true;
      }
    }
    return dataSource;
  }

  getOrgId(org) {
    if (typeof org == 'object') {
      return org.id ? org.id : undefined;
    } else if (typeof org == 'string' || typeof org == 'number') {
      return org;
    } else {
      return undefined;
    }
  }

  async getUserRoleOrganizations() {
    const vm = this;

    if (!vm.currentUser)  {
      vm.currentUser = await vm.userService.getCurrentUser();
    }

    if (!vm.userRoleOrgs) {
      vm.userRoleOrgs = await vm.organizationService.getUserRoleOrganizations(vm.currentUser.id);
      return vm.userRoleOrgs;
    } else {
      return vm.userRoleOrgs;
    }

  }

  findRoleOrgFromOrg(org) {
    const vm = this;
    const orgId = vm.getOrgId(org);
  
    let foundRoleOrg = undefined;
    if (orgId && vm.userRoleOrgs) {
      foundRoleOrg = vm.userRoleOrgs.find(roleOrg => roleOrg.organization.id == orgId && vm.currentUser.id == roleOrg.user);
    }

    if (vm.currentUser.isAdmin && !foundRoleOrg) {
      // Master User is configuring roleOrg before inviting user.
      // Assume admin role and proceed.
      foundRoleOrg = { organization: { id: orgId }, role: { label: 'admin'} };
    }

    return foundRoleOrg;
  }

  findRoleOrgsFromOrgs(organizations = []) {
    const vm = this;
    let foundRoleOrgs = [];

    organizations.forEach(org => {
      const foundRoleOrg = vm.findRoleOrgFromOrg(org);
      if (foundRoleOrg) {
        foundRoleOrgs = foundRoleOrgs.concat(foundRoleOrg);
      }
    });
    return foundRoleOrgs
  }
}

const dataSourceSelectorOptions = {
  templateUrl:
    "/app/directives/datasource-selector/datasource-selector.html",
  controller: DataSourceSelectorController,
  controllerAs: "dssVm",
  bindings: {
    //Identifier for the component. Must be unique
    id: '@',
    //DataSources provided to the component. Defaults to User DataSources if not present
    dataSources: '=?', 
    //DataSource Type Filter - Valid options are 'meter' or 'group'
    type: '@',
    //Organization filter - Display DataSources from provided organization
    organization: '<',
    //Id of selected DataSource - Needed if object datasource is subset of original datasource object
    selectedDataSourceIds: '<',
    //Subset of 'dataSources' param that will be marked as 'selected' by the component
    selectedDataSources: "=?",
    //The height of the dropdown list
    maxHeight: '@',
    //Button Width
    buttonWidth: '@',
    //Enable or disable rendering of the filter input field
    enableFiltering: '@',
    //Enable/disable clickable opt-groups
    enableClickableOptGroups: '@',
    //Enable/disable the 'select all' option
    includeSelectAllOption: '@',
    //Valid options are: 'text', 'value' or 'both'
    filterBehaviour: '@',
    //Enables/Disables the component
    disabled: '@',
    //Only DataSources from Admin Organizations
    adminRoleOnly: "@",
    //Automatic Selection of DataSources managed by Admin Role User
    //Defaults to false
    automaticCheckSelection: "@",
    //Boolean param to determine wether to load all DS or only active ones
    allDataSources: "@",
    // Meter Type Filter (Ids) - Will be excluded
    excludedMeterTypes: "<"
  }
};

export const ngDatasourceSelector = {
  name:"datasourceSelector",
  def: dataSourceSelectorOptions
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShellComponent } from './app-shell.component';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { TenantModule } from '../tenant/tenant.module';
import { AppKitModule } from 'webapp/app/app-kit/app-kit.module';
import { HeaderSearchModule } from '../header-search/header-search.module';
import { AjsHelperModule } from '../ajs-module/ajs-module.module';
import { AnalysisModule } from 'webapp/app/dashboard/analysis/analysis.module';
import { MatIconModule } from '@angular/material/icon'

@NgModule({
  declarations: [
    AppShellComponent,
    HeaderComponent,
    NavbarComponent,
  ],
  exports: [
    AppShellComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    TenantModule,
    AppKitModule,
    RouterModule,
    HeaderSearchModule,
    AnalysisModule,
    AjsHelperModule,
    MatIconModule
  ]
})
export class AppShellModule { }

import * as angular from 'angular';

angular
    .module('dashboard-services')
    .factory('ClassificationService', ClassificationService);

ClassificationService.$inject = ['HttpUtil'];

function ClassificationService(HttpUtil) {
    var exportService = {
        getClassifications: getClassifications,
        getClassificationsConsumptions: getClassificationsConsumptions
    }

    return exportService;

    function getClassifications(params) {
        return HttpUtil.get({ url: `/api/meters/${params.meterId}/classifications` })
            .then(res => { return res.data.classifications })
    }

    function getClassificationsConsumptions(params) {
        return HttpUtil.get({ url: `/api/meters/${params.meterId}/classifications/${params.classificationId}/consumptions/`, params: params })
            .then(res => { return res.data.consumptions })
    }
}

import { Injectable, Inject } from "@angular/core";
import {HttpUtil} from "./http.util";
import { Auxiliar } from './auxiliar';
import { downgradeInjectable } from "@angular/upgrade/static";

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(
    private HttpUtil: HttpUtil,
    private Auxiliar: Auxiliar
  ) {}

    downloadTemplateExcel(type) {
        return this.HttpUtil.get({
            url: `/api/uploads/${type}/downloadTemplate`,
            responseType: 'blob',
        })
        .then((res) => {
            this.Auxiliar.download(res, null);
            return true;
        })
        .catch((error) => console.error(error));
    }
  
    transformExcelToJson( data, params, type ) {
        return this.HttpUtil.postData(`/api/uploads/${type}/list`, 'file', data, params)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw Error(error);
        });
    }
  
    createFromJson( data, type ) {
        return this.HttpUtil.post({ url:`/api/uploads/${type}/create`, data: data })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw Error(error);
        });
    }
}

export const ng2UploadService = {
    name: UploadService.name,
    def: downgradeInjectable(UploadService)
}
  
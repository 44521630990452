import * as angular from 'angular';

angular
  .module('dashboard-services')
  .service('CompanyService', CompanyService);

CompanyService.$inject = ['HttpUtil'];

/* @ngInject */
function CompanyService(HttpUtil) {
  return {
    getCompanies: getCompanies,
    getCompany: getCompany,
    getCompanyTypes: getCompanyTypes,
    getBillings: getBillings,
    getCompanyBilling: getCompanyBilling,
    getCompanyCoverages: getCompanyCoverages,
    getCoverages: getCoverages,
    saveCompany: saveCompany,
    deleteCompany: deleteCompany,
    createProfileItem: createProfileItem,
    updateProfileItem: updateProfileItem,
    deleteProfileItem: deleteProfileItem
  }

  function getCompanies(params = {}) {
    return HttpUtil.get({
      url: '/api/companies/',
      method: 'get',
      params: params
    }).then(res => {
      return res.data.companies
    });
  }

  function getCompany(id) {
    return HttpUtil.get({
      url: `/api/companies/${id}`,
      method: 'get'
    }).then(res => {
      return res.data.company
    });
  }

  function getCompanyTypes(params) {
    return HttpUtil.get({ url: '/api/companytypes', method: 'get', params: params }).then(res => { return res.data.companyTypes });
  }

  function getBillings(params) {
    return HttpUtil.get({
      url: '/api/companies/billings',
      method: 'get',
      params: params
    }).then(res => {
      return res.data.companies
    });
  }

  function getCompanyBilling(id, params) {
    return HttpUtil.get({
      url: `/api/companies/${id}/billings`,
      method: 'get',
      params: params
    }).then(res => {
      return res.data.invoices
    });
  }

  function getCoverages(params) {
    return HttpUtil.get({
      url: `/api/companies/profilebalances`,
      method: 'get',
      params: params
    }).then(res => {
      return res.data
    });
  }

  function getCompanyCoverages(params) {
    return HttpUtil.get({
      url: `/api/companies/${params.companyId}/profilebalances`,
      method: 'get',
      params: params
    }).then(res => {
      return res.data
    });
  }

  function createProfileItem(params) {
    return HttpUtil.post({
      url: `/api/companies/${params.companyId}/profiles/${params.profileId}/items`,
      params: params
    }).then(res => {
      return res.data
    });
  }

  function updateProfileItem(params) {
    return HttpUtil.put({
      url: `/api/companies/${params.companyId}/profiles/${params.profileId}/items/${params.profileItemId}`,
      params: params
    }).then(res => {
      return res.data
    });
  }

  function deleteProfileItem(params) {
    return HttpUtil.del({
      url: `/api/companies/${params.companyId}/profiles/${params.profileId}/items/${params.profileItemId}`,
      params: params
    }).then(res => {
      return res.data
    });
  }

  function saveCompany(params) {
    if (params.id) {
      return HttpUtil.put({ url: '/api/companies/' + params.id, method: 'put', data: params });
    } else {
      return HttpUtil.post({ url: '/api/companies', method: 'post', data: params });
    }
  }

  function deleteCompany(params) {
    if (params.id) {
      return HttpUtil.del({ url: `/api/companies/${params.id}`, method: 'delete' });
    }
  }
}
